import React from "react";

export function PaddleLoader() {
    return (
        <script
            src="https://cdn.paddle.com/paddle/paddle.js"
            onLoad={() => {
                if (process.env.REACT_APP_PADDLE_SANDBOX) {
                    Paddle.Environment.set("sandbox");
                }
                Paddle.Setup({
                    vendor: Number(process.env.REACT_APP_VENDOR_ID),
                    eventCallback: function(data: any) {
                        // The data.event will specify the event type
                        if (data.event === "Checkout.Complete") {
                            console.log(data.eventData); // Data specifics on the event
                        }
                        else if (data.event === "Checkout.Close") {
                            console.log(data.eventData); // Data specifics on the event
                        }
                    }
                });
            }}
        />
    );
}