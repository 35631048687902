import React from 'react';

interface IHeadingSkeletonProps {}

const HeadingSkeleton: React.FC<IHeadingSkeletonProps> = ({}) => {
  return (
    <div role="status" className="max-w-sm animate-pulse mb-8">
      <div className="h-5 bg-gray-100 rounded dark:bg-gray-300  w-80 "></div>
      <div className="h-8 bg-gray-100 rounded dark:bg-gray-300 w-96 mt-10"></div>
      <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-96 mt-2"></div>
    </div>
  );
};

export default HeadingSkeleton;
