import UniqueID from '@tiptap-pro/extension-unique-id';
import { EditorContent, Extension, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
// import FontFamily from '@tiptap/extension-font-family'
import React from 'react';
import { normalizeToPMFormat } from '../../../lib/tiptap/utils';
import { Document } from '../../../services/v1/interfaces';
import { PluginKey, Plugin } from '@tiptap/pm/state';
import CustomBubbleMenu from './CustomBubbleMenu/CustomBubbleMenu';
import { scrollElementIntoView } from '../../../helpers/scrollElementIntoView';

interface IEditorProps {
  documentData: Document | undefined;
  nodeID: string;
  setSearchParams: any;
  commentsToDisplay: any;
  restOfTheCommentsToDisplay: any;
}

export const Editor: React.FC<IEditorProps> = ({
  documentData,
  nodeID,
  setSearchParams,
  commentsToDisplay,
  restOfTheCommentsToDisplay,
}) => {
  const EventHandler = Extension.create({
    name: 'eventHandler',

    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('eventHandler'),
          props: {
            handleClickOn(view, pos, node, nodePos, event, direct) {
              if (
                commentsToDisplay?.some(
                  (comm: any) => comm.document_element_id === node.attrs.id
                )
              ) {
                setSearchParams((params: any) => {
                  params.set('id', node.attrs.id);
                  return params;
                });
              }
            },
          },
        }),
      ];
    },
  });
  const editor = useEditor({
    extensions: [
      EventHandler,
      StarterKit,
      UniqueID.configure({
        types: ['heading', 'paragraph'],
      }),

      // FontFamily.configure({
      //   types: ['heading', 'paragraph']
      // })
    ],
    content: '',
    editable: false,
  });

  const focus = () => {
    editor?.chain().command(({ tr }) => {
      tr.doc.descendants((node, pos) => {
        if (node.attrs.id === nodeID) {
          const htmlNode = document.querySelector(`[data-id="${nodeID}"]`);
          if (htmlNode) {
            htmlNode.className = 'bg-[#80DED9]';
            scrollElementIntoView(htmlNode as any);
          }
        } else {
          restOfTheCommentsToDisplay.forEach((comm: any) => {
            const htmlNode = document.querySelector(
              `[data-id="${comm.document_element_id}"]`
            );
            if (htmlNode) {
              htmlNode.className =
                'bg-[#DAEBEF] hover:bg-[#80DED9] cursor-pointer';
            }
          });
        }

        return true;
      });
      return true;
    });
  };

  React.useEffect(() => {
    if (documentData) {
      // editor?.commands.setFontFamily('Inter')
      editor?.commands.setContent(
        normalizeToPMFormat(
          documentData?.result[0].result.ordered_doc_segments as any
        )
      );
    }
  }, [documentData, editor]);

  React.useEffect(() => {
    if (documentData && editor) {
      focus();
    }
  }, [nodeID, documentData, editor]);

  return (
    <>
      <CustomBubbleMenu editor={editor || undefined} />
      <EditorContent
        className="bg-white p-16 rounded overflow-y-auto h-full "
        editor={editor}
      ></EditorContent>
    </>
  );
};
