import Disclaimer from "../components/Single_Components/Disclaimer";
import LegalReview from "../assets/images/LegalReview.png";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import FetchUserInfo from "../assets/FetchBalance";
import Loading from "../components/Single_Components/Loading";

export default function ToCPrivacyPolicy() {
  const [button, setButon] = useState(false);
  const navigate = useNavigate();

  const [Userinfo, setUserinfo] = useState(0);
  const [acceptedDisclaimer, setAcceptDisclaimer] = useState(false);
  const [loading, setLoading] = useState(true);

  const PatchUserToC = async () => {
    const requestOptions = {
      method: "PATCH",
      headers: { 'Content-Type': 'application/json', "Authorization": localStorage.getItem("token") },
      body: JSON.stringify({ terms_accepted: true }),
    };

    const fetchUserInfo = async () => {

      return await window.fetch(
        process.env.REACT_APP_DOMAIN + "/api/users/" + Userinfo.id+"/",
        requestOptions
      );
    };
    return await fetchUserInfo();
  };

  useEffect(() => {
    try{
      FetchUserInfo().then((result) => {
        setUserinfo(result);
        setAcceptDisclaimer(result.terms_accepted);
      });
    } catch{

    }
  }, []);

  function checkboxHandler() {
    setButon(!button);
  }

  function containsNumbers(str) {
    return /\d/.test(str);
  }
  
  function clickHandler() {
    if (button) {
      
      try{
        PatchUserToC().then((a) => {
          
          return a.json()
        }).then((a) => {
          window.dataLayer.push({ event: 'user_sign_up' });
          localStorage.setItem("terms_accepted", true);
          const pageUrl = window.location.href
          setTimeout(() => {
            if (!containsNumbers(pageUrl))
              navigate("/home/upload");
            window.location.reload(true);
          },250)

        });
      } catch{

      }

    }
  }

  return (
    <>
      <div className="w-full h-[50vh] bg-[#748AF2] items-center">
        <div className="flex h-screen">
          <div className="pb-10 m-auto max-w-[750px] bg-white rounded-2xl drop-shadow-xl">
            <div className="mt-16 h-[82px] max-w-[450px] mb-5 flex mx-auto">
              <img src={LegalReview} alt=" React Logo" />
            </div>
            <div className="mx-10 block p-10 py-0 ">
              <Disclaimer styles="mx-auto shadow-none p-0  pt-5" />
              <div className="mx-auto mb-10 max-w-[970px] pt-5">
                <fieldset className="space-y-5">
                  <div className="relative flex items-start">
                    <div className="ml-2.5 flex h-5 items-center">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        style={{ accentColor: "#748AF2" }}
                        onClick={checkboxHandler}
                        className="h-5 w-5 rounded border-[#748AF2] text-[#748AF2] focus:ring-[#748AF2]"
                      />
                    </div>
                    <div className="ml-[50px] Inter">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-700">
                        <p>
                          Accept&nbsp;
                          <a
                            target={"_blank"}
                            href={"https://www.legalreview.ai/terms"}
                            className="underline">
                            Terms and Conditions
                          </a>
                          &nbsp;&&nbsp;
                          <a
                            target={"_blank"}
                            href={"https://www.legalreview.ai/privacy"}
                            className="underline">
                            Privacy Policy
                          </a>
                        </p>

                        <p
                          id="comments-description"
                          className="text-gray-500 font-light">
                          Accepting this terms means that you agree with how we
                          process your data and you understand how our system
                          works.
                        </p>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className={`text-center 2xl:mt-10`}>
                <button
                  onClick={clickHandler}
                  className={`w-[450px] rounded-full text-white text-xl py-3  items-center  ${
                    button ? "bg-[#748AF2]" : "bg-gray-200 cursor-not-allowed"
                  }`}>
                  <p>Continue</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <Disclaimer /> */}
      </div>
    </>
  );
}
