import { React, useState, useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import FetchSingleDocument from "../../assets/FetchSingleDocument";
import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from "../../components/navbar/Sidebar";
import { RxHamburgerMenu } from "react-icons/rx";
import LoginButton from "../../components/auth/login";
import {
  setCommentYPosition,
  removeUpperSiblingMarginTop,
  removeLowerSiblingMarginTop,
  calcMoveValue,
} from "../../assets/GeneralFunctions";

import { IoMdArrowDropleft } from "react-icons/io";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BsChevronLeft } from "react-icons/bs";
import ToCPrivacyPolicy from "../../pages/ToCPrivacyPolicy";
import FetchUserInfo from "../../assets/FetchBalance";
import NotFound from "../NotFound";
import FetchComparison from "../../assets/FetchComparison";

// import test from "./test.json"
function LoadDocument() {
  const params = useParams();
  const [document1, setDocument] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const { isAuthenticated } = useAuth0();
  const [status, setStatus] = useState(200);

  useEffect(() => {
    const fetchDocFromUniqueID = async (requestOptions) => {
      const response = await window.fetch(
        process.env.REACT_APP_DOMAIN +
          "/api/v1/jobs/share/" +
          localStorage.getItem("uniqueID") +
          "/",
        requestOptions
      );
      if (response.status !== 200) return response.status;
      const result = await response.json();
      if (result.length !== 0) {
        setDocument(result);
      }
      return response.status;
    };

    if (localStorage.getItem("uniqueID")) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      };
      try {
        const response = fetchDocFromUniqueID(requestOptions)
          .then((result) => {
            return result.json();
          })
      } catch (e) {
        console.log(e);
      }
      localStorage.removeItem("uniqueID");
    }

  }, []);

  console.log(document1)
  const [comments, setComments] = useState([]);
  const [text, setText] = useState([]);
  const fetchComments = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("token"),
        "Connection": "keep-alive",
      },
    };
  
    const getComments = async (thumb) => {
  
      const response = await fetch(
          process.env.REACT_APP_NLP_API_BASE_URL+"/document/"+params.id+"/comments",
          requestOptions,
          {}
        ).then(async (r) => {
          return await r.json();
        })
      return response;
    };
  
    const k = getComments().then((result) => {
      let text1 = []
      // setText(result.sort((a,b) => a.value.order_idx - b.value.order_idx))
      Object.entries(result).forEach((entry) => {
        const [key, value] = entry;
        text1.push({key, value});
      })
      text1.sort((a,b) => a.value.order_idx - b.value.order_idx)
      setText(text1)
      return comments.map((a) => a.value.comments)
    })
    return k
  }

  useEffect(() => {
      FetchComparison([document1, setDocument], params.id)
  }, []);
  

  useEffect(() => {
    if (comments) {
      for (let child of document.getElementById("comments").children) {
        child.style.width =
          document.getElementById("comments").offsetWidth-48 + "px";
      }
      setTimeout(() => {
        if(comments){
          for (let child of document.getElementById("comments").children) {
            let idcomment= child?.id?.split("-comment")[0]
            let element = document.getElementById("father-" + idcomment+"-child2")
            if(child.offsetTop !== element.offsetTop) {
              element.click()
              document.getElementById("comments").click()
              break;
            }
          }
        }

      },370)

    }

  }, [isOpen]);


  let commentsList = [];

  const [acceptedDisclaimer, setAcceptDisclaimer] = useState();

  useEffect(() => {
    if (
      localStorage.getItem("terms_accepted") === "false" ||
      localStorage.getItem("terms_accepted") === "undefined" ||
      !localStorage.getItem("terms_accepted")
    ) {
      FetchUserInfo().then((userinfo) => {
        setAcceptDisclaimer(userinfo.terms_accepted);
        localStorage.setItem("terms_accepted", userinfo.terms_accepted);
      });
    } else {
      setAcceptDisclaimer(true);
    }
  }, []);

  if (isAuthenticated && !acceptedDisclaimer) {
    return <ToCPrivacyPolicy />;
  }
  if (status !== 200) return <NotFound />;
  return (
    <div className="flex bg-[#E0E0E0]/[.2] ">
      <div className="w-full min-h-screen">
        <div className="fixed h-screen">
          {isOpen ? (
            <>
              <div className="absolute top-1/2 left-[270px] bg-white border border-l-[0px] translate-x-[-1px] rounded-tr-full rounded-br-full">
                <button
                  className="translate-x-[-5px] translate-y-[0px]  rounded-tr-full rounded-br-full py-2"
                  onClick={() => setIsOpen(!isOpen)}>
                  <MdOutlineKeyboardArrowLeft size={30} />
                </button>
              </div>
              <Sidebar props={1} />
            </>
          ) : (
            <button
              className="p-5 mt-5 ml-2 bg-white shadow-sm border rounded-xl "
              onClick={() => setIsOpen(!isOpen)}>
              <RxHamburgerMenu size={25} />
            </button>
          )}
        </div>
        <div className={`m-5 mb-0 ${isOpen ? "ml-[284px]" : "ml-16"} `}>
          <Outlet context={[document1, setDocument, text, comments, fetchComments]} />
        </div>
      </div>
    </div>
  );
}

export default LoadDocument;
