import { React, useState, useEffect, useRef, useId } from "react";
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react'

import DocSegment from "./DocSegment";
import TextBox from "../../../components/Single_Components/TextBox";
import AskQuestion from "./Question";

// import test from "./test.json"
function CompleteDocument({ props, url, id, text, comments, fetchComments, document2}) {
  const [searchParams, setSearchParams] = useSearchParams();
  let documentElementId = searchParams.get("tag")

  const ref = useRef(null);
  const uniqueID = useId();
  const location = useLocation();

  let contractText = []
  for(let i of text){
    contractText.push(i.value)
  }

  let text1 = [];
  if (props?.doc_segments_by_id) {
    Object.entries(props?.ordered_doc_segments).forEach((entry) => {
      const [key, value] = entry;
      text1.push(value);
    });
  }

  const [question, setQuestion] = useState()
  const [openQuestionBox, setOpenQuestionBox] = useState(false)
  function openQuestion(a, filter, id){
    setQuestion([filter, id.split("father-")[1]])
    setOpenQuestionBox(!openQuestionBox)
  }

  function paintChildren(parentId, color){
    const document_elements = props?.ordered_doc_segments
    for (const document_element of document_elements) {
      const docParentId = document_element?.parent_id
      const docElementId = document_element?.id
      if (docParentId === parentId){
        let element = document.getElementById("father-" + docElementId)
        element.style.background = color;
      }
    }
  }

  useEffect(() => {
    if(documentElementId && text.length > 0){
      setTimeout(() => {
        let element = (document.getElementById("father-" + documentElementId)) ? document.getElementById("father-" + documentElementId) : document.getElementById(documentElementId)
        element.setAttribute("aria-comment" , "true")
        element.click();
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => {
          let element = document.getElementById("father-" + documentElementId)
          const orangeColor = "rgba(255, 170, 2, 0.38)"
          element.style.background = orangeColor;
          paintChildren(documentElementId, orangeColor)
        },300);
      }, 250);

    }
  },[text.length])

  return (
      <div className="flex" id="generated_document">
        <div
            ref={ref}
            id="doc-text"
            className="sm:px-6 w-8/12 lg:px-8 bg-white border rounded-xl p-6 shadow-sm">
          {useFeatureFlagEnabled("[FE]-[InlineReview]-ask_question_on_the_fly") ? (

              <AskQuestion
                  id={id}
                  language={props?.general_information?.language}
                  fetchComments={fetchComments}
                  comments={comments}
                  openQuestion={openQuestion}
                  question={question}
                  setQuestion={setQuestion}
                  openQuestionBox={[openQuestionBox, setOpenQuestionBox]}
              />
          ) : (
              <></>
          )}
          <div className="relative">
            {useFeatureFlagEnabled("[FE]-[InlineReview]-make_eli5_bar_visible") ? (

                <TextBox questions={props?.summary} openQuestion={openQuestion}
                         openQuestionBox={openQuestionBox} setOpenQuestionBox={setOpenQuestionBox}
                >
                  {contractText.map((element, value) => {
                    return (
                        <div key={uniqueID + value}>
                          <DocSegment props={element} id={element.id} text={"0"} comments={comments}/>
                        </div>
                    );
                  })}
                </TextBox>
            ) : (
                <>
                  {contractText.map((element, value) => {
                    return (
                        <div key={uniqueID + value + "1"}>
                          <DocSegment props={element} id={element.id} text={"0"} comments={comments}/>
                        </div>
                    );
                  })}
                </>
            )}</div>
        </div>
        <div id="comments" className={`mt-6 relative w-4/12 pl-12 z-0`}>
          {comments.map((element) => {
            return <DocSegment props={element} text={"1"} />;
          })}
        </div>
      </div>
  );
}

export default CompleteDocument;
