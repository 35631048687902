import React from "react";
import {NavLink} from "react-router-dom";
import customClassNames from "classnames";
import {CommentReference} from "../../models/CommentReference";

function convert_quality_score_2_color(quality: string){
    if (quality === "good")
        return "green"
    else if (quality === "medium")
        return "yellow"
    else if (quality === "bad")
        return "red"
    else
        return "blue"

}

export default function CommentAnswerReferences(
    {job_id, are_refs_visible, references}: {job_id: number, are_refs_visible: boolean, references:Array<CommentReference>}
    ) {
    const should_show_references = are_refs_visible ?? true
    return <div className="mt-2"> {
        should_show_references ? (
        references?.map((reference, index) => {
            const quality_color = convert_quality_score_2_color(reference?.quality)
            const reference_id = reference?.document_element_id ?? reference
            const activateClassNames = customClassNames(
                "w-[120px]",
                "min-w-[120px]",
                "py-1",
                "px-3",
                "rounded-full",
                "text-right",
                "Inter",
                "text-xs",
                "hover:bg-opacity-50",
                "fade-in",
                "duration-300",
                "focus:outline-none",
                {
                    "bg-[#B3E7DB]/[.62]": quality_color === "green",
                    "bg-[#FFDEB4]/[.62]": quality_color === "yellow",
                    "bg-[#E7B3CC]/[.62]": quality_color === "red",
                    "bg-[#9BABB8]/[.62]": quality_color === "blue"
                },
                {
                    "hover:bg-[#B3E7DB]/[0.2]": quality_color === "green",
                    "hover:bg-[#FFDEB4]/[0.2]": quality_color === "yellow",
                    "hover:bg-[#E7B3CC]/[0.2]": quality_color === "red",
                    "hover:bg-[#9BABB8]/[0.2]": quality_color === "blue"
                },
                {
                    "text-[#217541]":  quality_color === "green",
                    "text-[#FAAB78]":  quality_color === "yellow",
                    "text-[#a83248]":  quality_color === "red",
                    "text-[#526D82]":  quality_color === "blue"
                }
            )
            return (
                <NavLink
                    to={`/contract/${job_id}/inline_review?tag=${reference_id}`}
                    target="_blank" rel="noopener noreferrer"
                    key={`${reference_id}`}
                    className={`hover:text-${quality_color} ml-1`}
                >
                <span
                    className={activateClassNames}>
                    r{index}
                </span>
                </NavLink>
            )
        })
        ) : <></>
    } </div>
}