import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {CompaniesDocumentsResponse} from './interfaces';

export const getMonitoringCompanies = async (): Promise<CompaniesDocumentsResponse> => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem("token")
    }} ;
  return await axios
    .get<CompaniesDocumentsResponse>(`${process.env.REACT_APP_NLP_API_BASE_URL}/monitoring/subscriptions`, requestOptions)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error getting companies documents`, error);
      return Promise.reject(error);
    });
};

export function useGetMonitoringCompaniesAsQuery(
    options?: QueryOptionsOmit<
      UseQueryOptions
    >
) {
  return useQuery(
    ["monitoring", "companies", "get"],
    () => {
      return getMonitoringCompanies();
    },
    options as any
  );
}
