
const FetchComparison = async ([document, setDocument], id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": localStorage.getItem("token")},
    };

    const fetchComp = async () => {
        const response = await window.fetch(process.env.REACT_APP_DOMAIN+"/api/v1/jobs/"+id, requestOptions)
        // const response = await window.fetch("../../../test.json", requestOptions)
        const result = await response.json()
        if(result.length !== 0){
            setDocument(result)
        }
        
        // setDocument(myJson)
        return result?.result[0]?.result
    };
    return await fetchComp();
};
export default FetchComparison