import { React, useRef, useEffect, useState } from "react";
import Comment from "./Comment";
import ClickOutside from "../../../components/Single_Components/ClickOutside";

import {
  setCommentYPosition,
  getSiblingsBellow,
  getSiblingsAbove,
  setCommentYPositionDownwards,
  setCommentYPositionUpwards,
  addStyle,removeStyle,
  ADDED_BACKGROUND_COLOR,
  ELIMINATED_BACKGROUND_COLOR,
  EDITED_BACKGROUND_COLOR,
 ADDED_BORDER, ELIMINATED_BORDER, EDITED_BORDER,
} from "../../../assets/GeneralFunctions";

function DocSegment({ props, old_id,new_id, text, part, commentIds}) {
  const ref = useRef(null);

  useEffect(() => {
    if(document.getElementById("comments")){
      for (let child of document.getElementById("comments").childNodes) {
        child.style.width =
          document.getElementById("comments").offsetWidth -48 + "px";
      }
    }
  }, []);

  const handleMouseEnter = (e) => {
    let fatherID = e.target.id.slice(0, -1);
    let fatherTitle1 = document.getElementById(fatherID+"1")
    let fatherTitle2 = document.getElementById(fatherID+"2")
    if (fatherTitle1 && fatherTitle2 && fatherTitle2.getAttribute("aria-comment") === "false") {
      
      addStyle(fatherTitle1, props.change_type, props.real_change_type);
      addStyle(fatherTitle2, props.change_type, props.real_change_type);
      for (let child of document.getElementsByClassName("child-"+new_id)){
        child.classList.add("comments_border")
        child.setAttribute("aria-comment", "true")
        if(props.change_type === "edited") child.classList.add(EDITED_BORDER);
        else if(props.change_type === "added" || props.real_change_type === "added") child.classList.add(ADDED_BORDER);
        else if(props.change_type === "eliminated" || props.real_change_type === "eliminated") child.classList.add(ELIMINATED_BORDER);
    }
    }
  };

  const handleMouseLeave = (e) => {
    let fatherID = e.target.id.slice(0, -1);
    let fatherTitle1 = document.getElementById(fatherID+"1")
    let fatherTitle2 = document.getElementById(fatherID+"2")
    if (fatherTitle1 && fatherTitle2 && fatherTitle2.getAttribute("aria-comment") === "false") {
      removeStyle(fatherTitle1, props.change_type, props.real_change_type);
      removeStyle(fatherTitle2, props.change_type, props.real_change_type);
      for (let child of document.getElementsByClassName("child-"+new_id)){
        child.classList.remove("comments_border")
        child.setAttribute("aria-comment", "false")
        if(props.change_type === "edited") child.classList.remove(EDITED_BORDER)
        else if(props.change_type === "added" || props.real_change_type === "added") child.classList.remove(ADDED_BORDER);
        else if(props.change_type === "eliminated" || props.real_change_type === "eliminated") child.classList.remove(ELIMINATED_BORDER);
    }
    }
  };

  const handleMouseClick = (e) => {
    
    let makeid = (props.ref_id ? props.ref_id : props.new_doc_ref_id)
    
    let fatherID = (e.target.id ? e.target.id.slice(0, -1) : "father-"+makeid+"-child")
    let fatherTitle1 = document.getElementById(fatherID+"1")
    let fatherTitle2 = document.getElementById(fatherID+"2")
    if (fatherTitle1 && fatherTitle2 && fatherTitle2.getAttribute("aria-comment") === "false" && fatherTitle1.getAttribute("aria-comment") === "false") {
      fatherTitle1.setAttribute("aria-comment", "true");
      fatherTitle2.setAttribute("aria-comment", "true");
    }
    const selectedGroupComments = document.getElementById(
      makeid + "-comment"
    );

    addStyle(document.getElementById(fatherID+"1"), props.change_type, props.real_change_type);
    addStyle(document.getElementById(fatherID+"2"),props.change_type, props.real_change_type);
    
    for (let child of document.getElementsByClassName("child-"+makeid)){
      child.classList.add("comments_border")
      child.setAttribute("aria-comment", "true")

      if(props.change_type === "edited") child.classList.add(EDITED_BORDER);
        else if(props.change_type === "added" || props.real_change_type === "added") child.classList.add(ADDED_BORDER);
        else if(props.change_type === "eliminated" || props.real_change_type === "eliminated") child.classList.add(ELIMINATED_BORDER);
      // element.setAttribute("title","true")
  }

    
    setCommentYPosition(selectedGroupComments, null);
    let topComments = getSiblingsAbove(selectedGroupComments);
    let bottomComments = getSiblingsBellow(selectedGroupComments);
    let lastComments = [selectedGroupComments];
    // console.log(document.getElementById("father-" + makeid+"-child2"), makeid)
    if (selectedGroupComments.offsetTop !== document.getElementById("father-" + makeid+"-child2").offsetTop
    ) {
      for (let comment of topComments) {
        let lastComment = lastComments.pop();
        setCommentYPositionUpwards(comment, lastComment);
        lastComments.push(comment);
      }
      lastComments = [selectedGroupComments];
      for (let comment of bottomComments) {
        let lastComment = lastComments.pop();
        setCommentYPositionDownwards(comment, lastComment);
        lastComments.push(comment);
      }
    }
    setTimeout(() => {fatherTitle2.scrollIntoView({ behavior: "smooth", block: "center" });}, 250);
    

  };

  const handleClickOutside = (e) => {
    let fatherTitle1 = document.getElementById("father-"+new_id+"-child1")
    let fatherTitle2 = document.getElementById("father-"+new_id+"-child2")
    if(fatherTitle1 !== e.target && fatherTitle2 !== e.target && document.getElementById("child-"+new_id)!==e.target){
      if (fatherTitle1 && fatherTitle2 && fatherTitle2.getAttribute("aria-comment") === "true" && fatherTitle1.getAttribute("aria-comment") === "true") {
        fatherTitle1.setAttribute("aria-comment", "false");
        fatherTitle2.setAttribute("aria-comment", "false");
        removeStyle(fatherTitle1,props.change_type, props.real_change_type);
        removeStyle(fatherTitle2,props.change_type, props.real_change_type);
        let childs = document.getElementsByClassName("child-" + new_id);
        for (let child of childs) {
          child.setAttribute("aria-comment", "false");
        }
      }
    }
  };

  useEffect(() => {
    for(let k of document.getElementsByClassName("eliminated")){
      if (k.id === "father-"+new_id+"-child2" && document.getElementById("father-"+new_id+"-child1")){
        k.style.height = document.getElementById("father-"+new_id+"-child1").clientHeight + "px"
      }
    }
    for(let k of document.getElementsByClassName("added")){
      if (k.id === "father-"+new_id+"-child1" && document.getElementById("father-"+new_id+"-child2")){
        k.style.height = document.getElementById("father-"+new_id+"-child2").clientHeight + "px"
      }
    }
  }, [new_id])


  let commentsList = []
  if(commentIds && commentIds.length>0){
    for(let k of commentIds){
      commentsList.push(k.ref_id)
    } 
  }
  // sections 
  if(props.element_type === "section") {
    if(props.change_type === "eliminated")
    return (
      <ClickOutside
              onClickInside={handleMouseClick}
              onClickOutside={handleClickOutside}>
              <props.element_optional_info
              id={`father-${new_id}-${part}`}
              key={`father-${new_id}-eliminated`}
              style={{whiteSpace: "break-spaces"}}
                ref={ref}
                className={`${ELIMINATED_BACKGROUND_COLOR} Inter transition-all duration-150 pr-5 overflow-hidden break-words eliminated`}
              
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-comment="false">
                
                {props.side_text}
              </props.element_optional_info>
              </ClickOutside>)
    if(props.change_type === "added")
      return(<ClickOutside
    onClickInside={handleMouseClick}
    onClickOutside={handleClickOutside}>
    <props.element_optional_info
    id={`father-${new_id}-${part}`}
    key={`father-${new_id}-added`}
    style={{whiteSpace: "break-spaces"}}
      ref={ref}
      className={`${ADDED_BACKGROUND_COLOR} Inter transition-all duration-150 pr-5 overflow-hidden break-words added`}
    
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-comment="false">
      
      {props.side_text}
    </props.element_optional_info>
    </ClickOutside>)
    return (<props.element_optional_info
      id={`father-${new_id}`}
      key={`father-${new_id}-1`}
      className={`Inter transition-all duration-150 pr-5 overflow-hidden break-words`}>
      {props.side_text}
      </props.element_optional_info>)
  }


  if(props.change_type === "empty_block" && props.change_type === "eliminated")
    return (
      <ClickOutside
              onClickInside={handleMouseClick}
              onClickOutside={handleClickOutside}>
              <p
              id={`father-${new_id}-${part}`}
              key={`father-${new_id}-eliminated`}
              style={{whiteSpace: "break-spaces"}}
                ref={ref}
                className={`${ELIMINATED_BACKGROUND_COLOR} Inter transition-all duration-150 pr-5 overflow-hidden break-words eliminated`}
              
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-comment="false">
                
                {props.side_text}
              </p>
              </ClickOutside>)
  if(props.element_type === "empty_block" && props.change_type === "added")
  return (
    <ClickOutside
            onClickInside={handleMouseClick}
            onClickOutside={handleClickOutside}>
            <p
            id={`father-${new_id}-${part}`}
            key={`father-${new_id}-added`}
            style={{whiteSpace: "break-spaces"}}
              ref={ref}
              className={`${ADDED_BACKGROUND_COLOR} Inter transition-all duration-150 pr-5 overflow-hidden break-words added`}
            
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              aria-comment="false">
              
              {props.side_text}
            </p>
            </ClickOutside>)

  //paragraph edit, add, eliminate, ignore
  return (
    <>
      {text === "0" ? (
        <div className="flex justify-between">
          <div className="block">
            {/* addition */}
            {props.change_type === "added" && props.children && props?.children.map((child, index) => {
                return (<ClickOutside
                  onClickInside={handleMouseClick}
                  onClickOutside={handleClickOutside}>
                  <child.element_optional_info
                  id={`father-${child.new_doc_ref_id}-${part}`}
                  key={`father-${child.new_doc_ref_id}-added`}
                  style={{whiteSpace: "break-spaces"}}
                    ref={ref}
                    className={`${ADDED_BACKGROUND_COLOR} Inter transition-all duration-150 pr-5 overflow-hidden break-words added`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    aria-comment="false">
                    {child.side_text}
                  </child.element_optional_info>
                  </ClickOutside>)
              })}

            {/* edited  */}
            {props.change_type === "edited" && props.children && props?.children.map((child, index) => {
              if(child.diffs.length>0)
                return (<ClickOutside
                  onClickInside={handleMouseClick}
                  onClickOutside={handleClickOutside}>
                  <child.element_optional_info
                  id={`father-${child.new_doc_ref_id}-${part}`}
                  key={`father-${child.new_doc_ref_id}-edited`}
                  style={{whiteSpace: "break-spaces"}}
                    ref={ref}
                    className={`${EDITED_BACKGROUND_COLOR} Inter transition-all duration-150 pr-5 overflow-hidden break-words edited`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    aria-comment="false">
                    {child.side_text}
                  </child.element_optional_info>
                  </ClickOutside>)
              else return (<child.element_optional_info
                id={`father-${child.new_doc_ref_id}-${part}`}
                key={`father-${child.new_doc_ref_id}-1`}
                className={`Inter transition-all duration-150 break-words`}>
                {child.side_text}
            </child.element_optional_info>)
              })}

            {/* eliminated  */}
            {props.change_type === "eliminated" && props.children && props?.children.map((child, index) => {
                return (<ClickOutside
                  onClickInside={handleMouseClick}
                  onClickOutside={handleClickOutside}>
                  <child.element_optional_info
                  id={`father-${child.old_doc_ref_id}-${part}`}
                  key={`father-${child.old_doc_ref_id}-eliminated`}
                  style={{whiteSpace: "break-spaces"}}
                    ref={ref}
                    className={`${ELIMINATED_BACKGROUND_COLOR} Inter transition-all duration-150 pr-5 overflow-hidden break-words eliminated`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    aria-comment="false">
                    {child.side_text}
                  </child.element_optional_info>
                  </ClickOutside>)
              })}
          </div>
        </div>
      ) : 
        <div
          key={ + "-comment"}
          id={props.ref_id + "-comment"}
          className={`absolute z-30 transition-all duration-500 ${props.ref_id}`}>
          {/* <VerticalCarousel items={props.diffs} ref={ref}/> */}
              
            <Comment comment_data={props} allFatherData={props} fatherClick={handleMouseClick} />
          
        </div>
      }
    </>
  );
}

export default DocSegment;
