import React from 'react';
import { useSearchParams } from 'react-router-dom';
import customClassnames from 'classnames';

interface IReferencesProps {
  references: any;
  initialReference: any
}

const classnames = {
  active: 'bg-white text-[#748AF2] shadow rounded',
  inactive: 'bg-[#FCFCFF] text-[#8D92A7] border border-[#8D92A7] border-opacity-10 rounded',
};



const References: React.FC<IReferencesProps> = ({
  references,
  initialReference,
}) => {
  const [activeReference, setActiveReference] =
  React.useState(initialReference);
  
  const [_, setSearchParams] = useSearchParams();

  const handleRefClick = (ref: any) => {
    setActiveReference(ref);
    setSearchParams((params) => {
      params.set('id', ref.document_element_id);
      return params;
    });
  };

  const qualityClassnames = (refQuality: any) => {
    return customClassnames({
      'border-[#B3E7DB]/[.62]': refQuality === 'good',
      'border-[#FFDEB4]/[.62]': refQuality === 'medium',
      'border-[#E7B3CC]/[.62]': refQuality === 'bad',
    });
  };

  React.useEffect(()=>{
    setActiveReference(initialReference)
  },[initialReference])

  

  return (
    <div>
      <p className="text-xs font-semibold mb-2">References</p>
      <div className="flex gap-2">
        {references?.map((ref: any, index: number) => (
          <span
            data-id={ref.ref_id}
            key={ref.ref_id}
            onClick={() => handleRefClick(ref)}
            className={`font-semibold text-xs px-2 py-1 cursor-pointer ${
              activeReference.ref_id === ref.ref_id
                ? classnames.active
                : classnames.inactive
            }  ${qualityClassnames(ref.quality)}`}
          >
            {(index + 1).toString().padStart(2, '0')}
          </span>
        ))}
      </div>
    </div>
  );
};

export default References;
