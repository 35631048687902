export default function Loading({style}) {
  return (
    <>
      <div className={`flex items-center justify-center w-full ${style ? style: "h-screen"}`}>
        <div
          className={`inline-block ${style ? "h-7 w-7 border-2" : "h-20 w-20 border-8 "} animate-spin rounded-full border-[#748AF2] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    </>
  );
}
