// src/components/authentication-button.js

import React from 'react';

import LoginButton from './login';
import LogoutButton from './logout';

import { useAuth0 } from '@auth0/auth0-react';

const AuthenticationButton = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <LogoutButton /> : <></>;
};

export default AuthenticationButton;