import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {SupabaseDBUser, SupabaseDBUserWrapper} from './interfaces';

export const getProfileData = async (): Promise<SupabaseDBUser> => {
const requestOptions = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
    }} ;
  return await axios
      .get<SupabaseDBUserWrapper>(`${process.env.REACT_APP_NLP_API_BASE_URL}/users/data`, requestOptions)
      .then((response) => {
        return Promise.resolve(response.data.user);
      })
      .catch((error) => {
        console.error(`Error getting profile data`, error);
        return Promise.reject(error);
      });
};
export function useFetchUserDataQuery(
  options?: QueryOptionsOmit<
    UseQueryOptions<any>
  >
) {
  return useQuery(
    ['users', 'fetch'],
    () => {
      return getProfileData();
    },
    options as any
  );
}
