import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import { Document as DocumentResponse } from './interfaces';

export const getDocumentByID = async (
  ID: string
): Promise<DocumentResponse> => {
  return await axios
    .get<DocumentResponse>(`${process.env.REACT_APP_DOMAIN}/api/v1/jobs/${ID}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error getting document: ${ID}`, error);
      return Promise.reject(error);
    });
};

export function useGetDocumentByIDQuery(
  ID: string,
  options?: QueryOptionsOmit<
    UseQueryOptions<DocumentResponse, any, DocumentResponse>
  >
) {
  return useQuery(
    ['documents', 'details', ID],
    () => {
      return getDocumentByID(ID);
    },
    options as any
  );
}
