import React, { useState, useRef, useEffect } from 'react';
import {BiCommentDetail} from "react-icons/bi"
import {BsStars} from "react-icons/bs"
import Loading from './Loading';
import {HiOutlineLightBulb} from "react-icons/hi"
import { notifications } from "../layout/MainLayout";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import Dropdown from "./Dropdown";

import Notification from './Notification';
function TextSelector({questions, children, openQuestion, openQuestionBox, setOpenQuestionBox}) {
  const [showBox, setShowBox] = useState(false);
  const [explainText, setExplainText] = useState(<><Loading style={"h-auto "}/></>);
  const [showExplainText, setShowExplainText] = useState(false);
  const [maxWidth, setMaxWidth] = useState(32);
  const [notification, setNotification] = useState();
  const boxRef = useRef(null);
  const [dropdownQuestions, setDropdownQuestions] = useState()
  const [parentElement, setParentElement] = useState()
  const [filterQuestions, setFilterQuestions] = useState({})
  let parent = ""

  const prevCountRef = useRef();
    useEffect(() => {
        const handleScroll = () => {
          const box = boxRef.current;
          if (box.style.display === 'block') {
            let select = window.getSelection()
            const range = select.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const topOffset = rect.top + window.scrollY+6;
            box.style.top = topOffset + 'px';
            box.style.left ="50%"; // show box where selection starts
            setShowExplainText(false)
            setExplainText(<div className='p-3'><Loading /></div>)
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
  
    useEffect(() => {
      const handleClick = (event) => {
        const box = boxRef.current;
        if (box && !box?.contains(event.target) && !document.getElementById("questions")?.contains(event.target) && !document.getElementById("answer")?.contains(event.target)) {
          setShowBox(false);
          
          if(prevCountRef.current){
            prevCountRef.current.style.background = "white"
          }
          // prevCountRef.current.style.background = "white"
          setOpenQuestionBox(false)
          // prevCountRef.current = parentElement;
        }
      };
      window.addEventListener('mousedown', handleClick);
      return () => window.removeEventListener('mousedown', handleClick);
    }, []);
  

    function addSelectedTextId(filter) {
      if (!parent?.attributes["aria-comment"]){
        parent.style.background = "rgba(0, 155, 176, 0.2)"
      }
      return openQuestion(filter, filter,parent?.id )
    }
    
    useEffect(() => {

      function paint(e){
        if(parent){
          if (parent?.attributes["aria-comment"]){
            prevCountRef.current = "";
          }
          else{
            prevCountRef.current = parent;
          }
        }
      }

      document.addEventListener('selectionchange', paint);
      return () => {
        document.removeEventListener('selectionchange', paint);      
      };
    }, [parentElement, window.getSelection()])


    const handleSelect = () => {
      let select = window.getSelection()
      parent = select.anchorNode.parentElement
      prevCountRef.current = (parent?.attributes["aria-comment"])?"" :parent
      setParentElement(parent)
      if(questions){
        let question_to_filter = (questions?.prompting_general_answers?.length > 0) ? questions?.prompting_general_answers : questions?.yes_no_risks
        let ques = question_to_filter?.filter((a) => a.answer === "I do not know the answer.")
        ques.map((a,idx) => (questions?.prompting_general_answers?.length > 0) ? filterQuestions[a.prompt_text] = a.prompt_text : filterQuestions[a.origin_question] = a.origin_question )
                setDropdownQuestions(<Dropdown dropDownChild={addSelectedTextId} filters={filterQuestions} placeholder={"Undetected issues"} page={"review"} styles={"hover:rounded-md"}/>)

      }

        setMaxWidth(document.getElementById("doc-text")?.clientWidth - 32)
        
        
        
        if (select.toString()) {
          const range = select.getRangeAt(0);
          const rect = range.getBoundingClientRect();
          const topOffset = rect.bottom + window.scrollY - document.getElementById("doc-text").offsetTop+30;
          const box = boxRef.current;
          box.style.display = 'flex';
          setShowExplainText(false)
          setExplainText(<div className='p-3'><Loading style={"h-auto "}/></div>)
          if(rect.height > 34){
            box.style.left = "0px";
          }
          else{
            box.style.left = (rect.left-document.getElementById("doc-text").offsetLeft-32)+ 'px';
          }
          box.style.top = topOffset - 46 + 'px';
          setShowBox(true);
        } else {
          setShowBox(false);
          setDropdownQuestions("")
        }
      };
  
    const handleOptionClick = (option) => {
      
      const rect = window.getSelection().getRangeAt(0).getBoundingClientRect()
      const topOffset = rect.bottom + window.scrollY - document.getElementById("doc-text").offsetTop+30;
      const box = boxRef.current;
      box.style.top = topOffset - box.offsetHeight-10 + 'px';
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("token"),
          "Connection": "keep-alive",
        },
        body: JSON.stringify({
          context_text: window.getSelection().toString()
        }),
      }
    
      const fetchEli5 = async () => {
        setShowExplainText(true)
        const response = await fetch(
            process.env.REACT_APP_NLP_API_BASE_URL+"/eli5",
            requestOptions,
            {}
          ).then(async (r) => {
            return await r.json();
          })
        return response;
      };
      if(window.getSelection().toString().length < 2500){
        fetchEli5().then((result) => {
          setExplainText(<div className='px-3 py-2'>{result.result_text}</div>)
        })
      }
      else{
        setNotification (() => <Notification width={"w-3/12"}/>)
        notifications.push({
          icon: <AiOutlineCloseCircle size={25} color="red" />,
          upper_text: "Maximum characters exceded",
          lower_text: "Limit your search to 2500 characters, you selected "+window.getSelection().toString().length,
        });
      }
    };

    const [userComment, setUserComment] = useState()
    const [showUserComment, setShowUserComment] = useState(false)
    const handleAddComment = () => {
      setShowUserComment(true)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("token"),
          "Connection": "keep-alive",
        },
        body: JSON.stringify({
          context_text: window.getSelection().toString()
        }),
      }

          
      const postComment = async () => {
        
        const response = await fetch(
            process.env.REACT_APP_NLP_API_BASE_URL+"/eli5",
            requestOptions,
            {}
          ).then(async (r) => {
            return await r.json();
          })
        return response;
      };


    }
    

  return (
    <>
    {notification}
      <div onMouseUp={handleSelect}>
        {children}
      </div>
      {/* 0 23px 41px -8px rgba(0,0,0,1) */}
      <div id="text-box" ref={boxRef} className={`bg-white z-50 absolute shadow-[0_3px_18px_0px_rgba(0,155,176,0.3)] rounded-md Inter text-blue `} style={{ display: showBox ? 'flex' : 'none', maxWidth: maxWidth-32+"px"}}>
        <div className={"block"}>
        <div className={`${showExplainText ?"block" : "hidden"} text-black Inter border-2 rounded-md`} style={{ maxWidth: maxWidth-32+"px"}}>
          {explainText}
          <div className='bg-gray-200 p-1.5 pl-3 Inter text-sm text-gray-400 flex items-center'>
            <IoIosWarning color="gray" className="rounded mr-2" size={17} />
              AI explanations can be inaccurate</div>
        </div>
        <div className={`${showExplainText ?"hidden" : "flex"}`} >
          <button className='p-3 py-1.5 font-bold flex items-center whitespace-nowrap hover:bg-gray-50 hover:rounded-md' onClick={() => handleOptionClick('Option 1')}><HiOutlineLightBulb className='mr-2'/>Explain</button>
          {/* <button className='p-3 py-1.5 flex items-center whitespace-nowrap hover:bg-gray-50' onClick={() => handleAddComment()}><BiCommentDetail className='mr-2'/><span>Add comment</span></button> */}
          {dropdownQuestions}
          

          {/* <button className='p-3 py-1.5 flex items-center' onClick={() => handleOptionClick('Option 3')}><BsStars className='mr-2'/><span>Ask</span></button> */}
        </div>
        </div>

        
      </div>
    </>
  );
}


export default TextSelector;