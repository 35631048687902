import React from "react";

import { PaddleLoader } from "../../pages/paddle";
import { useGetPaymentUrlQuery } from "../../services/v1/getPaymentUrl";
import Loading from "../auth/Loading";
import { useFetchUserDataQuery } from "../../services/v1/getProfileData";


const Pricing = () => {

    const returnUrl = `${window.location.origin}/home/settings` || "https://app.legalreview.ai/home/settings"
    const { data: paymentUrl, isLoading: isPaymentUrlLoading } = useGetPaymentUrlQuery(
        "60971", returnUrl, {
        refetchOnWindowFocus: false,
    });

    const { data: profile, isLoading: isLoadingProfile } = useFetchUserDataQuery({
        refetchOnWindowFocus: false,
    });

    const Mailto = ({ email, subject, body, children }: { email: string, subject: string, body: string, children: any }) => {
        return (
            <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}
                className="w-full block text-base font-semibold bg-[#748AF2] text-white bg-primary border border-primary rounded-md text-center p-4 hover:bg-opacity-90 transition"
            >{children}</a>
        );
    };

    return (
        <section className="flex justify-center z-20 overflow-hidden bg-white pt-10 pb-6 lg:pt-[60px] lg:pb-[45px]">
            <div className="container">
                <div className="w-full px-4">
                    <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                        <span className="block mb-2 text-lg font-semibold text-primary">
                            Level-up your Contract Review
                        </span>
                        <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                            Our Pricing Plan
                        </h2>
                        <p className="text-base text-body-color">
                            Subscribe today and take advantage of unlimited Contract Reviews, services monitoring system and more.
                        </p>
                    </div>
                </div>

                {isPaymentUrlLoading || isLoadingProfile ? <Loading /> : <div className="flex justify-center -mx-4">
                    <div className="flex justify-center -mx-4">
                        <PricingCard
                            type="Free"
                            price="€0"
                            subscription="month"
                            description="Perfect for experimenting our contract review system"
                            buttonText={profile?.subscription_type === "free" ? "" : "Change To Free"}
                            buttonMetadata={{ url: profile?.cancel_url }}
                            active={profile?.subscription_type !== "free"}
                        >
                            <ListItem>3 Contract Reviews</ListItem>
                            <ListItem>Unlimited contract QAs</ListItem>
                        </PricingCard>
                        <PricingCard
                            type="Pro"
                            price="€20"
                            subscription="month"
                            description="Perfect for automating Contract Reviews and managing SaaS services"
                            buttonText="Subscribe Pro"
                            buttonMetadata={{ paddle: <PaddleLoader />, url: paymentUrl?.url }}
                            active={profile?.subscription_type !== "pro"}
                        >
                            <ListItem>Unlimited Contract Reviews</ListItem>
                            <ListItem>Unlimited Contract QAs</ListItem>
                            <ListItem>Use Projects to organize contracts</ListItem>
                            <ListItem>SaaS Contract changes monitoring</ListItem>
                        </PricingCard>
                        <PricingCard
                            type="Enterprise"
                            price=""
                            subscription=""
                            description="Contact Sales Team (sales@legalreview.ai for the best deal)"
                            buttonText="Email Sales Team"
                            buttonMetadata={{ openMail: <Mailto email="sales@legalreview.ai" subject="[Enterprise Subscription]" body="">Email Sales Team</Mailto> }}
                            active={true}
                            isEnterprise={true}
                            enterpriseNotice="Contact sales team for solution tailored to your needs"
                        >
                            <ListItem>Unlimited Contract Reviews</ListItem>
                            <ListItem>Unlimited Contract QAs</ListItem>
                            <ListItem>Free updates</ListItem>
                            <ListItem>Use Projects to organize contracts</ListItem>
                            <ListItem>SaaS Contract changes monitoring</ListItem>
                        </PricingCard>
                    </div>
                </div>}
            </div>
        </section>
    );
};

export default Pricing;

const PricingCard = ({
    children,
    description,
    price,
    type,
    subscription,
    buttonText,
    buttonMetadata,
    active,
    isEnterprise = false,
    enterpriseNotice,
}: {
    children: any,
    description: string,
    price: string,
    type: string,
    subscription: string,
    buttonText: string,
    buttonMetadata: any,
    active: boolean,
    isEnterprise?: boolean,
    enterpriseNotice?: string
}) => {
    return (
        <>
            <div className="w-full px-4 md:w-2/3 lg:w-1/2">
                <div className="relative z-10 px-8 py-10 mb-10 overflow-hidden bg-white border rounded-xl border-primary border-opacity-20 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12">
                    <span className="block mb-4 text-lg font-semibold text-primary">
                        {type}
                    </span>
                    {!isEnterprise ? (
                        <h2 className="mb-5 text-[42px] font-bold text-dark">
                            {price}
                            <span className="text-base font-medium text-body-color">
                                / {subscription}
                            </span>
                        </h2>
                    ) : (
                        <h2 className="mb-5 text-[32px] leading-tight">
                            {enterpriseNotice}
                        </h2>
                    )}
                    <p className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color">
                        {description}
                    </p>
                    <ul className="mb-7">
                        <p className="mb-1 text-base leading-loose text-body-color">
                            {children}
                        </p>
                    </ul>
                    {active && buttonText !== "" ?
                        !buttonMetadata?.openMail ?
                            <a
                                href={buttonMetadata?.url ? buttonMetadata.url : ""}
                                className={` ${active
                                        ? ` w-full block text-base font-semibold bg-[#748AF2] text-white bg-primary border border-primary rounded-md text-center p-4 hover:bg-opacity-90 transition`
                                        : `block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-primary hover:bg-primary hover:text-white`
                                    } `}
                            >
                                {buttonText}
                            </a> : buttonMetadata?.openMail : <></>
                    }
                    <div>
                        <span className="absolute right-0 top-7 z-[-1]">
                            <svg
                                width={77}
                                height={172}
                                viewBox="0 0 77 172"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear"
                                        x1={86}
                                        y1={0}
                                        x2={86}
                                        y2={172}
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                                        <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </span>
                        <span className="absolute right-4 top-4 z-[-1]">
                            <svg
                                width={41}
                                height={89}
                                viewBox="0 0 41 89"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="38.9138"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="1.42021"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 1.42021)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="1.4202"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 1.4202)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="1.42019"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 1.42019)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="1.4202"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 1.4202)"
                                    fill="#3056D3"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

const ListItem = ({ children }: { children: any }) => {
    return (
        <>
            <p className="mb-1 text-base leading-loose text-body-color">
                <svg className="h-6 w-5 flex-none text-indigo-600 inline-block mr-2" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd" />
                </svg>
                {children}
            </p>
        </>
    );
};
