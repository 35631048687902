// src/components/login-button.js

import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import Loading from 'react-loading';
import LegalReview from "../../assets/images/beta_full_logo.png"

const LoginButton = () => {
  // window.localStorage.clear()
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div> .</div>;
  }
  else{
    return (
      <>
      
          <div className="bg-gray-50 flex items-center justify-center h-screen w-screen">
            <div className="max-w-sm text-center">
              <div className='mb-10'>
                <img src={LegalReview} alt="React Logo" className='m-auto'/>
              </div>
              
              <div className='p-5'>
                <button id="login" className="mr-10 justify-center items-center rounded-full bg-blue py-3 px-5 text-md text-white hover:bg-[#225187] fade-in duration-300 focus:outline-none cursor-pointer"
                  onClick={() => loginWithRedirect()}>
                  <span className="navbar-font">Log In</span>
                </button>
                <button id="login" className="justify-center items-center rounded-full bg-blue py-3 px-5 text-md text-white hover:bg-[#225187] fade-in duration-300 focus:outline-none cursor-pointer"
                  onClick={() => loginWithRedirect()}>
                  <span className="navbar-font">Sign Up</span>
                </button>
              </div>
            </div>
          </div>
      </>

    );
  }

};

export default LoginButton;