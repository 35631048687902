import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Pagination from "../../components/Single_Components/Pagination";
import { useState, useEffect } from "react";
import Container from "../../components/Single_Components/Container";
import Notification from "../../components/Single_Components/Notification";
import Loading from "../../components/Single_Components/Loading";
import React from "react";
import {useGetCompaniesDocumentsAsQuery} from "../../services/v1/getCompaniesDocuments";
import {CompanyDocuments} from "../../services/v1/interfaces";
import {getFlagEmoji, TOP_COUNTRIES_CODES} from "./countries_utils";
import {BiBuildings} from "react-icons/bi";
import {NavLink, useNavigate} from "react-router-dom";
import {openDocumentShareLink} from "./utils";
import {AiOutlinePlusCircle} from "react-icons/ai";

export default function SaasCompanies() {

    const [postsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const { data: companiesDocuments, isLoading: isLoading } =
        useGetCompaniesDocumentsAsQuery({
            refetchOnWindowFocus: false,
        });
    const [searchedCompanyDocuments, setSearchedCompanyDocuments] = useState<CompanyDocuments[]>([]);
    const [companySearchText, setCompanySearchText] = useState<string>("");

    const navigate = useNavigate();

    let indexOfLastPost = currentPage * postsPerPage;
    let indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const forward = (pageNumber: number) => setCurrentPage(pageNumber + 1);
    const backward = (pageNumber: number) => setCurrentPage(pageNumber - 1);

    const [pageCompanyDocuments, setPageCompanyDocuments] = useState<CompanyDocuments[]>([]);

    const searchCompany = (e: any) => {
        const companies = companiesDocuments?.companies || []
        let searchedCompanies = [...companies];
        searchedCompanies = searchedCompanies.filter((company) =>{
                return company.company_name.toLowerCase()?.includes(e.target.value.toLowerCase())
            }
        );
        setSearchedCompanyDocuments(searchedCompanies);
        setCompanySearchText(e.target.value);
    };

    useEffect(() => {
        const companies = companiesDocuments?.companies || []
        setSearchedCompanyDocuments(companies)
        setCompanySearchText("");
    }, [isLoading]);


    useEffect(() => {
        setPageCompanyDocuments([...searchedCompanyDocuments]
            .sort((a:any, b:any) => a.company_name.localeCompare(b.company_name))
            .slice(indexOfFirstPost, indexOfLastPost)
        )
    }, [searchedCompanyDocuments, indexOfFirstPost, indexOfLastPost]);

    return (
        <Container>
            <div className="mt-11 mb-11">
                <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible  max-h-screen">
                    <h1 className="text-2xl Inter font-black pb-7">
                        <BiBuildings className="inline-block mr-3" size={30}/>
                        SaaS Companies
                    </h1>
                    <Notification children={undefined} width={undefined} />
                    <span className="block Inter text-sm mb-5">
                      Legalreview monitors a catalog of the most popular software services, so any user can track and easily understand their rights when using such services.
                    </span>
                    <div className="flex justify-between mb-8">
                        <div className="sm:flex-auto mb-2">
                            <div className="">
                                <div className="w-4/4">
                                    <div className="relative text-[#6B7280] focus-within:text-gray-400">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <MagnifyingGlassIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <input
                                            id="search"
                                            name="lookup"
                                            className="block w-1/3 rounded-md border border-transparent bg-[#E8EAEE] py-2 pl-10 pr-3 leading-5 text-black placeholder-[#6B7280] focus:bg-[#F8FAFE] fade-in duration-100 focus:outline-none sm:text-sm"
                                            placeholder="Search Companies"
                                            type="search"
                                            value={companySearchText}
                                            onChange={searchCompany}
                                        />
                                    </div>
                                </div>
                                <br/>
                            </div>
                            {/*  */}
                        </div>
                        <div>
                            <NavLink
                                to={`/home/upload`}>
                                <button className="border-[#748AF2] text-l bg-transparent text-[#748AF2] shadow border-2 rounded-lg p-2 pr-4 pl-4 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer"
                                        type="submit">
                                    <AiOutlinePlusCircle className="mr-3" size={23} />
                                    Review Custom Company Document
                                </button>
                            </NavLink>
                        </div>
                    </div>

                    {isLoading ? <Loading style={{}}/> : <div className="-mx-4 rounded-xl sm:-mx-6 md:mx-0 min-h-[700px]">
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="Inter w-full text-center text-xs font-bold text-[#6B7280] bg-[#F9FAFB] ">
                            <tr>
                                <th scope="col" className="px-3 py-3.5 w-1/4 text-left">
                                    Company
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/4">
                                    Jurisdiction
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/6">
                                    NºApps
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/6">
                                    NºCertificates
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/12">
                                    T&C
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/12">
                                    Privacy Policy
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/12">
                                    DPA
                                </th>


                            </tr>
                            </thead>
                            <tbody className="divide-y text-center divide-gray-200 bg-white">
                            {pageCompanyDocuments && pageCompanyDocuments?.map((company: CompanyDocuments) => {
                                const companyCountryName: string = company.company_metadata?.country?.name
                                const companyCountryCode = TOP_COUNTRIES_CODES[companyCountryName]
                                return (<tr className="even:bg-[#F9FAFB]" key={company.id} onClick={()=>navigate(`/home/companies/${company.id}/details`)}>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis">
                                        <div className="flex items-center">
                                            <div className="flex flex-col">
                                                {company.company_logo.includes("clearbit") ? <img src={company.company_logo} alt="Company logo" width="30px"
                                                     className=""/> :
                                                <BiBuildings color="gray" size={30}/>
                                                }
                                            </div>
                                            <div className="ml-4">
                                                <span className="py-1 px-3 cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150 text-blue"
                                                      onClick={()=>navigate(`/home/companies/${company.id}/details`)}
                                                >{company.company_name}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                                        {companyCountryCode ? getFlagEmoji(companyCountryCode): <>-</>} {companyCountryName}
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                                        {company.apps.length}
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                                        {company.certificates.length}
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis">
                                        {
                                            company.documents["T&C"] ?
                                                openDocumentShareLink(navigate, company.documents["T&C"])
                                                : <>-</>
                                        }
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                                        {
                                            company.documents["Privacy Policy"] ?
                                                openDocumentShareLink(navigate, company.documents["Privacy Policy"])
                                                : <>-</>
                                        }
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                                        {
                                            company.documents["DPA"] ?
                                                openDocumentShareLink(navigate, company.documents["DPA"])
                                                : <>-</>
                                        }
                                    </td>

                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>}
                </div>
                <div>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={companiesDocuments?.length}
                        paginate={paginate}
                        forward={forward}
                        backward={backward}
                        currentPage={currentPage}
                    />
                </div>
            </div>
        </Container>
    );
}
