import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { notifications } from "../layout/MainLayout";
import { AiOutlineClockCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function Modal(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };
  const closeModalDocuments = () => {
    setOpen(false);
    props.closeModal();
    notifications.push({
      icon: <AiOutlineCheckCircle size={25} color="green" />,
      upper_text: "Success",
      lower_text: "Document was submitted for review with success!",
    });
    navigate("/home/contracts");
  };

  const closeModalReview = () => {
    setOpen(false);
    props.closeModal();
    notifications.push({
      icon: <AiOutlineCheckCircle size={25} color="green" />,
      upper_text: "Success",
      lower_text: "Document was submitted for review with success!",
    });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className=" relative transform scale-125 overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-bold leading-6 text-gray-900 Poppins">
                      Success!
                    </Dialog.Title>
                    <div className="mt-2 mb-5">
                      <p className="text-sm text-gray-400 Poppins font-bold">
                        Document Submitted for review
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex bg-[#FDD134]/[.3] rounded-xl p-5">
                  <div className="mr-6">
                    <AiOutlineClockCircle size={30} />
                  </div>
                  <div className="block  Poppins">
                    <span className="text-[13px] font-bold block mb-2">
                      Document Review might take a few minutes to complete
                    </span>
                    <div className="text-[10px] flex">
                      <div className="mr-1.5">
                        <HiOutlineLightBulb size={15} color="#F9C107" />
                      </div>
                      <span>
                        {" "}
                        An email will be sent to you once the review is
                        completed/ready/done
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex mt-5 sm:mt-6 justify-center ">
                  <button
                    type="button"
                    className="inline-flex w-1/2 justify-center rounded-xl border-2 border-blue bg-white px-4 py-2 font-medium text-blue shadow-sm hover:bg-blue hover:text-white transition-all duration-200 focus:outline-none sm:text-sm"
                    onClick={() => closeModalDocuments()}>
                    My Documents
                  </button>
                  {/* <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-xl border-2 border-blue bg-white px-4 py-2 font-medium text-blue shadow-sm hover:bg-blue hover:text-white transition-all duration-200 focus:outline-none sm:text-sm"
                    onClick={() => closeModalReview()}
                  >
                    New Review
                  </button> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
