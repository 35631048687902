import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {TrademarkClassificationResponse} from "./interfaces";

export const classifyTrademark = async (
    trademark_description: string
): Promise<any> => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token"),
    }} ;
    console.log("trademark_description:", trademark_description)
    return await axios
      .post<TrademarkClassificationResponse>(`${process.env.REACT_APP_NLP_API_BASE_URL}/trademarks/classify`, {
          trademark_definition: trademark_description
      }, requestOptions)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error classifying Trademark`, error);
        return Promise.reject(error);
      });
};

export function useClassifyTrademark(
    trademark_description: string,
  options?: QueryOptionsOmit<
    UseQueryOptions
  >
) {
  return useQuery(
    [trademark_description],
    () => {
      return classifyTrademark(trademark_description);
    },
    options as any
  );
}
