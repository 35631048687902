import {  React, useState, useEffect  } from "react";
import Breadcrumb from "../../../components/Single_Components/Breadcrumbs";
import DocumentHeader from "../../../components/Single_Components/DocumentHeader";
import { useOutletContext } from "react-router-dom";
import FetchComparison from "../../../assets/FetchComparison";
import { useParams } from "react-router-dom";
import SummaryComponent from "./SummaryComponent";
import Checklist from "./Checklist";

// import test from "./test.json"
function ComparisonSummary() {
    const [document1, setDocument, text, comments, fetchComments] = useOutletContext();

    const { id } = useParams();

    // const [document, setDocument] = useState([]);

    const [documentFinal, setDocumentFinal] = useState(document);
    const [date, setDate] = useState("");
    const [name, setName] = useState("");
    const [shareID, setShareId] = useState("");

    useEffect(()=>{
        let temp = ""
        if(document1?.result && document1?.result[0]?.result?.side_by_side_comparison){
            temp = document1?.result[0]
            setDocumentFinal(temp?.result)
            setDate(temp?.updated_at.replaceAll("-","/").split("T")[0])
            setName(temp?.document_name)
            setShareId(document1?.unique_id);

        }
    },[document1])

    return ( <div  className="lg:min-w-navbar mt-0  my-6 mx-5" >
        <Breadcrumb page={"Summary"} docname={name}/>
        <DocumentHeader props={[documentFinal, "comparison/"+id+"/inline_review", "View inline review",date, name,"",shareID]}/>
        <SummaryComponent props={documentFinal} playbook_id={-1}/>
        {/* <SideBySide props={[document]}/> */}
         
        <Checklist props={documentFinal}/>
        {/* <UserQuestions props={documentFinal} id={id}/>  */}
    </div>)
} 


export default ComparisonSummary;