import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';

export const protectedShareJobByUniqueID = async (
    UNIQUE_ID: string,
    companyDocumentId: string
): Promise<any> => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json',
    Authorization: localStorage.getItem("token"),
  }} ;
  return await axios
    .post<any>(`${process.env.REACT_APP_NLP_API_BASE_URL}/companies/${companyDocumentId}/share`, {
      unique_id: UNIQUE_ID
    }, requestOptions)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error sharing job`, error);
      return Promise.reject(error);
    });
};

export function useProtectedShareJob(
    UNIQUE_ID: string,
    companyDocumentId: string,
    options?: QueryOptionsOmit<UseQueryOptions>
) {
  return useQuery(
    [UNIQUE_ID, companyDocumentId],
    () => {
      return protectedShareJobByUniqueID(UNIQUE_ID, companyDocumentId);
    },
    options as any
  );
}
