import React, { useState, useRef, useEffect } from "react";
import {NavLink} from "react-router-dom"
import { Replace_idk_answer } from "../../../assets/GeneralFunctions";
import CommentAnswerReferences from "../../../components/Single_Components/CommentAnswerReferences";
export default function UserQuestions({props, id}) {
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
    const tabsRef = useRef([]);
    let system_qas = props?.summary?.prompting_general_answers.sort((a, b) => b.answer_references.length - a.answer_references.length) ?? []
    console.log('system_qas', system_qas);
    let user_qas = props?.summary?.user_qas ?? []


    const tabsData = [

        {
        label: "Your Questions",
        value: user_qas.length,
        content: (<div className="rounded-xl overflow-hidden ">
        <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                    <tr>
                        <th scope="col" className="px-6 py-5 w-2/12">
                            Question
                        </th>
                        <th scope="col" className="px-6 w-7/12">
                            Answer
                        </th>
                        <th scope="col" className="px-6 w-2/12 text-center">
                            References
                        </th>
                    </tr>
                </thead>
            <tbody>
            {user_qas.map((qa) => {
                return(
                    <tr key={qa.origin_question} className="even:bg-[#F9FAFB] border-b">
                    <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 ">
                        <div className="block">
                            <div className="w-full font-extralight text-gray-500 pb-2">{qa.tag_name}</div>
                            <div className="w-full font-bold">{qa.prompt_text}</div>
                        </div>
                        
                    </th>
                    <td className="px-6 py-4 whitespace-pre-wrap max-w-[200px] ">
                        <div className="block ">
                            <div className="w-full font-bold text-[#748AF2] pb-2">{Replace_idk_answer(qa.answer)}</div>
                        </div>
                    </td>
        
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                        <CommentAnswerReferences
                            job_id={id}
                            are_refs_visible={qa?.are_refs_visible}
                            references={qa?.answer_references}
                        />
                    </td>

                </tr>
                )
            })}
            </tbody>
        </table>
</div>)
        },       {
            label: "System Questions",
            value: system_qas.length,
            content: (<div className="rounded-xl overflow-hidden">
            <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                    <tr>
                        <th scope="col" className="px-6 py-5 w-2/12">
                            Question
                        </th>
                        <th scope="col" className="px-6 w-7/12">
                            Answer
                        </th>
                        <th scope="col" className="px-6 w-2/12 text-center">
                            References
                        </th>
                    </tr>
                </thead>
                <tbody>
                {system_qas.map((qa) => (
                    
                    <tr key={qa.origin_question} className="even:bg-[#F9FAFB] border-b">
                        <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 ">
                            <div className="block">
                                <div className="w-full font-extralight text-gray-500 pb-2">{qa.tag_name}</div>
                                <div className="w-full font-bold">{qa.prompt_text}</div>
                            </div>
                            
                        </th>
                        <td className="px-6 py-4 whitespace-pre-wrap max-w-[200px] ">
                            <div className="block ">
                                <div className="w-full font-bold text-[#748AF2] pb-2">{Replace_idk_answer(qa.answer)}</div>
                            </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-center">
                            <CommentAnswerReferences
                                job_id={id}
                                are_refs_visible={qa?.are_refs_visible}
                                references={qa?.answer_references}
                            />
                        </td>
    
                    </tr>
                    ))}
                </tbody>
            </table>
    </div>)
            },

    ]
    if(user_qas.length === 0)
        tabsData.shift()
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }
        
        setTabPosition();
        window.addEventListener("resize", setTabPosition);
    
        return () => window.removeEventListener("resize", setTabPosition);
    }, [activeTabIndex]);

    if(user_qas.length === 0 && system_qas.length === 0)
        return null
    return (
        <div className="bg-white rounded-xl border shadow-sm mb-5 p-7 pt-12">
            <span className="Inter font-black text-2xl">Document Understanding Assessment</span>
            <div className="mt-7 border-b-2 border-[#EFF0F6]">
                {tabsData.map((tab, idx) => {
                    return (
                    <button
                        key={idx}
                        ref={(el) => (tabsRef.current[idx] = el)}
                        className={`pb-5 mr-7 border-b-2 transition-colors duration-300 ${
                        idx === activeTabIndex
                            ? "border-[#748AF2] text-[#748AF2]"
                            : "border-transparent hover:border-gray-200"
                        }`}
                        // Change the active tab on click.
                        onClick={() => setActiveTabIndex(idx)}>
                        <span className="mr-4">{tab.label}</span><span className="p-1 px-2.5 text-sm text-[#748AF2] bg-[#748AF2]/[.2] rounded-full">{tab.value}</span>
                    </button>
                    );
                })}
            </div>
                <span className="absolute bottom-0 block h-1 bg-teal-500 transition-all duration-300" />
            <div className="mt-7">
                <span className="">{tabsData[activeTabIndex].content}</span>
            </div>
        </div>

        
    );
    }