import React from "react";
import {protectedShareJobByUniqueID} from "../../services/v1/protectedShareJob";
import {CompanyDocument} from "../../services/v1/interfaces";

const shareHandler = async (navigate: any, url: string, companyDocumentId: string) => {
    const uniqueId = url.split("share/").pop() || ""
    const shareResult = await protectedShareJobByUniqueID(uniqueId, companyDocumentId);
    console.log("shareResult:", shareResult)
    const jobId = shareResult.job_id
    navigate(`/contract/${jobId}/summary`);
}

export const openDocumentShareLink = (navigate: any, companyDocument: CompanyDocument) => <a
    key={companyDocument.share_url}
    target="_blank" rel="noopener noreferrer"
    onClick={()=>shareHandler(navigate, companyDocument.share_url, companyDocument.id)}
    className={
        companyDocument.has_access ?
            "py-1 px-3 rounded-full bg-baby-blue text-right text-blue Inter text-xs hover:bg-blue hover:bg-opacity-50 fade-in duration-300 focus:outline-none cursor-pointer"
            :
            "py-1 px-3 rounded-full text-gray-600 text-opacity-25 bg-opacity-10 bg-gray-500 fade-in duration-300 cursor-no-drop"
    }>
    view
</a>