import Disclaimer from "../components/Single_Components/Disclaimer";
import LegalReview from "../assets/images/LegalReview.png";
import Share from "../assets/images/share.png";

import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import FetchUserInfo from "../assets/FetchBalance";
import Loading from "../components/Single_Components/Loading";

export default function ShareLandingPage() {
  const [button, setButon] = useState(false);
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading, loginWithRedirect,  } = useAuth0();

  const [Userinfo, setUserinfo] = useState(0);
  const [acceptedDisclaimer, setAcceptDisclaimer] = useState(false);
  const [loading, setLoading] = useState(true);

  const PatchUserToC = async () => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };

    const fetchUserInfo = async () => {
      return await axios.patch(
        process.env.REACT_APP_DOMAIN + "/api/users/" + Userinfo.id+"/",
        { terms_accepted: true },
        requestOptions
      );
    };
    return await fetchUserInfo();
  };

  useEffect(() => {
    FetchUserInfo().then((result) => {
      setUserinfo(result);
      setAcceptDisclaimer(result.terms_accepted);
    });
    localStorage.setItem(
      "uniqueID",
      window.location.pathname.split("share/")[1]
    );
  }, []);

  return (
    <>
      <div className="w-full h-[50vh] bg-[#748AF2] items-center">
        <div className="flex h-screen">
          <div className="pb-2 m-auto max-w-[700px] bg-white rounded-2xl drop-shadow-xl">
            <div className="mt-10 h-[70px] max-w-[400px] mb-5 flex mx-auto">
              <img src={LegalReview} alt=" React Logo" />
            </div>
            <div className="mx-10 block p-10 ">
              <div className="text-center Inter font-bold text-xl mb-5">
                👋 Welcome
              </div>
              <div className="font-light text-sm Inter">
                Someone shared with you a document that has been reviewed by Legalreview.ai
                proprietary AI. This is a review performed independently by AI.
              </div>
              <div className="mt-5 h-[140px] 2xl:mt-10 2xl:h-[180px] max-w-[600px] justify-center mb-5 flex mx-auto">
                <img src={Share} alt="Share" />
                <div className="block ml-24 self-center Inter">
                  <ul className="list-disc text-sm font-light">
                    <li className="mb-8">Risk assessment</li>
                    <li className="mb-8">Tailored insights</li>
                    <li>Data extraction</li>
                  </ul>
                </div>
              </div>
              <div className="block border-l-8 border-l-[#748AF2] pl-5 mb-10">
                <span className="block Inter font-bold text-md mb-3">
                  What is Legalreview.ai?
                </span>
                <span className="block Inter font-light text-sm">
                  Our goal is to speed up the process of reviewing legal documents by using curated Artificial Intelligence tools. Legalreview.ai makes it possible to review a document in just a few minutes with helpful insights, saving you time and money on legal costs.
                </span>
              </div>
              <div className={`text-left 2xl:mt-10`}>
                <span className="Inter font-light text-sm">
                  Want to see the document in full detail?
                </span>
                <a
                  id="login"
                  className="justify-center items-center text-[#748AF2] py-3 px-5 text-md text-white hover:text-[#225187] fade-in duration-300 focus:outline-none cursor-pointer"
                  onClick={() => loginWithRedirect()}>
                  <span className="navbar-font">Log In</span>
                </a>
                <span className="mr-6">or</span>
                <button
                  id="login"
                  className="mr-3 justify-center items-center rounded-full bg-[#748AF2] py-1 px-5 text-md text-white hover:bg-[#225187] fade-in duration-300 focus:outline-none cursor-pointer"
                  onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>
                  <span className="navbar-font">Sign Up</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
