import React from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import References from '../References/References';
import { scrollElementIntoView } from '../../../../../../helpers/scrollElementIntoView';

interface IQuestionItemProps {
  question: any;
  riskScore?: number;
}

const QuestionItem: React.FC<IQuestionItemProps> = ({
  question,
  riskScore,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const nodeID = searchParams.get('id');

  const initialReference = React.useMemo(() => {
    return (
      question.answer_references.find(
        (ref: any) => ref.document_element_id === nodeID
      ) || question.answer_references[0]
    );
  }, [nodeID, question.answer_references]);

  React.useEffect(() => {
    const activeReference = question.answer_references.find(
      (ref: any) => ref.document_element_id === nodeID
    );
    if (activeReference) {
      setExpanded(true);
      const refNode: any = document.querySelector(
        `[data-id="${activeReference.ref_id}"]`
      );
      scrollElementIntoView(refNode)
    } else {
      setExpanded(false);
    }
  }, [nodeID, question.answer_references]);

  
  return (
    <div
      className={`${
        expanded ? 'bg-[#FCFCFF]' : 'bg-[#FFF]'
      } rounded border-l-2 border-l-[#F1F3F8] border-[1px] border-[#F1F3F8] `}
    >
      <div
        className="border-b-[1px] border-b-[#F1F3F8]  p-3 flex justify-between items-start cursor-pointer"
        onClick={() => {
          setExpanded(!expanded);
          if (question.answer_references[0]?.document_element_id && !expanded) {
            setSearchParams((params) => {
              params.set(
                'id',
                question.answer_references[0].document_element_id
              );
              return params;
            });
          }
        }}
      >
        <h3 className="text-xs font-semibold">{question.prompt_text}</h3>
        <span className="cursor-pointer">
          {expanded ? (
            <AiOutlineMinusCircle
              size={18}
              color="#748AF2"
              onClick={() => setExpanded(false)}
            />
          ) : (
            <AiOutlinePlusCircle size={18} onClick={() => setExpanded(true)} />
          )}
        </span>
      </div>
      <div
        className={`${expanded ? 'static' : 'hidden'} p-3 flex flex-col gap-7`}
      >
        <div>
          <p className="text-xs">{question?.answer}</p>
        </div>
        <References
          references={question.answer_references}
          initialReference={initialReference}
        />
        <div className="flex justify-between items-center">
          <p className="text-xs text-[#8D92A7]">{question?.tag_name}</p>
          <span className="flex gap-2">
            <FiThumbsUp
              color="#8D92A7"
              size={20}
              className="cursor-pointer hover:!text-[#748AF2]"
            />
            <FiThumbsDown
              color="#8D92A7"
              size={20}
              className="cursor-pointer hover:!text-[#748AF2]"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
