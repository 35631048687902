import React, { useState } from "react";
import Container from "../components/Single_Components/Container";
import {FiSettings} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import {Divider} from "antd";
import {useFetchUserDataQuery} from "../services/v1/getProfileData";
import Loading from "../components/Single_Components/Loading";
import {useDeleteAccountMutation} from "../services/v1/deleteAccount"
import { useAuth0 } from '@auth0/auth0-react';
import { notifications } from "../components/layout/MainLayout";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import Notification from "../components/Single_Components/Notification";

const Settings = () => {

    const { data: profile, isLoading: isLoadingProfile } = useFetchUserDataQuery({
        refetchOnWindowFocus: true,
    });
    const [showModal, setShowModal] = useState(false);
    const deleteAccountMutation = useDeleteAccountMutation();
    const handleDeleteClick = () => {
        setShowModal(true);
    };
    const { user, logout } = useAuth0();
    const handleConfirmDelete = () => {
        deleteAccountMutation.mutate(profile.id, {
            onSuccess: () => {
                notifications.push({
                    icon: <AiOutlineCheckCircle size={25} color="green" />,
                    upper_text: "Success",
                    lower_text: "Account deleted with success! You will be logged out now.",
                  });
                setShowModal(false);
                setTimeout(4000);
                window.localStorage.clear()
                logout({
                    returnTo: window.location.origin,
                })
            },
            onError: (error) => {
                console.error("Error deleting account:", error);
                notifications.push({
                    icon: <AiOutlineCloseCircle size={25} color="red" />,
                    upper_text: "Failed",
                    lower_text: "Account was not deleted. Server returned error.",
                  });
            }
        });
        setShowModal(false);
        
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    return (
        <div className="mt-12">
            <Notification />
            <div className='m-8 flex justify-between items-center'>
                <div className='font-black text-xl Inter'>
                    <FiSettings className="inline-block mr-3" size={30}/>
                    Your Settings
                    
                </div>
            </div>
            {
                isLoadingProfile ? <Loading/>
                    :
                    <div className="w-full">
                <Container>
                    <div className='m-8 block justify-between items-center'>
                    <div className="flex justify-between items-center mb-4">
                    <div>
                        <label className="Inter font-bold text-lg">
                            Profile
                        </label>
                        <p className="Inter text-s text-[#6B7280]">
                            Consult and configure your personal information and payment data.
                        </p>
                    </div>
                    <button 
                        type="button"
                        onClick={handleDeleteClick}
                        className="focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-red-500 text-white hover:bg-red-600">
                        Delete Account
                    </button>
                    
                    {showModal && (
                        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-8 rounded shadow-lg">
                                <h2 className="text-xl mb-4">Confirm Deletion</h2>
                                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                                <div className="mt-6 flex justify-end">
                                    <button onClick={handleCancelDelete} className="mr-2 px-4 py-2 rounded bg-gray-300 hover:bg-gray-400">
                                        Cancel
                                    </button>
                                    <button
                                    onClick={handleConfirmDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                       
                        <Divider></Divider>
                        <ul role="list" className="divide-y divide-gray-100">
                            <li key="0" className="flex py-5 w-full">
                                <div className="flex w-1/3">
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">Email</p>
                                    </div>
                                </div>
                                <div className="hidden sm:flex sm:flex-col sm:items-start w-1/3">
                                    <p className="text-sm leading-6 text-gray-900">{profile?.email}</p>
                                </div>
                                <div className="hidden sm:flex sm:flex-col sm:items-end w-1/3">
                                    <p className="text-sm leading-6 text-gray-900"></p>
                                </div>
                            </li>
                            <li key="1" className="flex py-5 w-full">
                                <div className="flex w-1/3">
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">Subscription</p>
                                    </div>
                                </div>
                                <div className="hidden sm:flex sm:flex-col sm:items-start w-1/3">
                                    <p className="text-sm leading-6 text-gray-900">{profile?.subscription_type}</p>
                                </div>
                                <div className="hidden sm:flex sm:flex-col sm:items-end w-1/3">
                                {
                                    profile?.subscription_type === "free" ?
                                        <NavLink
                                            to={`/home/pricing`}
                                            className="">
                                            <button className="border-[#748AF2] text-xl bg-transparent text-[#748AF2] shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer"
                                                    type="submit">
                                                Upgrade Subscription
                                            </button>
                                        </NavLink>
                                        :
                                        <>
                                            <a
                                                href={profile?.update_url}
                                                className="">
                                                <button className="border-[#748AF2] text-l bg-transparent text-[#748AF2] shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer"
                                                        type="submit">
                                                    Update Subscription
                                                </button>
                                            </a>
                                            <a
                                                href={profile?.cancel_url}
                                                className="">
                                                <button className="text-l bg-transparent text-pink-700 p-3 pr-5 pl-5 flex items-center hover:underline fade-in duration-300 cursor-pointer"
                                                        type="submit">
                                                    Cancel Subscription
                                                </button>
                                            </a>
                                        </>

                                }
                                </div>
                            </li>
                        </ul>
                    </div>
                </Container>
                    </div>
            }
        </div>);

};

export default Settings;