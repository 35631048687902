import { BubbleMenu, Editor } from '@tiptap/react';
import React from 'react';

interface ICustomBubbleMenuProps {
  editor?: Editor;
}

const CustomBubbleMenu: React.FC<ICustomBubbleMenuProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }
  return (
    <BubbleMenu
      shouldShow={(props) => {
        return props.from !== props.to;
      }}
      editor={editor}
      tippyOptions={{ duration: 100 }}
    >
      <div className="p-5 bg-slate-400 rounded">
        <button
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Explain
        </button>
      </div>
    </BubbleMenu>
  );
};

export default CustomBubbleMenu;
