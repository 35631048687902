import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import AuthNav from "../auth/auth-nav";
import { useAuth0 } from "@auth0/auth0-react";
import TopNav from "./Topnav";
import { CSSTransition } from "react-transition-group";

import DropdownMenu from "./Submenu";
import UploadContract from "../../pages/UploadContract";
import {useFeatureFlagEnabled, usePostHog} from "posthog-js/react";
import {CgSoftwareUpload} from "react-icons/cg";
import ListContracts from "../../pages/ListContracts";
import {HiOutlineDocumentSearch} from "react-icons/hi";
import Projects from "../../pages/Projects";
import {AiTwotoneFolderOpen} from "react-icons/ai";
import Redaction from "../../pages/Anonymization";
import {BiBuildings, BiHide} from "react-icons/bi";
import SearchDocuments from "../../pages/SearchDocuments";
import {MdOutlineScreenSearchDesktop} from "react-icons/md";
import SaasCompanies from "../../pages/SaasCompanies";
import UserCompaniesMonitoringPage from "../../pages/SaasCompanies/monitoring";
import {TbHeartRateMonitor} from "react-icons/tb";
import TrademarksPage from "../../pages/trademarks";
import {TiBusinessCard} from "react-icons/ti";
import Settings from "../../pages/Settings";
import {FiSettings} from "react-icons/fi";
import {posthog} from "posthog-js";
export default function Sidebar({props, close}) {
    const { user, isAuthenticated } = useAuth0();
    const [dropdown, setDropdown] = useState(false);

    const ph = usePostHog()

    useEffect( () => {
        if (user) {
            posthog.identify(user.email, {
                email: user.email,
            })
        }
    }, [ph, user])

    function menuClick() {
        setDropdown((prev) => !prev);
    }

    const settingsEnabled = useFeatureFlagEnabled(
        '[FE]-[Homepage]-make_settings_visible'
    )
    const projectsEnabled = useFeatureFlagEnabled('[FE]-[Global]-Projects')
    const anonEnabled = useFeatureFlagEnabled('[FE]-[Global]-Redaction')
    const searchDocumentsEnabled = useFeatureFlagEnabled(
        '[FE]-[Global]-search_documents'
    )
    const saasCompaniesEnabled = useFeatureFlagEnabled(
        '[FE]-[Global]-saas_companies'
    )

    const trademarksEnabled = useFeatureFlagEnabled(
        '[FE]-[Global]-trademarks'
    )
    const myMonitoringEnabled = useFeatureFlagEnabled(
        '[FE]-[Global]-monitoring_companies'
    )

    const legalreviewViewEnabled = useFeatureFlagEnabled(
        '[FE]-original-legalreview-app-view'
    );

    let sidebarPages = []
    if (legalreviewViewEnabled) {

        sidebarPages = [
            {
                path: "/home/companies",
                index: saasCompaniesEnabled,
                element: SaasCompanies,
                state: "SaaS Companies",
                icon: <BiBuildings color={"#748AF2"} size={23}/>,
            },
            {
                path: "/home/monitoring",
                index: myMonitoringEnabled,
                element: UserCompaniesMonitoringPage,
                state: "SaaS Following",
                icon: <TbHeartRateMonitor color={"#748AF2"} size={23}/>,
            },
            {
                path: '/home/contracts',
                index: true,
                element: ListContracts,
                state: 'My Documents',
                icon: <HiOutlineDocumentSearch color={'#748AF2'} size={23} />,
            },
            {
                path: "/home/projects",
                index: projectsEnabled,
                element: Projects,
                state: "Projects",
                icon: <AiTwotoneFolderOpen color={"#748AF2"} size={20}/>,
            }
        ]
    } else{
        sidebarPages = [
            {
                path: '/home/upload',
                index: false,
                element: UploadContract,
                state: 'Review Document',
                icon: <CgSoftwareUpload color={'#748AF2'} size={23} />,
            },
            {
                path: '/home/contracts',
                index: true,
                element: ListContracts,
                state: 'My Documents',
                icon: <HiOutlineDocumentSearch color={'#748AF2'} size={23} />,
            },
            {
                path: "/home/projects",
                index: projectsEnabled,
                element: Projects,
                state: "Projects",
                icon: <AiTwotoneFolderOpen color={"#748AF2"} size={20}/>,
            },
            {
                path: "/home/searchDocuments",
                index: searchDocumentsEnabled,
                element: SearchDocuments,
                state: "Search Documents",
                icon: <MdOutlineScreenSearchDesktop color={"#748AF2"} size={23}/>,
            },
            {
                path: "/home/companies",
                index: saasCompaniesEnabled,
                element: SaasCompanies,
                state: "SaaS Companies",
                icon: <BiBuildings color={"#748AF2"} size={23}/>,
            },
            {
                path: "/home/monitoring",
                index: myMonitoringEnabled,
                element: UserCompaniesMonitoringPage,
                state: "SaaS Following",
                icon: <TbHeartRateMonitor color={"#748AF2"} size={23}/>,
            },
            {
                path: "/home/Redaction",
                index: anonEnabled,
                element: Redaction,
                state: "Redaction",
                icon: <BiHide color={"#748AF2"} size={23}/>,
            },
            {
                path: "/home/trademarks",
                index: trademarksEnabled,
                element: TrademarksPage,
                state: "Trademark Classification",
                icon: <TiBusinessCard color={"#748AF2"} size={23}/>,
            }
        ]
    }
    sidebarPages.push({
        path: '/home/settings',
        index: settingsEnabled,
        element: Settings,
        state: "Settings & Payments",
        icon: <FiSettings color={"#748AF2"} size={23}/>,
    })

    return (
    <div className={`block ${props === 1 ? "" : " z-50 absolute lg:fixed max-w-[250px]"}`}>
      <TopNav />
      <div>
        {sidebarPages.filter((e) => e.element === UploadContract).map((route, index) => (
          <NavLink
            key={route.state}
            to={route.path} 
            onClick={close}
            className={({ isActive }) =>
              isActive
                ? "space-x-2 w-fit bg-[#748AF2]/[.1] p-4 pl-3 mb-2 mx-5 rounded-xl flex text-[#748AF2]"
                : `space-x-2 w-fit bg-[#748AF2]/[.1] p-4 pl-3 mb-2 mx-5 rounded-xl flex hover:bg-[#748AF2] hover:bg-opacity-20 hover:text-[#748AF2]`
            }>
            <span className="">{route.icon}</span>
            <span className="font-[500] navbar-font self-center">
              {route.state}
            </span>
          </NavLink>
        ))}
      </div>

      <div className="lg:min-w-navbar w-[250px] m-5 mb-0 bg-white rounded-xl border">
        {/* bar with color and menus */}
        <div className={`flex flex-col flex-1 sidebar-height`}>
          <ul className="space-y-4 text-sm h-full justify-between p-3">
            {/* <DropdownMenu /> */}
            {isAuthenticated ? (
              <>
                {sidebarPages
                  .filter((a) => a.index === true)
                  .map((route, index) => (
                    <li key={route.state} className="rounded-sm">
                      {route.submenu ? (
                        <>
                          <button
                            onClick={menuClick}
                            className="w-full justify-between cursor-pointer navbar-text flex items-center px-3 py-1.5 rounded-xl hover:font-black hover:bg-grey ease-in duration-100">
                            <div className="flex navbar-font space-x-3 ">
                              {route.icon}
                              <span>{route.state}</span>
                            </div>
                            <svg
                              className={`h-5 w-5 text-gray-500 chevron ${
                                dropdown ? "rotate" : ""
                              }`}
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 18L9 12 15 6"
                              />
                            </svg>
                            {/* {dropdown ?<BsChevronLeft /> :<BsChevronDown /> } */}
                          </button>
                          <CSSTransition
                            in={dropdown}
                            timeout={200}
                            classNames="menu-transition list-transition"
                            unmountOnExit>
                            {/* <CSSTransition in={dropdown} onEntered={menuClick} unmountOnExit appear > */}
                            <ul className={`space-y-1 text-sm`}>
                              {route.submenu.map((a) => {
                                // return <Submenu submenu={submenu} />
                                return <DropdownMenu submenu={a} />;
                              })}
                            </ul>
                          </CSSTransition>
                          {/* </CSSTransition> */}
                        </>
                      ) : (
                        <NavLink
                          to={route.path}
                          className="transition-[height] delay-150 navbar-text flex items-center px-3 py-1.5 space-x-3 rounded-xl hover:font-black hover:bg-grey ease-in duration-100 hover:text-[#748AF2]">
                          <span>{route.icon}</span>
                          <span className="navbar-font">{route.state}</span>
                        </NavLink>
                      )}
                    </li>
                  ))}
              </>
            ) : (
              <></>
            )}
          </ul>

          <AuthNav />
        </div>
      </div>
    </div>
  );
}
