import { React, useState } from "react";
import ClickOutside from "../../../components/Single_Components/ClickOutside";
import {
  addStyle,
  removeStyle,
  REVIEW_COMMENT_BORDER,

} from "../../../assets/GeneralFunctions";

import { BsHandThumbsDown, BsHandThumbsUp } from "react-icons/bs";
import { BsHandThumbsDownFill, BsHandThumbsUpFill } from "react-icons/bs";
import { useFeatureFlagEnabled } from 'posthog-js/react'

// import test from "./test.json"
function Comment({ comment_data,allFatherData, fatherClick }) {
  const handleMouseEnter = () => {
    let fatherTitle = document
      .getElementById("father-" + comment_data.document_element_id)
      .getAttribute("aria-comment");
    if (fatherTitle === "false") {
      document
        .getElementById("father-" + comment_data.document_element_id)
        .classList.remove("bg-[#009BB0]/[0.2]");
      document
        .getElementById("father-" + comment_data.document_element_id)
        .classList.add("bg-[#009BB0]/[0.5]");
      document.getElementById(comment_data.id).classList.add("comments_border");
      document.getElementById(comment_data.id).classList.add(REVIEW_COMMENT_BORDER);
    }
  };
  
  const handleMouseLeave = () => {
    let fatherTitle = document
      .getElementById("father-" + comment_data.document_element_id)
      .getAttribute("aria-comment");
    if (fatherTitle === "false") {
      removeStyle(document.getElementById("father-" + comment_data.document_element_id));
    }
  };

  const handleClick = () => {
    document
      .getElementById("father-" + comment_data.document_element_id)
      .setAttribute("aria-comment", "true");
    let childs = document.getElementsByClassName(
      "child-" + comment_data.document_element_id
    );
    for (let child of childs) {
      child.setAttribute("aria-comment", "true");
    }
    document
        .getElementById("father-" + comment_data.document_element_id).style.background = ""
    addStyle(document.getElementById("father-" + comment_data.document_element_id));

    //--click----
    fatherClick();
    // const selectedGroupComments = document.getElementById(comment_data.document_element_id+"-comment")
    // setCommentYPositionComment(selectedGroupComments,null,id)
    // let topComments = getSiblingsAbove(selectedGroupComments)
    // let bottomComments = getSiblingsBellow(selectedGroupComments)

    // let lastComments = [selectedGroupComments]
    // if(selectedGroupComments.offsetTop !== document.getElementById("father-"+comment_data.document_element_id).offsetTop)
    // {
    //     for (let comment of topComments) {
    //         let lastComment = lastComments.pop()
    //         setCommentYPositionUpwardsComment(comment, lastComment)
    //         lastComments.push(comment)
    //     }
    //     lastComments = [selectedGroupComments]
    //     for (let comment of bottomComments) {
    //         let lastComment = lastComments.pop()
    //         setCommentYPositionDownwardsComment(comment, lastComment)
    //         lastComments.push(comment)
    //     }
    // }
    document.getElementById(comment_data.id).style.transform = "translateX(-30px)";
  };
  const [thumbs, setThumbs] = useState(comment_data.feedback?.feedback_score)
  const requestOptions = (method, thumb) => ( {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("token"),
      "Connection": "keep-alive",
    },
    body: JSON.stringify({
      feedback_score: thumb,
      feedback_text: ""
    }),
  });

  const sendThumbs = async (thumb) => {
    const response = await fetch(
        process.env.REACT_APP_NLP_API_BASE_URL+"/comments/"+comment_data.id+"/feedback",
        requestOptions("POST",thumb),
        {}
      ).then(async (r) => {
        return await r.json();
      })
    return response;
  };

  const handleThumbsUp = () => {
      if (thumbs !== 1){
        sendThumbs(1)
        setThumbs(1)
      } 
      else {
        sendThumbs(0)
        setThumbs(0)
      };
  }


  const handleThumbsDown = () => {
      if (thumbs !== -1){
        sendThumbs(-1)
        setThumbs(-1)
      } 
      else {
        sendThumbs(0)
        setThumbs(0)
      };
  }


  return (
    <>
      <ClickOutside onClickInside={handleClick}>
        <div
          key={comment_data.id}
          id={comment_data.id}
          className={`bg-white mb-3 shadow-inner p-5 rounded-xl transition-all duration-150 Inter child-${comment_data.document_element_id}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          aria-comment="false">
          <div>
            <div className={`text-sm text-gray-500 mb-3 pr-2 flex  ${comment_data?.tag || comment_data?.created_by ?"justify-between" :"justify-end" }`}>
              
              {comment_data?.tag ? comment_data?.tag?.tag_name : <span className="font-bold">{comment_data?.created_by}</span>}
              {useFeatureFlagEnabled("[FE]-[InlineReview]-allow_feedback") ? (
                <div className="z-20 flex">
                  <button onClick={handleThumbsUp}>
                    {thumbs === 1 ? (
                      <BsHandThumbsUpFill
                        size={25}
                        className="mr-5 fill-blue"
                      />
                    ) : (
                      <BsHandThumbsUp
                        size={25}
                        className="mr-5 fill-gray-400"
                      />
                    )}
                  </button>
                  <button onClick={handleThumbsDown}>
                    {thumbs === -1 ? (
                      <BsHandThumbsDownFill size={25} className="fill-red" />
                    ) : (
                      <BsHandThumbsDown size={25} className="fill-gray-400" />
                    )}
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <p className="ml-auto font-bold mb-2 pr-2">
              {comment_data.prompt_text}
            </p>
            <p className="ml-auto text-md pr-2">{comment_data.comment_text}</p>
          </div>
        </div>
      </ClickOutside>
    </>
  );
}

export default Comment;
