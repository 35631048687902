import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Loading = () => {
  const { isLoading, error } = useAuth0();
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setTimedOut(true), 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Oops... Something went wrong</p>;
  }
  if (timedOut) {
    return <p>Taking too long... <button onClick={() => window.location.reload()}>Reload</button></p>;
  }

  return null;
};

export default Loading;