import { React, useState, useRef, useEffect, useId } from "react";
import { useLocation } from "react-router-dom";
import Comment from "./Comment";
import ClickOutside from "../../../components/Single_Components/ClickOutside";
import {
  setCommentYPosition,
  addStyle,
  removeStyle,
  getSiblingsBellow,
  getSiblingsAbove,
  setCommentYPositionDownwards,
  setCommentYPositionUpwards,
} from "../../../assets/GeneralFunctions";

function DocSegment({ props,id, text, comments}) {
  const [background, setBackground] = useState("bg-[#009BB0]/[0.2]");
  const ref = useRef(null);

  useEffect(() => {
    if(document.getElementById("comments")){
      for (let child of document.getElementById("comments").childNodes) {
        child.style.width =
          document.getElementById("comments").offsetWidth -48 + "px";
      }
    }

  }, []);

  const handleMouseEnter = () => {
    let fatherTitle = document
      .getElementById("father-" + props.id)
      .getAttribute("aria-comment");
    if (fatherTitle === "false") {
      addStyle(document.getElementById("father-" + props.id));
    }
  };
  const handleMouseLeave = () => {
    let fatherTitle = document
      .getElementById("father-" + props.id)
      .getAttribute("aria-comment");
    if (fatherTitle === "false") {
      removeStyle(document.getElementById("father-" + props.id));
    }
  };

  const handleMouseClick = (e) => {
    
    let makeid = (id ? id : props.key)
    document
      .getElementById("father-" + makeid)
      .setAttribute("aria-comment", "true");
    const selectedGroupComments = document.getElementById(
      makeid + "-comment"
    );
    setCommentYPosition(selectedGroupComments, null);
    let topComments = getSiblingsAbove(selectedGroupComments);
    let bottomComments = getSiblingsBellow(selectedGroupComments);
    let lastComments = [selectedGroupComments];
    if (
      selectedGroupComments.offsetTop !==
      document.getElementById("father-" + makeid).offsetTop
    ) {
      for (let comment of topComments) {
        let lastComment = lastComments.pop();
        setCommentYPositionUpwards(comment, lastComment);
        lastComments.push(comment);
      }
      lastComments = [selectedGroupComments];
      for (let comment of bottomComments) {
        let lastComment = lastComments.pop();
        setCommentYPositionDownwards(comment, lastComment);
        lastComments.push(comment);
      }
    }
    addStyle(document.getElementById("father-" + makeid));
  };

  const handleClickOutside = (e) => {
    document
      .getElementById("father-" + props.id)
      .setAttribute("aria-comment", "false");
    let childs = document.getElementsByClassName("child-" + props.id);
    for (let child of childs) {
      child.setAttribute("aria-comment", "false");
    }
    removeStyle(document.getElementById("father-" + props.id));
  };

  function doesDocumentElementReferToAComment(){
    const parent_document_element_id = props?.parent
    if (comments.map(x => x.key).includes(parent_document_element_id)){
      return true
    }
    return props.comments.length > 0
  }


  return (
    <>
      {text === "0" ? (
        <div className="flex justify-between">
          {doesDocumentElementReferToAComment() ? (
            <ClickOutside
              onClickInside={handleMouseClick}
              onClickOutside={handleClickOutside}>
              <props.element_optional_info
                id={`father-${props.id}`}
                key={`father-${props.id}`}
                ref={ref}
                className={`${
                  doesDocumentElementReferToAComment() ? background : ""
                } transition-all duration-150 pr-5 father-${props.id} `}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-comment="false">
                {props.element_type === "section" ? props.title: props.text}
              </props.element_optional_info>
            </ClickOutside>
          ) : (
            <>
              <props.element_optional_info
                id={`father-${props.id}`}
                key={`father-${props.id}-1`}
                className={`${
                  doesDocumentElementReferToAComment() ? background : ""
                } transition-all duration-150 pr-5`}>
                {props.element_type === "section" ? props.title: props.text}
              </props.element_optional_info>
            </>
          )}
        </div>
      ) : 
        <div
          key={props.key + "-comment"}
          id={props.key + "-comment"}
          className={`absolute z-30 transition-all duration-500 `}>
          {/* <VerticalCarousel items={props.comments} ref={ref}/> */}
          {props.value.comments
            .map((e) => {
              return (
                <div key={e.id+"--"}>
                  <Comment comment_data={e} allFatherData={props.value} fatherClick={handleMouseClick} />
                </div>
              );
            })}
        </div>
      }
    </>
  );
}

export default DocSegment;
