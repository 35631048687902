import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {PaymentUrl, SupabaseDBUser} from './interfaces';

export const getPaymentUrl = async (
    productId: string,
    returnUrl: string
): Promise<PaymentUrl> => {
const requestOptions = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
    }} ;
    let data = {
        product_id: productId,
        return_url: returnUrl
    }
  return await axios
      .post<PaymentUrl>(`${process.env.REACT_APP_NLP_API_BASE_URL}/payments/url`, data, requestOptions)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error getting payment url`, error);
        return Promise.reject(error);
      });
};
export function useGetPaymentUrlQuery(
    productId: string,
    returnUrl: string,
    options?: QueryOptionsOmit<
        UseQueryOptions<any>
    >
) {
  return useQuery(
    ['payments', 'url'],
    () => {
      return getPaymentUrl(productId, returnUrl);
    },
    options as any
  );
}
