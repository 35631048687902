import { useState, useEffect } from "react";
import Joyride from "react-joyride";
import axios from "axios";
import FetchUserInfo from "../../assets/FetchBalance";

export default function Onboarding(props) {
  const [joyrideEnabled, setJoyrideEnabled] = useState(false);
  const [Userinfo, setUserinfo] = useState(0);

  useEffect(() => {
    FetchUserInfo().then((result) => {
      setUserinfo(result);
    });
  }, []);

  const onExit = (e) => {
    if (e.action === "reset") {
      const PatchUserTutorial = async () => {
        const requestOptions = {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        };

        const fetchUserInfo = async () => {
          return await axios.patch(
            process.env.REACT_APP_DOMAIN + "/api/users/" + Userinfo.id+"/",
            { tutorial_completed: true },
            requestOptions
          );
        };
        return await fetchUserInfo();
      };
      PatchUserTutorial().then((a) => {
        localStorage.setItem("tutorial", false);
      });
    }
  };
  const isEnabled = () => {
    const CheckTutorial = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      };

      const fetchUserInfo = async () => {
        const response = await window
          .fetch(
            process.env.REACT_APP_DOMAIN + "/api/users/me/",
            requestOptions
          )
          .then(async (result) => {
            return (result = await result.json());
          });
        return response;
      };
      return await fetchUserInfo();
    };
    CheckTutorial().then((result) => {
      if (!result.tutorial_completed) {
        setJoyrideEnabled(true);
      }
    });
  };

  useEffect(() => {
    isEnabled();
  }, []);

  return joyrideEnabled ? (
    <Joyride
      steps={props.steps}
      initialStep={0}
      callback={onExit}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      styles={{
        options: {
          primaryColor: "#748AF2",
        },
      }}
    />
  ) : (
    <></>
  );
}
