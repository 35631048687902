// src/components/logout-button.js

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BsPersonCircle } from "react-icons/bs"
import FetchUserInfo from '../../assets/FetchBalance'
import { HiOutlineLogout } from "react-icons/hi"
import { NavLink } from 'react-router-dom';
const LogoutButton = () => {
  const [Userinfo, setUserinfo] = useState(0);  

  useEffect(() => {
      FetchUserInfo([Userinfo, setUserinfo]).then(result =>{
        setUserinfo(result)
      })  
  }, [])
  
  const { user, logout } = useAuth0();
  return (<>
  <hr className="solid mx-4"></hr>
  <div className='block w-full'>

    <div className=" navbar-text font-medium cursor-pointer items-center flex px-3 mx-3 py-1.5 my-2 rounded-xl hover:bg-grey"

        
          onClick={() =>{
            window.localStorage.clear()
            logout({
              returnTo: window.location.origin,
            })}
           }>
            
            
            <HiOutlineLogout size={23} className='text-[#748AF2] mr-3 transition-colors duration-200'/>Log out
    </div>
    <div className='flex items-center px-4 py-3 bg-[#748AF2]/[.1] rounded-b-xl'>
      <div className='min-w-[30px]'>
        {user.picture ? <img src={user.picture} className="rounded-full"/>: <BsPersonCircle size={23} color="#355BE1"/>}
        
      </div>
      
      <NavLink to={"/home/settings"} className='w-full navbar-font px-3.5 flex flex-col align-center items-start'>
        <span className='cursor-pointer text-[#748AF2]'>{Userinfo.email?.split("@")[0]}</span>
        <span className='text-start text-[#909090] text-[13px] text-ellipsis overflow-hidden w-[180px]  cursor-pointer'>{Userinfo.email}</span>
      </NavLink>
    </div>

    </div></>

  );
};

export default LogoutButton;