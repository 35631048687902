import { HiOutlineDocumentText } from "react-icons/hi"
import { FiChevronRight } from "react-icons/fi"
import {Link, NavLink, useLocation} from "react-router-dom"

function Breadcrumb(props) {
    const location = useLocation()

    const OldBreadcrumb = () => <>
        {location?.state?.location === "projects" ? <NavLink to={`/home/projects`} className="">
            <label className="cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150  mr-3 text-black Inter font-bold">Projects</label>
        </NavLink>:<NavLink to={`/home/contracts`} className="">
            <label className="cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150  mr-3 text-black Inter font-bold">My Documents</label>
        </NavLink> }


        <FiChevronRight size={20} className="mr-3"/>

        {location?.state?.location === "projects" ? <><NavLink to={`/home/projects/${location?.state?.project_id}/details`} className="">
            <label className="cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150  mr-3 text-black Inter font-bold">{location?.state?.project_name}</label>
        </NavLink><FiChevronRight size={20} className="mr-3"/></>:<></>}

        <Link to={`/contract/${props?.id}/summary`}
              state={{ location: location, project_id: location?.state?.project_id, project_name: location?.state?.project_id }}
              className=" bg-white">
            <label className="cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150 mr-3 text-black Inter font-bold">{props.docname}</label>
        </Link>
        {props.page !== "Summary" ? <>
                <FiChevronRight size={20} className="mr-3"/>
                <label className="text-blue Inter font-bold">{props.page}</label></>:
            <></>}
    </>

    return (
        <div className="bg-white border rounded-xl p-5 mb-5 shadow-sm relative z-20">
            <div className="flex items-center">
                <HiOutlineDocumentText size={25} className="mr-3 text-center" />
                {
                    props?.pathParts
                        ?
                        props?.pathParts?.map((path, idx)=>{
                            return <><a href={path.url} className="">
                                <label className="cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150  mr-3 text-black Inter font-bold">{path.name}</label>
                            </a>{idx === props?.pathParts.length-1 ? "" : <FiChevronRight size={20} className="mr-3"/>}</>
                        })
                        :   <OldBreadcrumb/>
                }

            </div>

        </div>);
  }
  
  export default Breadcrumb;