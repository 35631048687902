import { React } from "react";
import SummaryComponent from "./SummaryComponent";
import Checklist from "./Checklist";
import { useOutletContext } from "react-router-dom";
import UserQuestions from "./UserQuestions";

// import test from "./test.json"

function Summary() {
  const {documentFinal, playbookID, id} = useOutletContext();
  return (
    <>
      <SummaryComponent props={documentFinal} playbook_id={playbookID} />
      <Checklist props={documentFinal} playbook_id={playbookID} id={id}/>
      <UserQuestions props={documentFinal} id={id} />
    </>
  );
}

export default Summary;
