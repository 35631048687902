import React, { useEffect, useState } from 'react'
import useDrivePicker from 'react-google-drive-picker/dist';
import { PickerCallback, PickerConfiguration, authResult } from 'react-google-drive-picker/dist/typeDefs';
import axios from 'axios';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import { CgSoftwareUpload } from 'react-icons/cg';
// import googledrive from '../../../assets/googledrive.svg';
import GoogleDrive from "../../../assets/images/googledrive.png"
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useImmer } from 'use-immer';
import TableStatus from './TableStatus';
import GooglePicker from 'react-google-picker';
import Loading from '../../../components/Single_Components/Loading';
// import { GoogleApis as google } from 'googleapis';
// import { GoogleAuth } from 'google-auth-library';


export default function DriveUpload({runJob, totalDocs, setTotalDocs, loading}) {
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const [dragActive, setDragActive] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files[0]) {
      for(let file of e.dataTransfer.files){
        setTotalDocs(totalDocs => [...totalDocs, {file: file, status: "new"}])
        runJob(file.name,file)
      }
    }
    setDragActive(false);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files) {
      for(let file of e.target.files){
        setTotalDocs(totalDocs => [...totalDocs, {file: file, status: "new"}])
        runJob(file.name,file)
      }
    }
  };
  const [token, setToken] = useImmer("");

  const [openPicker, data] = useDrivePicker();  

  const handleOpenPicker = () => {
    let config = {
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID ?? "",
      developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY ?? "",
      viewId: "DOCS",
      token: (data?.access_token ?? token),
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: ['https://www.googleapis.com/auth/drive.readonly','https://www.googleapis.com/auth/drive.file'],
      callbackFunction: handleGoogleDrive,
    };
    return openPicker(config)
  }
  const [fileDrive, setFileDrive] = useState([]);

  useEffect(() => {
    if(fileDrive?.length>0 && data){
      setToken(data?.access_token)
      const requestOptions = {
        headers: {
          "Content-Type": 'application/pdf', //application/msword
          "Authorization": 'Bearer ' + data?.access_token,
        },
        responseType: 'blob',
      };
      for (let file of fileDrive){
        axios.get(`https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,requestOptions).then(result=>{
          let content = result.data
          content["name"] = file?.name
          setTotalDocs(totalDocs=>[...totalDocs, {file: content, status: "new"}])
          runJob(file.name,content)
        })
        } 
    setFileDrive([])
  }

  }, [data, fileDrive]);

  function handleGoogleDrive(info) {
    if (info.action === 'cancel') 
      return 
    
    if (info.docs?.length > 0) setFileDrive([...info.docs])
  }


  return (
        <>            
        <form
            id="form-file-upload"
            onDragEnter={handleDrag}
            onDrop={handleDrop}
            onDragOver={handleDrag}
            onChange={handleFileChange}
            onSubmit={(e) => e.preventDefault()}>
              <span className="block Inter text-sm mb-5">
                Upload document to hide/redact private information from documents.
              </span>
            {/* <label htmlFor="file-upload" className="absolute max-w-[500px] font-bold"> */}
            <div className="ml-10 my-6 text-end max-w-full border-2 border-dashed border-blue  cursor-pointer rounded-2xl bg-[#3056D3]/[.1] shadow-xl">
              
              
              <label htmlFor="file-upload" className={`step2 block cursor-pointer font-bold py-20 pt-12 pb-12`}>
                {/* <Loading style={"h-auto"}/> */}
                {loading?<Loading style={"h-[150px]"}/>:<div><div className="icon-upload color ">
                  <CgSoftwareUpload className="text-blue mb-8 mx-auto " size={40} />
                </div>
                <div className="flex text-sm text-gray-600">
                <label
                    htmlFor="file-upload"
                    className="m-auto navbar-font font-normal cursor-pointer text-center">
                    <div className=' mb-10'>
                      <span>Drag & drop or{" "}</span>
                      <span className="underline underline-offset-2">Select file</span>
                    </div>
                        <button onClick={() => handleOpenPicker()}>
                          <img src={GoogleDrive} alt=" React Logo" className="max-w-[40px]"/>
                        </button>
                  </label>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only" 
                    multiple={true}
                    onChange={handleFileChange}
                  />
                </div></div>}

                
                


              </label>
              
              {/* { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> } */}
            </div>{" "}
          </form>


      </> 
  )

}