import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import '../../../styles/tiptap/index.css';

import { useGetDocumentByIDQuery } from '../../../services/v1/getDocumentById';
import { useGetDocumentCommentsQuery } from '../../../services/v1/getDocumentComments';
import Heading from '../../../components/v2/features/contract/review/Heading/Heading';
import Sidebar from '../../../components/v2/features/contract/review/Sidebar/Sidebar';
import { Editor } from '../../../components/v2/editor';
import { isNotAnswered } from '../../../helpers/contract';
import EditorSkeleton from '../../../components/v2/editor/Editor.skeleton';

interface IReviewPageProps {}

const ReviewPage: React.FC<IReviewPageProps> = ({}) => {
  const { id: contractID } = useParams();
  const [params, setSearchParams] = useSearchParams();

  const { data: documentData, isLoading: isLoadingDocument } =
    useGetDocumentByIDQuery(contractID || '', { refetchOnWindowFocus: false });
  const { data: commentsData, isLoading: loadingCommentsData } =
    useGetDocumentCommentsQuery(contractID || '', {
      refetchOnWindowFocus: false,
    });

  // const {data: qas} = useGetDocumentQAsQuery(contractID || '')
  // const {data: refs} = useGetDocumentCommentsReferencesQuery(contractID || '')

  const nodeID = React.useMemo(() => {
    return params.get('id');
  }, [params]);

  const commentsToDisplay = React.useMemo(() => {
    if (documentData && !isLoadingDocument) {
      const generalQuestions =
        documentData?.result[0].result.summary.prompting_general_answers
          .map((a) => a.answer_references)
          .flat(2) || [];
      const userQuestions =
        documentData?.result[0].result.summary.user_qas
          .map((a) => a.answer_references)
          .flat(2) || [];

      const yesNoRisks =
        documentData?.result[0].result.summary.yes_no_risks
          .map((a) => a.answer_references)
          .flat(2) || [];

      return [...generalQuestions, ...userQuestions, ...yesNoRisks].filter(
        (answer) => !isNotAnswered(answer.answer)
      );
    }
    return [];
  }, [documentData, isLoadingDocument, nodeID]);

  const restOfTheCommentsToDisplay = React.useMemo(() => {
    if (commentsToDisplay.length > 0) {
      return commentsToDisplay.filter(
        (comm) => comm.document_element_id !== nodeID
      );
    }
    return [];
  }, [commentsToDisplay, nodeID]);

  return (
    <div className="bg-[#F9FAFE] flex justify-between h-screen">
      <div className="px-20 w-[80%] pt-10 flex flex-col">
        <Heading
          documentData={{
            documentType: documentData?.result[0].document_type || '',
            date: documentData?.result[0].updated_at || '',
            companyName: documentData?.result[0].company_name || 'N/A',
            lang:
              documentData?.result[0].result.general_information.language || '',
          }}
          title={documentData?.result[0].document_name || ''}
          breadcrumbs={[
            { label: 'Documents', path: '/home/contracts' },
            {
              label: documentData?.result[0].document_name || '',
              path: `/contract/${documentData?.id}/inline_review`,
            },
          ]}
        />
        {loadingCommentsData || isLoadingDocument ? (
          <EditorSkeleton />
        ) : (
          <Editor
            documentData={documentData}
            nodeID={nodeID || ''}
            setSearchParams={setSearchParams}
            commentsToDisplay={commentsToDisplay}
            restOfTheCommentsToDisplay={restOfTheCommentsToDisplay}
          />
        )}
      </div>
      <Sidebar />
    </div>
  );
};

export default ReviewPage;
