
import React from 'react';
import Breadcrumbs, { Breadcrumb } from '../Breadcrumbs/Breadcrumbs';

import { GiPlainCircle } from 'react-icons/gi';
import HeadingSkeleton from './Heading.skeleton';

interface IHeadingProps {
  breadcrumbs: Breadcrumb[];
  title: string;
  documentData: DocumentData;
}

type DocumentData = {
  companyName: string;
  documentType: string;
  lang: string;
  date: string;
};

const Heading: React.FC<IHeadingProps> = ({
  breadcrumbs,
  title,
  documentData,
}) => {
  if (!documentData || !title || !breadcrumbs) {
    // if(true){
    return <HeadingSkeleton />;
  }
  return (
    <div className='flex flex-col gap-2'>
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="font-extrabold text-2xl mt-6">{title}</h1>
      <div className="flex items-center gap-2 text-[#8D92A7] mb-12">
        <span>{documentData.companyName}</span>
        <GiPlainCircle size={6} />
        <span>{documentData.documentType}</span>
        <GiPlainCircle size={6} />
        <span>{documentData.lang}</span>
        <GiPlainCircle size={6} />
        <span>{new Date(documentData.date).toLocaleDateString()}</span>
      </div>
    </div>
  );
};

export default Heading;
