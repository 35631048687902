import { AiOutlineCloudUpload, AiOutlinePlusCircle } from "react-icons/ai";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import { useOutletContext } from "react-router-dom";
import FetchDocuments from "../../../assets/FetchDocuments";
import { CgSoftwareUpload } from "react-icons/cg";
import { HiPencil } from "react-icons/hi";
import Questions from "../../../components/Single_Components/Questions";
import Disclaimer from "../../../components/Single_Components/Disclaimer";
import { HiOutlineDocumentText } from "react-icons/hi";
import Container from "../../../components/Single_Components/Container";
import FetchUserInfo from "../../../assets/FetchBalance";
import Notification from "../../../components/Single_Components/Notification";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { notifications, createdJob } from "../../../components/layout/MainLayout";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import Modal from "../../../components/Single_Components/ModalUpload";
import { AiOutlineLink } from "react-icons/ai";
import { MdOutlineError } from "react-icons/md";
import { posthog } from "posthog-js";
import Onboarding from "../../../components/Single_Components/Onboarding";
import Dropdown from "../../../components/Single_Components/Dropdown";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import DriveUpload from "./DriveUpload";
import { FaChevronLeft } from "react-icons/fa";
import { useImmer } from "use-immer";
import TableStatus from "./TableStatus";

export default function Upload({ setTimeOfJob, name}) {
  const [project, setProject] = useState();
  const [questions, setQuestions] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const{id} = useParams()
  const navigate = useNavigate();
  const location = useLocation()
  let filterList = ["Select Option", "M&A", ] //[ "m_and_a", "saas_db", "in_house_legal", "finance_team" ] will add this later
  const [option, setDropDownChild] = useState("Select Option")
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [url, setUrl] = useState("");


  const [notification_component, setNotification_component] = useState(null);

  const handleClick = () => {
    navigate("/home/projects/"+id+"/details")
  };


  document.addEventListener("click", function (e) {
    if (error && e.target.id !== "submit-url") {
      document.getElementById("company-website").classList.remove("error");
      document.getElementById("company-website").style.borderColor = "inherit";
      setError("");
    }
  });

  const steps = [
    {
      target: ".step1",
      content:
        "Upload the document you wish to review or provide an URL for a website to review",
    },
    {
      target: ".step2",
      content: "You can upload or select a file to review",
    },
    {
      target: ".step3",
      title: "User questions",
      content:
        "You can ask one or more questions you would like to know about your document",
      placement: "top",
    },
    {
      target: ".step4",
      content: "Submit your document for review (this may take some minutes)",
      placement: "top",
    },
  ];
  const handleUrl = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    setUrl(pastedText);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTotalDocs(totalDocs => [...totalDocs, {file: {name: url}, status: "new"}])
    runJob(url, null)
    setUrl("");
  };

  const {user} = useAuth0();
  const [totalDocs, setTotalDocs] = useImmer([]);
  const [playbook_id, setPlaybook_id] = useState();



  useEffect(() => {
    
  function fetchPlaybook(){
    const requestOptions = {
      headers: { 'Content-Type': 'application/json',
      Authorization: localStorage.getItem("token"),
    }} ;
    axios.get(process.env.REACT_APP_DOMAIN + "/api/v1/projects/"+id, requestOptions)
    .then(result => {
      setPlaybook_id(result.data.playbook_id)
    })
  }
    fetchPlaybook()
  }, [])

  async function runJob(filename, file_binary) {

    const formData = new FormData()
    formData.append('source',  user.email)
    formData.append('job_type', "review")
    if(file_binary){
      formData.append('original_file', file_binary, filename)
    } else {
      formData.append("url", filename);
    }
    
    formData.append('project',id)
    formData.append('playbook_id', playbook_id)
    const requestOptions = {
      headers: { 'Content-Type': 'multipart/form-data',
      Authorization: localStorage.getItem("token"),
    }} ;
    // Perform the upload using axios or fetch
    try {
      const res = axios.post(process.env.REACT_APP_DOMAIN + "/api/v1/jobs/" , formData, requestOptions).then(() => {
        setTotalDocs(job => {
          const newJobStatus = job.find(a =>
            a.file.name === filename
          );
          newJobStatus.status = "created";
        });
      })
    } catch (error) {

    }

  }
  
  let tabsData = [
    {
      label: "Upload document",
      value: "file",
      icon: <CgSoftwareUpload size={20} />,
      content: (<DriveUpload runJob={runJob} totalDocs={totalDocs} setTotalDocs={setTotalDocs}/>),

    },{
      label: "Insert website URL",
      value: "link",
      icon: <AiOutlineLink size={20} />,
      content: (
        <div className="mb-10">
          <label className="block text-md font-[500] Inter mb-4">
            Insert the URL corresponding to the document
          </label>
          <div className="relative mt-1 rounded-md w-[737px] step2 flex w-fit">
            <input
              type="text"
              placeholder="&#xf0c1;  https://www.example.com/"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onPaste={handleUrl}
              name="company-website"
              id="company-website"
              style={{ fontFamily: "Inter, FontAwesome", fontSize: "16px" }}
              className="w-[637px] border p-4 text-lg rounded focus:outline-none Inter"
            />
            <button
            className="ml-8 border-[#748AF2] text-l bg-transparent text-[#748AF2] shadow border-2 rounded-2xl p-3 pr-10 pl-5 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer"
            onClick={handleSubmit}>
            <AiOutlinePlusCircle className="mr-3 min-w-[20px]" size={20} />
            <span className="min-w-fit">Add Document</span>
          </button>
          </div>
          {error && (
            <p className="mt-2 flex text-red-primary items-center ml-4 text-xs Inter">
              <MdOutlineError className="mr-2" size={17} />
              {error}
            </p>
          )}

          

        </div>
      ),
    }];
    
  return (
    <Container>
      {useFeatureFlagEnabled("[FE]-[Global]-show_onboarding") && <Onboarding steps={steps} />}

      <div className="mt-5 m-10 mb-8">
      <NavLink to={`/home/projects/${id}/details`}>
            <button
              className=" mb-10 bg-white py-1.5 flex items-center text-[#748AF2] font-[500] "
              type="submit">
              <FaChevronLeft size={20}/><span className="ml-5"> {location?.state?.project_name}</span>
            </button>
          </NavLink>
        <div className="flex justify-between items-center">
          <div className="mb-10 font-black text-3xl Inter">Upload documents</div>
          {notification_component}
        </div>
        <div
          className="flex border rounded max-w-fit shadow-md step1 "
          data-intro="Hello step one!">
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`text-[#637381] navbar-font rounded flex py-2 p-8 m-1 transition-colors duration-300 items-center  ${
                  idx === activeTabIndex
                    ? "bg-[#F4F7FF]"
                    : " hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}>
                <span className="mr-2 ">{tab.icon}</span>
                <span>{tab.label}</span>
              </button>
            );
          })}
        </div>
        <span className="absolute bottom-0 block h-1 bg-teal-500 transition-all duration-300" />
        <div className="my-10">
          <div>{tabsData[activeTabIndex].content}</div>
          <TableStatus totalDocs={totalDocs} />
        </div>

        <button
          type="submit"
          id="submit-url"
          className="text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer"
          onClick={handleClick}>
          <HiOutlineDocumentText className="mr-2" size={18} />
          Continue
        </button>
        <div>

        </div>
        </div>
    </Container>
  );
}


