import { BsQuestionCircle } from "react-icons/bs"

export function Replace_idk_answer(text) {

    if (text && text.includes("I do not know the answer.")) return (<div className="flex items-center"><BsQuestionCircle size={20} className="min-w-[20px]"/><span className="ml-3">{"We could not find the answer."}</span></div>)
    else return (<>{text}</>)
}

export const subtract = (a, b) => a - b;

export const Notification = []


//test signed commit


export const totalChilds = () => {
    let total = 0
    for (let item of document.getElementById("comments").children){
        if (item.children.length > 0 ) total += 1
    }
    return total
}

export const selectedChild = (selectedId) => {
    let total = 0
    let element = selectedId+"-comment"
    for (let item of document.getElementById("comments").children){
        
        if (item.children.length > 0 ){
            
            if(item.id === element )return total
            total+=1 
        }
    }
}

export const getSiblingsAbove = (element) => {
    const siblings = [];
    let sibling = element.previousSibling;
    while (sibling) {
        if (sibling.childNodes.length > 0) {
            siblings.push(sibling);
        }
        sibling = sibling.previousSibling;
    }
    return siblings;
};

export const getSiblingsBellow = (element) => {
    const siblings = [];

    let sibling = element.nextSibling;
    while (sibling) {
        if (sibling.childNodes.length > 0) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
};

export function setCommentYPosition(comment, lastComment){
    if(lastComment && (parseInt(lastComment?.style?.top.replace("px","")) + lastComment?.offsetHeight) > parseInt(comment.style.top.replace("px","")) ){
        comment.style.top = (parseInt(lastComment.style.top.replace("px",""))+lastComment.offsetHeight+12)+"px"
    }else{
        if(document.getElementById("father-"+comment.id.replace("-comment","")))
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")).offsetTop)+"px"
        else
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")+"-child2").offsetTop)+"px"
    }
}

export function setCommentYPositionUpwards(comment, lastComment){//last comment ja foi movido, comment vai se mover
    let paiDoAnteriorTop = 0
    if(document.getElementById("father-"+comment.id.replace("-comment","")))
        paiDoAnteriorTop = document.getElementById("father-"+comment.id.replace("-comment","")).offsetTop
    else
        paiDoAnteriorTop = document.getElementById("father-"+comment.id.replace("-comment","")+"-child2").offsetTop
    const lastCommentTop = parseInt(lastComment?.style?.top.replace("px",""))
    const commentBottom = parseInt(comment.style.top.replace("px",""))+comment.offsetHeight

    if(lastComment && lastCommentTop < commentBottom){
        comment.style.top = (lastCommentTop-comment.offsetHeight-12)+"px"
    }
    else if(lastComment && paiDoAnteriorTop+comment.offsetHeight > lastCommentTop){
        comment.style.top = (lastCommentTop-comment.offsetHeight-12)+"px"
    }
    else{
        if(document.getElementById("father-"+comment.id.replace("-comment","")))
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")).offsetTop)+"px"
        else
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")+"-child2").offsetTop)+"px"
    }
}



export function setCommentYPositionDownwards(comment, lastComment){ //last comment ja foi movido, comment vai se mover

    let paiDoProximo = 0
    if(document.getElementById("father-"+comment.id.replace("-comment","")))
        paiDoProximo = document.getElementById("father-"+comment.id.replace("-comment","")).offsetTop
    else
        paiDoProximo = document.getElementById("father-"+comment.id.replace("-comment","")+"-child2").offsetTop

    const lastCommentTop = parseInt(lastComment?.style?.top.replace("px",""))
    const commentTop = parseInt(comment.style.top.replace("px",""))

    if(lastComment && (lastCommentTop + lastComment.offsetHeight) > commentTop){
        comment.style.top = (parseInt(lastComment.style.top.replace("px",""))+lastComment.offsetHeight+12)+"px"
    }
    else if(lastComment && paiDoProximo< lastCommentTop+lastComment.offsetHeight){
        comment.style.top = (parseInt(lastComment.style.top.replace("px",""))+lastComment.offsetHeight+12)+"px"
    }
    else{
        if(document.getElementById("father-"+comment.id.replace("-comment","")))
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")).offsetTop)+"px"
        else
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")+"-child2").offsetTop)+"px"
    }
        
}

export let ADDED_BACKGROUND_COLOR = "bg-[#B3E7DB]/[0.62]"
export let EDITED_BACKGROUND_COLOR = "bg-[#F9EAC2]"
export let ELIMINATED_BACKGROUND_COLOR = "bg-[#E7B3CC]/[0.4]"

export let ADDED_BACKGROUND_COLOR_SELECTED = "bg-[#B3E7DB]"
export let EDITED_BACKGROUND_COLOR_SELECTED = "bg-[#F9C107]/[.62]"
export let ELIMINATED_BACKGROUND_COLOR_SELECTED = "bg-[#E7B3CC]/[0.8]"

export let ADDED_BORDER = "border-l-[#B3E7DB]"
export let EDITED_BORDER = "border-l-[#F9C107]/[.62]"
export let ELIMINATED_BORDER = "border-l-[#E7B3CC]/[0.8]"

export let REVIEW_COMMENT_BORDER = "border-l-[#009bb0]"


export function addStyle(element, change_type, real_change_type){
    let id = element.id.replace("father-","")

    if(!change_type){
        for (let child of document.getElementsByClassName("child-"+id)){
            child.classList.add("comments_border")
            child.classList.add(REVIEW_COMMENT_BORDER)
        }
        element.classList.remove("bg-[#009BB0]/[0.2]")
        element.classList.add("bg-[#009BB0]/[0.5]")
    } else {
        for (let child of document.getElementsByClassName("child-"+id)){
            child.classList.add("comments_border")
        }
        if(change_type === "edited"){
            element.classList.remove(EDITED_BACKGROUND_COLOR)
            element.classList.add(EDITED_BACKGROUND_COLOR_SELECTED)
        }else if(change_type === "added" || real_change_type === "added") {
            element.classList.remove(ADDED_BACKGROUND_COLOR)
            element.classList.add(ADDED_BACKGROUND_COLOR_SELECTED)
        }else if(change_type === "eliminated" || real_change_type === "eliminated") {
            element.classList.remove(ELIMINATED_BACKGROUND_COLOR)
            element.classList.add(ELIMINATED_BACKGROUND_COLOR_SELECTED)
        }
    }
}

export function removeStyle(element, change_type, real_change_type){
    if(!change_type){
        let id = element.id.replace("father-","")
        for (let child of document.getElementsByClassName("child-"+id)){
            child.classList.remove("comments_border")
            child.style.transform="translateX(0px)"
        }
        element.classList.add("bg-[#009BB0]/[0.2]")
        element.classList.remove("bg-[#009BB0]/[0.5]")
    } else {
        let id = element.id
        if(element?.id?.includes("father-") && element?.id?.includes("-child"))
            id =  element?.id?.split("father-")[1].split("-child")[0]
    
        for (let child of document.getElementsByClassName("child-"+id)){
            child.classList.remove("comments_border")
        }
        if(change_type === "edited"){
            element.classList.add(EDITED_BACKGROUND_COLOR)
            element.classList.remove(EDITED_BACKGROUND_COLOR_SELECTED)
        }else if(change_type === "added" || real_change_type === "added") {
            element.classList.add(ADDED_BACKGROUND_COLOR)
            element.classList.remove(ADDED_BACKGROUND_COLOR_SELECTED)
        }else if(change_type === "eliminated" || real_change_type === "eliminated") {
            element.classList.add(ELIMINATED_BACKGROUND_COLOR)
            element.classList.remove(ELIMINATED_BACKGROUND_COLOR_SELECTED)
        }
    }

}


export function setCommentYPositionComment(comment, lastComment, id){
    const fatherId = comment.id.replace("-comment","")
    if(lastComment && (parseInt(lastComment.style.top.replace("px","")) + lastComment.offsetHeight) > parseInt(comment.style.top.replace("px","")) ){
        comment.style.top = (parseInt(lastComment.style.top.replace("px",""))+lastComment.offsetHeight+12)+"px"
    }else{
        if(document.getElementById(id).offsetTop === 0 ) 
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")).offsetTop)+"px"
        else if(document.getElementById(id).offsetTop !== 0 && (comment.offsetTop+document.getElementById(id).offsetTop)!==document.getElementById("father-"+fatherId).offsetTop) {
            // const commentOffset = 2
            comment.style.top = (document.getElementById("father-"+comment.id.replace("-comment","")).offsetTop - document.getElementById(id).offsetTop)+"px"
        }
            
    }
        
}


export function setCommentYPositionUpwardsComment(comment, lastComment){//last comment ja foi movido, comment vai se mover
    const paiDoAnteriorTop = document.getElementById(comment.id).offsetTop
    const lastCommentTop = parseInt(lastComment?.style?.top.replace("px",""))
    const commentBottom = parseInt(comment.style.top.replace("px",""))+comment.offsetHeight

    if(lastComment && lastCommentTop < commentBottom){
        comment.style.top = (lastCommentTop-comment.offsetHeight-12)+"px"
    }
    else if(lastComment && paiDoAnteriorTop+comment.offsetHeight > lastCommentTop){
        comment.style.top = (lastCommentTop-comment.offsetHeight-12)+"px"
    }
    else{
        comment.style.top = (document.getElementById(comment.id).offsetTop)+"px"
    }
}



export function setCommentYPositionDownwardsComment(comment, lastComment){ //last comment ja foi movido, comment vai se mover
    const paiDoProximo = document.getElementById(comment.id).offsetTop
    const lastCommentTop = parseInt(lastComment?.style?.top.replace("px",""))
    const commentTop = parseInt(comment.style.top.replace("px",""))

    if(lastComment && (lastCommentTop + lastComment.offsetHeight) > commentTop){
        comment.style.top = (parseInt(lastComment.style.top.replace("px",""))+lastComment.offsetHeight+12)+"px"
    }
    else if(lastComment && paiDoProximo< lastCommentTop+lastComment.offsetHeight){
        comment.style.top = (parseInt(lastComment.style.top.replace("px",""))+lastComment.offsetHeight+12)+"px"
    }
    else{
        comment.style.top = (document.getElementById(comment.id).offsetTop)+"px"
    }
        
}















