export default function SimpleFormInputBox({idText, labelText, setText, placeholder}) {
    return (
        <div>
            <label htmlFor={idText}
                   className="block text-sm font-medium leading-6 text-gray-900">{labelText}</label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <input type="text" name={idText} id={idText}
                       className="block w-full bg-[#F9FBFF] rounded-md border-0 py-1.5 pl-5 pr-20 text-gray-900 placeholder-[#6B7280] focus:ring-1 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                       placeholder={placeholder} onChange={e => setText(e.target.value)}/>
            </div>
        </div>
    )
}