import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import Pagination from "./Pagination";


export default function ListJobs({jobs, location, project_id, project_name, answered_questions}) {
    const [currentPage, setCurrentPage] = useState(1);
    const show_admins_job_details = useFeatureFlagEnabled("[FE]-[ListContracts]-show_admins_job_details")
    const v2ReviewPage = useFeatureFlagEnabled("[FE]-[Contract]-new_review_page")
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const forward = (pageNumber) => setCurrentPage(pageNumber + 1);
    const backward = (pageNumber) => setCurrentPage(pageNumber - 1);
    const [currentPosts, setCurrentPosts] = useState(jobs.slice(indexOfFirstPost, indexOfLastPost));


    useEffect(() => {
        setCurrentPosts(jobs.slice(indexOfFirstPost, indexOfLastPost))
    }, [jobs, indexOfFirstPost, indexOfLastPost]);
    



    
    return (          <div><div className="-mx-4 mt-8 rounded-xl sm:-mx-6 md:mx-0 min-h-[630px]">
    <table className="min-w-full divide-y divide-gray-300 table-fixed">
      <thead className="Inter text-left text-xs font-bold text-[#6B7280] bg-[#F9FAFB] ">
        <tr>
          { show_admins_job_details ? (
            <th
              scope="col"
              className="text-[#6B7280] w-1 py-3.5 pl-3 pr-3 text-left text-sm font-thin text-gray-900 sm:pl-5">
              User
            </th>
          ) : (
            <></>
          )}
          {show_admins_job_details ? (
            <th
              scope="col"
              className="text-[#6B7280] w-1 py-3.5 pl-3 pr-3 text-left text-sm font-thin text-gray-900 sm:pl-5">
              ID
            </th>
          ) : (
            <></>
          )}
          <th scope="col" className="px-3 py-3.5">
            Document Name
          </th>
          <th
            scope="col"
            className=" px-3 py-3.5 w-[120px] max-w-[120px]">
            Date
          </th>
          {answered_questions ?
            <th scope="col" className="px-3 py-3.5">
              Answered Questions
            </th> : <></>}
          <th scope="col" className="px-3 py-3.5">
            Company
          </th>
          <th scope="col" className="px-3 py-3.5 w-[120px]">
            Document Type
          </th>
          <th scope="col" className="px-3 py-3.5 w-[120px]">
            Analysis Type
          </th>
          <th
            scope="col"
            className="step2 px-3 py-3.5  w-[150px] max-w-[150px]">
            Status
          </th>
          {/* <th scope="col" className="px-3 py-3.5 w-[120px] max-w-[120px]">
                    Review Type
                </th> */}
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {currentPosts.map((job, value) => (
          <tr className="even:bg-[#F9FAFB]" key={job.id}>
            {show_admins_job_details ? (
              <td className="Inter w-1 py-3.5 pl-3 pr-3 text-sm font-medium text-black-900 sm:w-auto sm:max-w-none sm:pl-5">
                {job.source}
              </td>
            ) : (
              <></>
            )}
            {show_admins_job_details ? (
              <td className="Inter w-1 py-3.5 pl-3 pr-3 text-sm font-medium text-black-900 sm:w-auto sm:max-w-none sm:pl-5">
                {job.id}
              </td>
            ) : (
              <></>
            )}
            <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
              {job?.result[0]?.document_name ? (
                job?.result[0]?.document_name
              ) : (
                <>-</>
              )}
            </td>
            <td className="Inter px-3 py-4 text-sm text-black-600 sm:table-cell whitespace-nowrap overflow-x-hidden">
              {show_admins_job_details ? (
                job.created_at.split(".")[0].replace("T", " ")
              ) : job.created_at.split("T")[0] ? (
                job.created_at.split("T")[0]
              ) : (
                <>-</>
              )}
              {}
            </td>
            {answered_questions ? answered_questions[value]:<></>}

            <td className="Inter text-left px-3 py-4 text-sm whitespace-nowrap capitalize overflow-x-hidden overflow-ellipsis w-[50px] max-w-[180px]">
              {job?.result[0]?.company_name ? (
                job?.result[0]?.company_name
              ) : (
                <>-</>
              )}
            </td>
            <td className="Inter text-left px-3 py-4 text-sm whitespace-nowrap overflow-x-hidden overflow-ellipsis w-[50px] max-w-[180px] ">
              {job?.result[0]?.document_type ? (
                job?.result[0]?.document_type
              ) : (
                <>-</>
              )}
            </td>
            <td className="Inter text-left px-3 py-4 text-sm whitespace-nowrap overflow-x-hidden overflow-ellipsis w-[50px] max-w-[180px] capitalize">
              {job?.job_type ? (
                job?.job_type
              ) : (
                <>-</>
              )}
            </td>
            <td className="Inter text-center px-3">
              <div
                className={`p-2 px-3 text-xs capitalize max-w-[95px] font-light  rounded-full ${
                  job?.status[0]?.status === "completed"
                    ? "text-green-600 bg-opacity-25 bg-green-500"
                    : job?.status[0]?.status === "processing"
                    ? "text-blue bg-baby-blue"
                    : job?.status[0]?.status === "new"
                    ? "text-gray-600 bg-opacity-25 bg-gray-500"
                    : "bg-red-primary text-red-primary bg-opacity-25 "
                } 
                `}>
                <span className="">
                  {job?.status[0]?.status === "new"
                    ? "Pending"
                    : show_admins_job_details &&
                      job?.status[0]?.status === "rejected"
                    ? job?.status[0]?.status
                    : job?.status[0]?.status === "rejected"
                    ? "Error"
                    : job?.status[0]?.status}
                </span>
              </div>
            </td>
            {/* <td className="Inter py-4 pl-3 pr-4 text-left capitalize text-sm sm:pr-6">{job.job_type}</td> */}
            <td className="step3 Inter w-[120px] min-w-[120px] text-center ">
              {job.job_type.toLowerCase() !== "compare" && job?.status[0]?.status === "completed" ? (
                <NavLink
                  to={v2ReviewPage ?{pathname:`/v2/contract/${job.id}/inline_review`} :{pathname:`/contract/${job.id}/inline_review`}}
                  state={{ location: location, project_id: project_id, project_name: project_name }} 
                  className="text-indigo-600 text-md hover:text-indigo-900">
                  <span className="text-blue text-xs Inter hover:underline hover:underline-offset-1">
                    Inline review
                  </span>
                </NavLink>
              ) : job.job_type.toLowerCase() === "compare" && job?.status[0]?.status === "completed"? (
                <NavLink
                  to={`/comparison/${job.id}/inline_review`}
                  state={{ location: location, project_id: project_id, project_name: project_name }} 
                  className="text-indigo-600 text-md hover:text-indigo-900">
                  <span className="text-blue text-xs Inter hover:underline hover:underline-offset-1">
                    Inline review
                  </span>
                </NavLink>
              ) : (
                <a className="cursor-not-allowed">
                  <span className="opacity-25 text-blue text-xs Inter ">
                    Inline review
                  </span>
                </a>
              )}
            </td>
            <td className="step4 Inter w-[120px] min-w-[120px] ">
              {job.job_type.toLowerCase() !== "compare" && job?.status[0]?.status === "completed" ? (
                <NavLink
                  key={job.id}
                  to={{pathname:`/contract/${job.id}/summary`}}
                  state={{ location: location, project_id: project_id, project_name: project_name }} 
                  className="hover:text-indigo-900">
                  <span className=" py-1 px-3 rounded-full bg-baby-blue text-right text-blue Inter text-xs hover:bg-blue hover:bg-opacity-50 fade-in duration-300 focus:outline-none">
                    View summary
                  </span>
                </NavLink>
              ) : job.job_type.toLowerCase() === "compare" && job?.status[0]?.status === "completed"? (
                <NavLink
                  key={job.id}
                  to={`/comparison/${job.id}/summary`}
                  state={{ location: location, project_id: project_id, project_name: project_name}} 
                  className="hover:text-indigo-900">
                  <span className=" py-1 px-3 rounded-full bg-baby-blue text-right text-blue Inter text-xs hover:bg-blue hover:bg-opacity-50 fade-in duration-300 focus:outline-none">
                    View summary
                  </span>
                </NavLink>
              ) : (
                <a key={job.id} className="cursor-not-allowed">
                  <span className="opacity-25  py-1 px-3 rounded-full bg-baby-blue text-right text-blue Inter text-xs">
                    View summary
                  </span>
                </a>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    <div>
        <Pagination
            postsPerPage={postsPerPage}
            totalPosts={jobs.length}
            paginate={paginate}
            forward={forward}
            backward={backward}
            currentPage={currentPage}
        />
    </div>
    </div>
  )
}