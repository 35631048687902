import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import { DocumentCommentResponse, Document as DocumentResponse } from './interfaces';

export const getDocumentComments = async (
  ID: string
): Promise<DocumentCommentResponse> => {
  return await axios
    .get<DocumentCommentResponse>(`${process.env.REACT_APP_NLP_API_BASE_URL}/document/${ID}/comments`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error getting document comments: ${ID}`, error);
      return Promise.reject(error);
    });
};

export function useGetDocumentCommentsQuery(
  ID: string,
  options?: QueryOptionsOmit<
    UseQueryOptions<DocumentCommentResponse, any, DocumentCommentResponse>
  >
) {
  return useQuery(
    ['documents', 'details', ID, 'comments'],
    () => {
      return getDocumentComments(ID);
    },
    options as any
  );
}
