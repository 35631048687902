import React, {useState} from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export default function Pagination({
  postsPerPage,
  totalPosts,
  paginate,
  forward,
  backward,
  currentPage,
}) {

  const pagesNumber = Math.ceil(totalPosts / postsPerPage)

  if (totalPosts < postsPerPage) return <></>;
  return (
        <div className="Inter flex justify-end mr-10">
          <nav className="flex items-center">
            <button
              onClick={() => {
                backward(currentPage);
              }}
              className={`${
                currentPage <= 1 ? "hidden" : "flex"
              } justify-center min-w-md w-12 max-w-xs p-3 bg-white border-gray-300 text-gray-500 hover:bg-baby-blue relative inline-flex items-center border transition-all duration-200`}>
              <BsChevronLeft size={20} />
            </button>
            <ul className="flex pl-0 rounded list-none flex-wrap">
              <li>
                {currentPage === pagesNumber &&
                pagesNumber > 2 ? (
                  <a
                    key={currentPage - 2}
                    onClick={() => {
                      paginate(currentPage - 2);
                    }}
                    href="#"
                    className={
                      "justify-center  min-w-sm w-12 max-w-xs p-3 bg-white border-gray-300 text-gray-500 hover:bg-baby-blue-200 relative inline-flex items-center border text-sm font-medium transition-all duration-200"
                    }>
                    {currentPage - 2}
                  </a>
                ) : (
                  <></>
                )}

                {currentPage - 1 !== 0 ? (
                  <a
                    key={currentPage - 1}
                    onClick={() => {
                      paginate(currentPage - 1);
                    }}
                    href="#"
                    className={
                      "justify-center  min-w-sm w-12 max-w-xs  p-3 bg-white border-gray-300 text-gray-500 hover:bg-baby-blue-200 relative inline-flex items-center border text-sm font-medium transition-all duration-200"
                    }>
                    {currentPage - 1}
                  </a>
                ) : (
                  <></>
                )}

                <span
                  className={
                    "justify-center min-w-md w-12 max-w-xs p-3 bg-baby-blue text-blue border-blue text-red-500 hover:bg-baby-blue-200 relative inline-flex items-center border text-sm font-medium transition-all duration-200 "
                  }>
                  {currentPage}
                </span>

                {currentPage !== pagesNumber ? (
                  <a
                    key={currentPage + 1}
                    onClick={() => {
                      paginate(currentPage + 1);
                    }}
                    href="#"
                    className={
                      "justify-center min-w-sm w-12 max-w-xs p-3 bg-white border-gray-300 text-gray-500 hover:bg-baby-blue-200 relative inline-flex items-center border text-sm font-medium transition-all duration-200"
                    }>
                    {currentPage + 1}
                  </a>
                ) : (
                  <></>
                )}

                {currentPage - 1 === 0 &&
                pagesNumber > 2 ? (
                  <a
                    key={currentPage + 2}
                    onClick={() => {
                      paginate(currentPage + 2);
                    }}
                    href="#"
                    className={
                      "justify-center  min-w-sm w-12 max-w-xs p-3 bg-white border-gray-300 text-gray-500 hover:bg-baby-blue-200 relative inline-flex items-center border text-sm font-medium transition-all duration-200"
                    }>
                    {currentPage + 2}
                  </a>
                ) : (
                  <></>
                )}
              </li>
            </ul>
            <button
              onClick={() => {
                forward(currentPage);
              }}
              className={`${
                currentPage >= pagesNumber
                  ? "hidden"
                  : "flex"
              } p-3 bg-white border-gray-300 text-gray-500 hover:bg-baby-blue relative inline-flex items-center border transition-all duration-200`}>
              <BsChevronRight size={20} />
            </button>
          </nav>
        </div>
  );
}
