// src/components/auth-nav.js

import React from 'react';
import AuthenticationButton from './auth-button';

const AuthNav = () => (
  <div className="navbar-nav text-center w-full p-auto ">
    <AuthenticationButton />
  </div>
);

export default AuthNav;