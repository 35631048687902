import React from 'react';
import { useGetDocumentCommentsQuery } from '../../../../../../services/v1/getDocumentComments';
import { useParams } from 'react-router-dom';
import QuestionItem from './QuestionItem';
import { useGetDocumentByIDQuery } from '../../../../../../services/v1/getDocumentById';
import { isNotAnswered } from '../../../../../../helpers/contract';
import UnansweredItem from './UnansweredItem';
import QuestionListSkeleton from './QuestionList.skeleton';

interface IQuestionListProps {
  active: 'answered' | 'unanswered';
}

const QuestionList: React.FC<IQuestionListProps> = ({ active }) => {
  const { id: contractID } = useParams();

  const { data: commentsData, isLoading: loadingCommentsData } =
    useGetDocumentCommentsQuery(contractID || '');
  const { data: documentData, isLoading: loadingDocumentData } =
    useGetDocumentByIDQuery(contractID || '');

  const commentsToDisplay = React.useMemo(() => {
    if (commentsData && !loadingCommentsData) {
      const generalQuestions =
        documentData?.result[0].result.summary.prompting_general_answers || [];
      const userQuestions =
        documentData?.result[0].result.summary.user_qas || [];

      const yesNoRisks =
        documentData?.result[0].result.summary.yes_no_risks || [];

      return [...generalQuestions, ...userQuestions, ...yesNoRisks].filter(
        (answer) => !isNotAnswered(answer.answer)
      );
    }
    return [];
  }, [commentsData, loadingCommentsData]);

  const unansweredComments = React.useMemo(() => {
    return documentData?.result[0].result.summary.prompting_general_answers.filter(
      (answer) => isNotAnswered(answer.answer)
    );
  }, [documentData]);

  const riskScores = React.useMemo(() => {
    return documentData?.result[0].result.summary.yes_no_risks;
  }, [documentData]);



  const assignRiskScoreToQuestion = (questionId: string) => {
    const match = riskScores?.find((item) =>
      item.answer_references.includes(questionId)
    );
    return match?.risk_score;
  };

  if (loadingCommentsData) {
    return <QuestionListSkeleton/>;
  }

  if (active === 'unanswered') {
    return (
      <div className="flex flex-col gap-2 mt-5">
        <p className='text-xs p-1'>
          To add answer, please select the relevant text first in the document,
          and select question from dropdown.
        </p>
        {unansweredComments?.map((question) => (
          <UnansweredItem question={question} key={question.id} />
        ))}
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2 mt-5">
      {commentsToDisplay.map((question) => (
        <QuestionItem
          question={question}
          key={question.prompt_id}
          riskScore={assignRiskScoreToQuestion(question.id || '')}
        />
      ))}
    </div>
  );
};

export default QuestionList;
