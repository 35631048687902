import React, { useEffect } from 'react';

const ClickOutside = ({ children, onClickOutside, onClickInside }) => {
  const ref = React.useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        if(onClickInside) onClickInside(event);
      } else if (onClickOutside) {
        onClickOutside(event);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [onClickInside, onClickOutside]);

  return <div ref={ref}>{children}</div>;
};

export default ClickOutside;
