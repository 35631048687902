import { HiOutlineSearch } from "react-icons/hi";
import { NavLink, useLocation } from "react-router-dom";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useNavigate } from "react-router-dom";
import { IoDownload, IoTrashBin } from "react-icons/io5";
import { FaPaperPlane } from "react-icons/fa";
import Modal from "./ModalShare";
import { useState } from "react";
import { notifications } from "../../components/layout/MainLayout";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useDeleteJobMutation } from "../../services/v1/deleteJob"
import Notification from "../../components/Single_Components/Notification";


export default function DocumentHeader({ props }) {
  const doc = props[0];
  const url = props[1];
  const text = props[2];
  const date = props[3];
  const name = props[4];
  const docx = props[5];
  const uniqueID = props[6];
  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };
  const location = useLocation()
  const [showModal, setShowModal] = useState(false);
  const handleDeleteClick = () => {
    setShowModal(true);
    console.log(doc);
};
  const navigate = useNavigate();
  const deleteAccountMutation = useDeleteJobMutation();
  const handleConfirmDelete = () => {
    deleteAccountMutation.mutate(doc.general_information.job_id, {
        onSuccess: () => {
            notifications.push({
                icon: <AiOutlineCheckCircle size={25} color="green" />,
                upper_text: "Success",
                lower_text: "Document deleted with success!",
              });
            setShowModal(false);
            setTimeout(4000);
            window.localStorage.clear()
            navigate('/home/contracts');
        },
        onError: (error) => {
            console.error("Error deleting account:", error);
            notifications.push({
                icon: <AiOutlineCloseCircle size={25} color="red" />,
                upper_text: "Failed",
                lower_text: "Document was not deleted. Server returned error.",
              });
        }
    });
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };
  return (
    <div>
      <Notification />
      <Modal open={modal} closeModal={closeModal} uniqueID={uniqueID} />
      <div className="relative bg-white rounded-xl border shadow-sm mb-5 z-20">
        <div className="py-8 px-6 sm:py-10 lg:flex lg:justify-between lg:px-8 items-center">
          <div className="flex  w-3/4 max-w-96 lg:w-8/12 xl:3/4">
            <div className="min-w-[0.5rem] w-2 h-[66px] mr-6 bg-[#748AF2]"></div>
            <div className="block">
              <h2 className="text-4xl font-semibold Inter tracking-tight text-black capitalize sm:text-2xl lg:text-3xl break-all line-clamp-2">
                {name}
              </h2>
              <ul className="flex flex-wrap list-disc">
                {doc?.general_information?.company_name ? (
                  <li className="first:list-none pr-7 whitespace-nowrap  mt-2 text-md text-gray-400">
                    {doc?.general_information?.company_name}
                  </li>
                ) : (
                  <></>
                )}
                {doc?.general_information?.document_type ? (
                  <li className="first:list-none pr-7 mt-2 whitespace-nowrap text-md text-gray-400">
                    {doc?.general_information?.document_type}
                  </li>
                ) : (
                  <></>
                )}

                {doc?.general_information?.language ? (
                  <li className="first:list-none whitespace-nowrap pr-7  mt-2 text-md text-gray-400">
                    {doc?.general_information?.language}
                  </li>
                ) : (
                  <></>
                )}
                {date ? (
                  <li className="first:list-none pr-7 whitespace-nowrap  mt-2 text-md text-gray-400">
                    <span className="line-clamp-2">{date}</span>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
          <div className="justify-end flex md:block items-center lg:flex w-1/4 lg:w-1/3 relative mt-5 md:mt-0 font-medium">
            <div className="">
              <button onClick={handleDeleteClick} className="hover:text-indigo-900">
                <div className="flex py-2 pt-0 px-5 rounded-full bg-white text-right items-center fade-in duration-300 focus:outline-none">
                  <IoTrashBin
                    size={35}
                    className="fill-[red] hover:fill-[red] hover:opacity-70"
                  />
                </div>
              </button>
            </div>
            {showModal && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded shadow-lg">
                        <h2 className="text-xl mb-4">Confirm Deletion</h2>
                        <p>Are you sure you want to delete the document? This action cannot be undone.</p>
                        <div className="mt-6 flex justify-end">
                            <button onClick={handleCancelDelete} className="mr-2 px-4 py-2 rounded bg-gray-300 hover:bg-gray-400">
                                Cancel
                            </button>
                            <button
                            onClick={handleConfirmDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {docx && (<div className="">
              <a href={docx} className="hover:text-indigo-900">
                <div className="flex py-2 pt-0 px-5 rounded-full bg-white text-right items-center fade-in duration-300 focus:outline-none">
                  <IoDownload
                    size={35}
                    className="fill-[#748AF2] hover:fill-[#748AF2] hover:opacity-70"
                  />
                </div>
              </a>
            </div>)}
            
            {useFeatureFlagEnabled("[FE]-[Contract]-make_share_visible") ? (

              <div className="mr-5 ">
                <button onClick={openModal} className="hover:text-indigo-900">
                  <div className="flex py-2 px-5 rounded-full bg-white text-right items-center fade-in duration-300 focus:outline-none">
                    <FaPaperPlane
                      size={25}

                      className="fill-[#748AF2] hover:fill-[#748AF2] hover:opacity-70"
                    />
                  </div>
                </button>
              </div>
            ) : (
              <></>
            )}

            <div className="min-w-fit">
              {url.split("/")[1] !== "undefined" ? (
                <NavLink to={"/" + url} state={location.state} className="hover:text-indigo-900">
                <div className="text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer">
                  <HiOutlineSearch className="mr-3" size={20} />
                  <span className="Inter text-md">{text}</span>
                </div>
              </NavLink>
              ) : <> </>}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
