import { IoIosWarning } from "react-icons/io";

function Disclaimer(props) {
  return (
    <div
      className={` ${
        props.styles ? props.styles : "shadow-[0_0_10px_0px_rgba(0,0,0,0.1)]"
      }  my-6 max-w-[970px] rounded-xl p-5`}>
      <div className="flex items-center">
        <span className="bg-[#FBBF24]/30 rounded-xl p-2">
          <IoIosWarning color="#FBBF24" className="rounded" size={25} />
        </span>
        <span className="text-[#9D5425] font-black Inter ml-[38px]">
          Disclaimer
        </span>
      </div>
      <div className="block ml-20 max-w-[740px] text-[#D0915C]">
        <span className="text-[16px] Inter ">
          Legalreview.ai is not a law firm and does not engage in the practice
          of law or provide legal advice or legal representation. All
          information, software, services, and comments provided on this website
          are generated by Artificial Intelligence for informational and
          self-help purposes only and shall not be considered a replacement for
          professional legal advice.
        </span>
      </div>
    </div>
  );
}

export default Disclaimer;
