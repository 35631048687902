import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {
    CompanyDocumentSubscriptionResponse, CompanyDocumentUnsubscriptionResponse,
    CompanySubscriptionResponse, CompanyUnsubscriptionResponse,
    TrademarkClassificationResponse
} from "./interfaces";

export const unsubscribeCompanyDocument = async (
    companyDocumentId: string
): Promise<CompanyDocumentUnsubscriptionResponse> => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token"),
    }} ;
    console.log("companyDocumentId:", companyDocumentId)
    return await axios
      .delete<CompanyDocumentUnsubscriptionResponse>(`${process.env.REACT_APP_NLP_API_BASE_URL}/monitoring/subscriptions/${companyDocumentId}`, requestOptions)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error subscribing company documents`, error);
        return Promise.reject(error);
      });
};

export const unsubscribeCompany = async (
    companyId: string
): Promise<CompanySubscriptionResponse> => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token"),
        }} ;
    console.log("companyId:", companyId)
    return await axios
        .delete<CompanyUnsubscriptionResponse>(`${process.env.REACT_APP_NLP_API_BASE_URL}/monitoring/subscriptions/company/${companyId}`, requestOptions)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            console.error(`Error unsubscribing company`, error);
            return Promise.reject(error);
        });
};

