import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import LoginButton from "../components/auth/login";
import MainLayout from "../components/layout/MainLayout";
import Loading from "../components/Single_Components/Loading";

export const AuthenticationGuard = ({ component }) => {
  // const Component = component
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <Loading />
      </div>
    ),
  });

  return <Component />;
};
