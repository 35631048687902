import { AiOutlineLoading3Quarters } from "react-icons/ai";


export default function TableStatus({totalDocs}) {



    return <div>
    <span>Upload Status</span>

    <table className="min-w-1/2 w-1/2 divide-y divide-gray-300 table-fixed table-auto">
      <thead className="Inter text-left text-xs font-bold text-[#6B7280] bg-[#F9FAFB] ">
        <tr>
          <td>Name</td>
          <td>Status</td>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
      {totalDocs?.map((file, index) => (
        <tr key={index+"file.name"}>
          <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
        {file.file?.name ? (
          file.file?.name
        ) : (
          <>-</>
        )}
      </td>
      <td className="Inter text-center px-3">
        <div
          className={`p-2 px-3 text-sm capitalize max-w-fit font-light  rounded-full ${
            file.status === "created"
              ? "text-green-600 bg-opacity-25 bg-green-500"
              : file.status === "new"
              ? "text-gray-600 bg-opacity-25 bg-gray-500"
              : "bg-red-primary text-red-primary bg-opacity-25 "
          } 
          `}>
          <span className="">
            {file.status === "new"
              ? <>
              <div className='flex items-center'>
                <AiOutlineLoading3Quarters className='animate-spin min-w-[15px] mr-3' size={15}/>
                <span>Uploading...</span>
              </div></>
              : file.status === "rejected"
              ? "Error"
              : file.status}
          </span>
        </div>
      </td>
        </tr>

    ))}
      </tbody>
    </table>
  </div>
}