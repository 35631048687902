import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {CompanyCertificate} from './interfaces';

export const getCompanyCertificationsById = async (
  ID: string
): Promise<CompanyCertificate[]> => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem("token")
    }} ;
  return await axios
    .get<CompanyCertificate[]>(`${process.env.REACT_APP_NLP_API_BASE_URL}/companies/web/${ID}/certifications`, requestOptions)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error getting company certifications: ${ID}`, error);
      return Promise.reject(error);
    });
};

export function useGetCompanyCertificationsByIdQuery(
  ID: string,
  options?: QueryOptionsOmit<
    UseQueryOptions<any>
  >
) {
  return useQuery(
    ['companies', 'certificates', ID],
    () => {
      return getCompanyCertificationsById(ID);
    },
    options as any
  );
}
