import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {CompaniesDocumentsResponse} from './interfaces';

export const getCompaniesDocuments = async (): Promise<CompaniesDocumentsResponse> => {
  return await axios
    .get<CompaniesDocumentsResponse>(`${process.env.REACT_APP_NLP_API_BASE_URL}/companies/documents`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error getting companies documents`, error);
      return Promise.reject(error);
    });
};

export function useGetCompaniesDocumentsAsQuery(
  options?: QueryOptionsOmit<
    UseQueryOptions
  >
) {
  return useQuery(
    [],
    () => {
      return getCompaniesDocuments();
    },
    options as any
  );
}
