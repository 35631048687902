import React from 'react';

const QuestionsContext = React.createContext({} as any);

export const QuestionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeItems, setActiveItems] = React.useState<string[]>([]);
  const onSetActiveItems = (newActiveItems: string[]) => {
    const items = newActiveItems;
    setActiveItems(items);
  };
  const context = React.useMemo(() => {
    return {
      activeItems,
      onSetActiveItems,
    };
  }, [activeItems]);
  return (
    <QuestionsContext.Provider value={context}>
      {children}
    </QuestionsContext.Provider>
  );
};

export const useQuestionsProvider = () => React.useContext(QuestionsContext);
