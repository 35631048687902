export interface Document {
  id: number;
  result: ResultElement[];
  status: Status[];
  created_at: string;
  updated_at: string;
  file: string;
  original_file: string;
  file_two: null;
  original_file_two: null;
  source: string;
  message_id: null;
  email_text: string;
  url: string;
  extra_info: string;
  subject: null;
  job_type: string;
  unique_id: string;
  playbook_id: number;
  should_redact: boolean;
  project: null;
  users: number[];
}

export interface ResultElement {
  id: number;
  created_at: string;
  updated_at: string;
  result: ResultResult;
  file: string;
  document_type: string;
  document_name: string;
  company_name: string;
  job: number;
}

export interface ResultResult {
  summary: Summary;
  summary_html: string;
  document_html: string;
  meta_information: MetaInformation;
  doc_segments_by_id: { [key: string]: DocSegmentsByID };
  document_body_text: string;
  email_summary_html: string;
  general_information: GeneralInformation;
  ordered_doc_segments: DocSegmentsByID[];
  doc_analysis_summary_data: DocAnalysisSummaryData;
}

export interface DocAnalysisSummaryData {
  total_checks: number;
  total_success_checks: number;
  total_unknown_checks: number;
  total_warning_checks: number;
  document_analysis_summary_text: string;
}

export interface DocSegmentsByID {
  id: string;
  tags: any[];
  text: string;
  comments: any[];
  insights: any[];
  element_type: ElementType;
  external_knowledge: Knowledge;
  element_optional_info: ElementOptionalInfo;
}

export enum ElementOptionalInfo {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  P = 'p',
}

export enum ElementType {
  Paragraph = 'paragraph',
  Section = 'section',
}

export interface Knowledge {}

export interface GeneralInformation {
  score: number;
  job_id: number;
  origin: string;
  version: string;
  glossary: any[];
  language: string;
  playbook_id: number;
  company_name: string;
  document_name: string;
  document_type: string;
  playbook_name: string;
  document_category: string;
  document_type_short: string;
}

export interface MetaInformation {
  risk_threshold: number;
}

export interface Summary {
  risks: any[];
  key_info: any[];
  user_qas: any[];
  categories: any[];
  yes_no_risks: YesNoRisk[];
  company_knowledge: Knowledge;
  document_knowledge: Knowledge;
  prompting_general_answers: any[];
}

export interface YesNoRisk {
  answer: Answer;
  tag_name: string;
  info_note: string;
  prompt_id: string;
  follow_ups: any[];
  risk_score: number;
  prompt_text: string;
  answer_references: string[];
}

export enum Answer {
  No = 'No',
  Yes = 'Yes'
}

export interface Status {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  job: number;
}

export interface DocumentComment {
  id: string;
  created_at: string;
  document_id: number;
  text: string;
  title: string;
  element_type: string;
  element_optional_info: string;
  order_idx: number;
  parent: null;
  comments: any[];
}


export interface DocumentCommentResponse {
    [key: string]: DocumentComment
}

export interface CompanyDocument {
  id: string
  document_type: string
  document_url: string
  share_url: string
  last_analysis_date: string

  is_monitoring: boolean
  document_info: { [key: string]: any }
  has_access: boolean
}


export interface CompanyDocuments {
  id: string
  company_name: string
  company_logo: string
  company_metadata: { [key: string]: any }
  documents: {[key: string]: CompanyDocument}
  apps: string[]
  certificates: string[]
}

export interface CompaniesDocumentsResponse {
  [companies: string]: Array<CompanyDocuments>
}

export interface TrademarkClassification {
  id: string
  class_id: string
  class_description: string
  trademark_type: string
  explanation: string
}

export interface TrademarkClassificationResponse {
  [trademark_nice_classes: string]: Array<TrademarkClassification>
}

export interface CompanyMetadata {
  [key: string]: any
  // description
  // totalEmployees
  // revenue
  // yearFounded
}


export interface Company {
  id: string
  name: string
  base_url: string
  metadata: CompanyMetadata
  type: string
  logo_url: string

  is_monitoring: boolean

  documents: Array<CompanyDocument>
}

export interface CompanyDocumentSubscriptionResponse {
  success: boolean
}

export interface CompanySubscriptionResponse {
  success: boolean
}

export interface CompanyCertificate {
  certificate_id: string
  name: string
  url: string

}

export interface CompanyDocumentUnsubscriptionResponse {
  success: boolean
}

export interface CompanyUnsubscriptionResponse {
  success: boolean
}

export interface CompanyDocumentSubscription {
  company_document_id: string
  is_monitoring: boolean
}

export interface CompanyDocumentSubscriptionsResponse {
  [key: string]: CompanyDocumentSubscription
}

export interface PaginatedCompanyResult {
  total_pages: number
  items: CompanyDocuments[]
}

export interface SupabaseDBUserWrapper {
  user: SupabaseDBUser
}

export interface SupabaseDBUser {
  id: string
  created_at: string
  email: string
  subscription_type: string
  cancel_url: string
  update_url: string
}

export interface PaymentUrl {
  url: string
}