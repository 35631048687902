const FetchDocuments = async ([searchedjobs, setSearchedJobs], token) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": token },
    };
    const fetchAllDocuments = async () => {

        const response = await window.fetch(process.env.REACT_APP_DOMAIN + "/api/v1/jobs/", requestOptions, {
        })

        const result = await response.json();
        result.sort((a, b) => (new Date(a.updated_at) - new Date(b.updated_at))).reverse()
        if (result.length !== 0) {
            setSearchedJobs(result)
        }

        return response
    };

    return await fetchAllDocuments();
}
export default FetchDocuments