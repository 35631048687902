import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import Container from "../../components/Single_Components/Container";
import Notification from "../../components/Single_Components/Notification";
import Loading from "../../components/Single_Components/Loading";
import React from "react";
import {TrademarkClassification} from "../../services/v1/interfaces";
import {classifyTrademark} from "../../services/v1/classifyTrademark";
import {AiOutlineFileSearch} from "react-icons/ai";
import {TiBusinessCard} from "react-icons/ti";

export default function TrademarksPage() {

    const [trademarkClasses, setTrademarkClasses] = useState<TrademarkClassification[]>([]);
    const [trademarkDescriptionText, setTrademarkDescriptionText] = useState<string>("");
    const [isClassifying, setIsClassifying] = useState<boolean>(false);

    const changeTrademarkDescription = (e: any) => {
        setTrademarkDescriptionText(e.target.value);
    };

    const classifyTrademarkHandler = async () => {
        setIsClassifying(true)
        const classificationResult = await classifyTrademark(trademarkDescriptionText);
        setTrademarkClasses(classificationResult.trademark_nice_classes)
        setIsClassifying(false)
    }

    return (
        <Container>
            <div className="mt-11 mb-11">
                <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible  max-h-screen">
                    <h1 className="text-2xl Inter font-black pb-7">
                        {<TiBusinessCard className="inline-block mr-3" size={30}/>}
                        Trademark Classification
                    </h1>
                    <Notification children={undefined} width={undefined} />
                    <span className="block Inter text-sm mb-5">
                      Classify trademarks with respect to Nice Agreement classes.
                    </span>
                    <div className="sm:flex-auto mb-2">
                        <div className="">
                            <div className="w-4/4">
                                <div className="relative text-[#6B7280] focus-within:text-gray-400">
                                    <div className="pointer-events-none absolute py-2 inset-y-0 left-0 flex pl-3">
                                        <MagnifyingGlassIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <textarea
                                        id="trademarkDescription"
                                        name="trademarkDescription"
                                        className="block w-full rounded-md border border-transparent bg-[#F9FBFF] py-2 pl-10 pr-3 leading-5 text-black placeholder-[#6B7280] focus:bg-[#F8FAFF] fade-in duration-100 focus:outline-none sm:text-sm"
                                        placeholder="eg. The product is an AI Saas product."
                                        value={trademarkDescriptionText}
                                        onChange={changeTrademarkDescription}
                                        rows={4}
                                    />
                                </div>
                            </div>
                            <br/>
                        </div>
                        <br/>
                        <div className="grid place-items-center">
                            <div className="text-indigo-600 text-md hover:text-indigo-900">
                                <button
                                    className="text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer mb-5"
                                    onClick={classifyTrademarkHandler}
                                    type="submit"
                                    disabled={isClassifying}
                                >
                                    <AiOutlineFileSearch className="mr-3" size={20} />
                                    Classify Trademark
                                </button>
                            </div>
                            <br/>
                        </div>
                    </div>

                    {isClassifying ? <Loading style={{}}/> : <div className="-mx-4 rounded-xl sm:-mx-6 md:mx-0 overflow-auto overflow-y-hidden max-h-screen">
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="Inter w-full text-center text-xs font-bold text-[#6B7280] bg-[#F9FAFB] ">
                            <tr>
                                <th scope="col" className="px-3 py-3.5 w-1/12">
                                    Class
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/12">
                                    Class Type
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/3">
                                    Definition
                                </th>
                                <th scope="col" className="px-3 py-3.5 w-1/3">
                                    Explanation
                                </th>


                            </tr>
                            </thead>
                            <tbody className="divide-y text-center divide-gray-200 bg-white">
                            {trademarkClasses && trademarkClasses?.map((trademark: TrademarkClassification) => {
                                return (<tr className="even:bg-[#F9FAFB]" key={trademark.id}>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis">
                                        {trademark.class_id}
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis capitalize">
                                        {trademark.trademark_type}
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell overflow-x-hidden overflow-ellipsis text-justify">
                                        {trademark.class_description}
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell overflow-x-hidden overflow-ellipsis text-justify">
                                        {trademark.explanation}
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </Container>
    );
}
