import React from 'react';
import QuestionList from './QuestionList';
import { QuestionsProvider } from './QuestionsContext';

interface IQuestionsProps {}

const Questions: React.FC<IQuestionsProps> = ({}) => {
  const [active, setActive] = React.useState<'answered' | 'unanswered'>(
    'answered'
  );
  const activeStyle =
    'text-[#748AF2] text-xs font-semibold bg-white rounded-sm px-6 py-2 w-[50%]';
  const inactiveStyle =
    'text-xs font-semibold text-[#8D92A7] rounded-sm px-6 py-2 w-[50%]';

  return (
    <QuestionsProvider>
      <div>
        <div className="w-full bg-[#F9FAFE] rounded p-1 flex justify-between">
          <button
            className={active === 'answered' ? activeStyle : inactiveStyle}
            onClick={() => setActive('answered')}
          >
            Answered
          </button>
          <button
            onClick={() => setActive('unanswered')}
            className={active === 'unanswered' ? activeStyle : inactiveStyle}
          >
            Not answered
          </button>
        </div>
        <QuestionList active={active} />
      </div>
    </QuestionsProvider>
  );
};

export default Questions;
