const FetchUserInfo = async () => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": localStorage.getItem("token") },
    };

    const fetchUserInfo = async () => {
        const response = await window.fetch(process.env.REACT_APP_DOMAIN+"/api/users/me/", requestOptions).then(async(result) => {
            return result = await result.json();
        })
        return response
    };
    return await fetchUserInfo();
}
export default FetchUserInfo