import {NavLink} from "react-router-dom"
import LegalReview from "../../assets/images/beta_full_logo.png"

export default function TopNav() {
    return (<>
    <NavLink to="/home/contracts" className="max-w-max">
        <div className="mt-[10px] h-[56px] max-w-[250px] mb-5 flex p-2 pl-6 drop-shadow-xl">
            <img src={LegalReview} alt=" React Logo" className="max-w-[196px]"/>
        </div>
    </NavLink></>

    )
}