import React from 'react';
import Sidebar from '../components/navbar/Sidebar';

export default function NotFound() {
    return (
        <div className="bg-gray-50 flex items-center justify-center h-screen">
          <div className="max-w-sm text-center">
            <h1 className="text-4xl font-medium text-gray-800">404</h1>
            <div className="mt-4 text-gray-500 text-lg">
              Sorry, the page you're looking for could not be found.
            </div>
          </div>
        </div>
      );
};
