import axios from 'axios';

// Configure outbound request interceptor logic
axios.interceptors.request.use(
  (request: any) => {
    if (!request.headers) {
		request.headers  = {} as any
	}
    const token = localStorage.getItem('token');
    request.headers['Authorization'] = token;
    return request;
  },
  (error) => Promise.reject(error)
);

// Configure incoming response interceptor logic
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => Promise.reject(error)
);
