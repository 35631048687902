import { AiOutlineCloudUpload } from "react-icons/ai";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import { useOutletContext } from "react-router-dom";
import FetchDocuments from "../../../assets/FetchDocuments";
import { CgSoftwareUpload } from "react-icons/cg";
import { HiPencil } from "react-icons/hi";
import Questions from "../../../components/Single_Components/Questions";
import Disclaimer from "../../../components/Single_Components/Disclaimer";
import { HiOutlineDocumentText } from "react-icons/hi";
import Container from "../../../components/Single_Components/Container";
import FetchUserInfo from "../../../assets/FetchBalance";
import Notification from "../../../components/Single_Components/Notification";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { notifications, createdJob } from "../../../components/layout/MainLayout";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import Modal from "../../../components/Single_Components/ModalUpload";
import { AiOutlineLink } from "react-icons/ai";
import { MdOutlineError } from "react-icons/md";
import { posthog } from "posthog-js";
import Onboarding from "../../../components/Single_Components/Onboarding";
import Dropdown from "../../../components/Single_Components/Dropdown";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { FaChevronLeft } from "react-icons/fa";
import { projectTypes, playbook } from "../../../components/layout/MainLayout";

export default function CreateProject({ setTimeOfJob }) {
  const [project, setProject] = useState();
  const [questions, setQuestions] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [projectName, setProjectName] = useState();
  const [projectID, setProjectID] = useState();

  const navigate = useNavigate();
  const { user } = useAuth0();

  const [option, setDropDownChild] = useState(Object.values(playbook)[0]);
  const [project_type, setProjectType] = useState(Object.values(projectTypes)[0])

  const [notification_component, setNotification_component] = useState(null);
  const [step, setStep] = useState(0);

  
  const handleAddProject = async () => {
    setUploading(true);
    setError(null);
    let projectNameValue = document.getElementById("project").value;
    setProjectName(projectNameValue);
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": localStorage.getItem("token") },
            body: JSON.stringify({ "name": projectNameValue, "playbook_id": option})
        };
        if(project_type) requestOptions.body = JSON.stringify({ "name": projectNameValue, "playbook_id": option, "project_type": project_type})
        const response = await window.fetch(process.env.REACT_APP_DOMAIN+"/api/v1/projects/", requestOptions)
        const result = await response.json()
        setProjectID(result.project_id);
        navigate(`/home/projects/${result.project_id}/upload`,{state: {project_name: projectNameValue}});
        setUploading(false);
        setStep(1);
    } catch (err) {
      console.log(err.message);
      notifications.push({
        icon: <AiOutlineCloseCircle size={25} color="red" />,
        upper_text: "Unable to review your document!",
        lower_text: err.message,
      });
      setNotification_component(() => <Notification />);
      // setNotification_component(null);
    }
  };

  const stepsOnboarding = [
    {
      target: ".step1",
      content:
        "Upload the document you wish to review or provide an URL for a website to review",
    },
    {
      target: ".step2",
      content: "You can upload or select a file to review",
    },
    {
      target: ".step3",
      title: "User questions",
      content:
        "You can ask one or more questions you would like to know about your document",
      placement: "top",
    },
    {
      target: ".step4",
      content: "Submit your document for review (this may take some minutes)",
      placement: "top",
    },
  ];

  return (
    <Container>
      {useFeatureFlagEnabled("[FE]-[Global]-show_onboarding") && <Onboarding steps={stepsOnboarding} />}

      <div className="mt-5 m-10 mb-8">
      <NavLink to={`/home/projects/`}>
            <button
              className=" mb-10 bg-white py-1.5 flex items-center text-[#748AF2] font-[500] "
              type="submit">
              <FaChevronLeft size={20}/><span className="ml-5">Back to projects</span>
            </button>
          </NavLink>
      <div>

        <div className="flex justify-between items-center">
          <div className="mb-10 font-black text-3xl Inter">Add project</div>
          {notification_component}
        </div>
        <div className="mb-10 ">
            <label className="block text-md text-[16px] font-[500] Inter mb-4">
                Project name
            </label>
            <input
            type="text"
            placeholder="&#xf044;  Type your project name"
            value={project}
            onChange={(e) => setProject(e.target.value)}
            id="project"
            name="project"
            style={{ fontFamily: "Inter, FontAwesome", fontSize: "16px" }}
            className="w-[637px] border p-4 text-lg rounded focus:outline-none Inter"
            // defaultValue={''}
          />
        </div>
        
          <div className="mb-10 w-1/3 flex justify-between">
            <div>
              <label className="block text-md text-[16px] font-[500] Inter mb-4">
                Question playbook
              </label>
              <Dropdown filters={playbook} dropDownChild={setDropDownChild} defaultFilter={-1}/>
            </div>
            <div>
              <label className="block text-md text-[16px] font-[500] Inter mb-4">
                Project type
              </label>
              <Dropdown filters={projectTypes} dropDownChild={setProjectType} defaultFilter={null}/>
            </div>


            </div>
          
        {/* <Questions questions={questions} setQuestions={setQuestions} /> */}
        <button
          type="submit"
          className="step4 text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer"
          onClick={handleAddProject}
          disabled={uploading}>
          <HiOutlineDocumentText className="mr-2" size={18} />
          {uploading ? "Creating..." : "Add project"}
        </button>
        {/* <Disclaimer styles={"shadow-none"} /> */}

      </div>
      </div>
    </Container>
  );
}


