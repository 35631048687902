import React, { useState, useRef, useEffect } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { HiDocumentSearch } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Loading from "../../../components/Single_Components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { Replace_idk_answer } from "../../../assets/GeneralFunctions";

function AskQuestion(props) {
  const [openQuestion, setOpenQuestion] = props.openQuestionBox
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [asktext, setAskText] = useState("Ask");
  const [addComment, setAddComment] = useState("Add as a comment");
  const [addedCommentId, setAddedCommentId] = useState();
  const { user } = useAuth0();

  const fetchAnswer = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("token"),
        "Connection": "keep-alive",
      },
      body: JSON.stringify({
        language: props.language,
        question: question,
        document_id: props.id,
      }),
    };
      if(question.length >0){
        setAskText(<Loading style={"h-auto "}/>)
        setLoading(true);
        const result = await fetch(
            process.env.REACT_APP_NLP_API_BASE_URL+"/ask_question",
            requestOptions
          ).then(async (r) => {
            return await r.json();
          })
          
            if(result?.context_id === ""){
              setAddComment()
            }
            setResponse(result)
            setAskText("Ask")
            setLoading(false);
          
        return response;
      }
  };

  const insertComment = async () => {
    setAddComment(<Loading style={"h-auto "}/>)
    let document_element_id = response?.context_id
    let comment_text = response?.answer
    let prompt_text = question
    let filled_by = "AI"
    if(props.question){
      prompt_text = props.question[0]
      document_element_id = props.question[1]
    }
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("token"),
        "Connection": "keep-alive",
      },
      body: JSON.stringify({
        document_element_id: props.question ? props.question[1] : response?.context_id,
        comment_text: props.question ? question: response?.answer,
        prompt_id: props.question ? -1 : -1,
        prompt_text: props.question ? props.question[0]: question,
        created_by: user?.email,
        filled_by: props.question ? user?.email : "AI",
        tag_id: "user_comment" // tag for user comment
      }),
    };
    try{
      const response1 = await fetch(
        process.env.REACT_APP_NLP_API_BASE_URL+"/document/"+props.id+"/comments",
        requestOptions
      ).then(async (r) => {
        return await r.json();
      }).then((result) => {
        //re render comments list
        props.fetchComments().then((a) => {
          setAddedCommentId(() => result?.id)

          setAddComment("Add as a comment")
          handleClear()
          handleClose()
        })
      })
    } catch {
      setAddComment("Add as a comment")
    }

    //clear
   
    //pintar amarelo o comment
  };
  useEffect(() => {
    if(addedCommentId){

      setTimeout(() => {
        let element = document.getElementById(""+addedCommentId)
        element.style.background = "rgba(255, 170, 2, 0.38)";
        element.click()
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        },300);
      },200);
      setTimeout(() => {
        let element = document.getElementById(""+addedCommentId)
        element.style.background = "white";
      },4000)
    }

  },[props.comments])

  function handleClose() {
    setAskText("Ask")
    setOpenQuestion(!openQuestion);
    props.setQuestion()
    setQuestion("");
    let element = document.getElementById(
      "father-" + response?.context_id
    );
    if (element) {
      element.click();
      element.style.background = null;
    }
  }

  function handleClear() {
    let element = document.getElementById(
      "father-" + response?.context_id
    );
    if (element) {
      element.click();
      element.style.background = null;
    }

    setQuestion("");
    setResponse("");

  }

  useEffect(() => {
    if (document.getElementById("comments")) {
      document.getElementById("questions").style.width =
        document.getElementById("comments").offsetWidth + "px";
      document.getElementById("answer").style.width =
        document.getElementById("comments").offsetWidth + "px";
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const [isButtonFixed, setIsButtonFixed] = useState(false);

  function handleScroll() {
    if (window.pageYOffset >= 254) {
      setIsButtonFixed(true);
    } else {
      setIsButtonFixed(false);
    }
  }

  useEffect(() => {
    if(props.question){
      setOpenQuestion(true)
      setAskText("Add as a comment")
    }
  }, [props.question])

  const onEnterPress = (e) => {
    
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      setAskText(<Loading style={"h-auto "}/>)
      if(props.question)
      insertComment(e)
      else
      fetchAnswer(e)
    }
  }

  return (
    <>
      <div className={` z-20 right-10 -translate-y-6 ${isButtonFixed ? "fixed top-10" : "absolute"}`}>
        <button
          onClick={handleClose}
          className={`cursor-pointer  w-16 h-16 bg-blue rounded-full  text-center transition-all duration-200 ${
            openQuestion ? "hidden" : "block"
          }`}>
          <HiDocumentSearch className="mx-auto" size={30} color="white" />
        </button>
      </div>

      <div
        ref={props.ref}
        id={"questions"}
        className={`w-3/12 ${isButtonFixed ? "fixed top-10" : "absolute"}  border-4 border-gray-400 z-50 right-10 bg-white -translate-y-6 p-5 shadow-sm border rounded-xl transition-all duration-200 ${
          openQuestion ? "block" : "hidden"
        }`}>
        <div className="z-20">
          <div className="Inter font-bold mb-5 flex justify-between items-center">
            <div>
              <span>{props.question ? "System questions":"Query document" }</span>
            </div>
            <div className="text-center items-center flex">
              <button className="mr-3 z-20" onClick={handleClear}>
                clear
              </button>
              <button className="z-20" onClick={handleClose}>
                <AiOutlineCloseCircle size={25} color="red" />
              </button>
            </div>
          </div>
          <div className="relative mt-1 rounded-md w-full mb-3 block">
          
            {props.question && (
              <div className="mb-5 text-md text-black rounded border-l-8 border-l-[#009BB0] bg-[#009BB0]/[0.1] p-2 pl-4">
              <span 
              className="min-w-full w-full  focus:outline-none Inter"
            >{props.question[0]}</span>
            </div>
            )}
          <div>
          <textarea 
              type="text"
              onKeyDown={onEnterPress}
              rows={props.question ? 9 : 3}
              placeholder={props.question ? "Answer..." : "Question..."}
              name="company-website"
              id="company-website"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              style={{ fontFamily: "Inter, FontAwesome", fontSize: "16px", resize: "none" }}
              className="min-w-full border-l-8 border-l-gray-400 bg-[#F4F4F4] p-2 pl-4 text-md text-black rounded focus:outline-none Inter"
            />
            </div>

          </div>
          <div className="text-end">
            <button
              onClick={props.question ? insertComment : fetchAnswer}
              className="z-20 rounded-full py-1.5 w-1/3 bg-gray-400 text-white hover:bg-[#225187] transition-all duration-200"
              disabled={loading}>
              {asktext}
            </button>
          </div>
        </div>

      </div>
      <div
        id={"answer"}
        className={`h-fit  ${isButtonFixed ? "fixed top-[241px]" : "absolute top-[503px]"} z-10 w-3/12 bg-gray-400 p-5 pt-6 rounded-b-xl right-10  ${
          openQuestion && response ? "block" : "hidden"
        }`}>
        <div className="bg-white rounded-xl p-3">
          <span>{Replace_idk_answer(response?.answer ? response?.answer : "") }</span>
        </div>
        {addComment ? <div className="text-end mt-3">
          <button onClick={insertComment} 
                  className="z-20 rounded-full py-1.5 w-1/3 bg-blue text-white hover:bg-[#225187] transition-all duration-200">
                  {addComment}</button></div>: <></>}
        
      </div>
    </>
  );
}

export default AskQuestion;
