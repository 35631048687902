import React from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import References from '../References/References';

interface IUnansweredItemProps {
  question: any;
  riskScore?: number;
}

const UnansweredItem: React.FC<IUnansweredItemProps> = ({
  question,
  riskScore,
}) => {
  
  const [expanded, setExpanded] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const nodeID = searchParams.get('id');

  const initialReference = React.useMemo(() => {
    return (
      question.answer_references.find(
        (ref: any) => ref.document_element_id === nodeID
      ) || question.answer_references[0]
    );
  }, [nodeID, question.answer_references]);

  React.useEffect(() => {
    const activeReference = question.answer_references.find(
      (ref: any) => ref.document_element_id === nodeID
    );
    if (activeReference) {
      setExpanded(true);
      const refNode = document.querySelector(`[data-id="${activeReference.ref_id}"]`)
      refNode?.scrollIntoView({ block: 'center', behavior: 'smooth' });

    } else {
      setExpanded(false);
    }
  }, [nodeID, question.answer_references]);

  // if(isNotAnswered(question.answer)){
  //   return <div></div>
  // }
  return (
    <div
      
      className={`${expanded ?  'bg-[#FCFCFF]': 'bg-[#FFF]'} rounded border-l-2 border-l-[#F1F3F8] border-[1px] border-[#F1F3F8] ${
        expanded
          ? ''
          : ''
      }`}
    >
      <div
        className="border-b-[1px] border-b-[#F1F3F8]  p-3 flex justify-between items-start cursor-pointer"
        onClick={() => {
          setExpanded(!expanded);
          if (question.answer_references[0]?.document_element_id && !expanded) {
            setSearchParams((params) => {
              params.set(
                'id',
                question.answer_references[0].document_element_id
              );
              return params;
            });
          }
        }}
      >
        <h3 className="text-xs font-semibold">{question.prompt_text}</h3>
        <span className="cursor-pointer">
          {expanded ? (
            <AiOutlineMinusCircle
              size={18}
              color='#748AF2'
              onClick={() => setExpanded(false)}
            />
          ) : (
            <AiOutlinePlusCircle size={18} onClick={() => setExpanded(true)} />
          )}
        </span>
      </div>
      {expanded && (
        <div className="p-3 flex flex-col gap-7">
          <div>
            <textarea className='w-full p-2' placeholder='Answer question' rows={8} />
          </div>
        
          <div className="flex justify-end p-2">
            <button className='bg-[#748AF2] text-white px-3 py-2 font-semibold rounded'>Save</button>
          </div>
          
        </div>
      )}
    </div>
  );
};

export default UnansweredItem;
