import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Dropdown from "../components/Single_Components/Dropdown";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Container from "../components/Single_Components/Container";
import Notification from "../components/Single_Components/Notification";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import Onboarding from "../components/Single_Components/Onboarding";
import ListJobs from "../components/Single_Components/ListJobs";
import Loading from "../components/Single_Components/Loading";
import { jobsFilters } from "../components/layout/MainLayout";
import {HiOutlineDocumentSearch} from "react-icons/hi";
import {Company} from "../services/v1/interfaces";
import {BsBookmark, BsBookmarkFill} from "react-icons/bs";

export default function ListContracts() {
  const [searchedjobs, setSearchedJobs, loading] = useOutletContext();
  const [search, setSearch] = useState("");
  const [listjobs, setListJobs] = useState(<Loading />);
  const [dropdownValue, setDropdown] = useState("");
  const [jobsFinal, setJobsFinal] = useState(searchedjobs);
  useEffect(() => {
    setJobsFinal(searchedjobs);
    
  }, [searchedjobs]);

  useEffect(() => {
    if(!loading) 
      setListJobs(<ListJobs jobs={jobsFinal} location={"my_documents"}/>)
    else
      setListJobs(<Loading />)
  }, [jobsFinal]);

  const dropDownChild = (value) => {
    let filterList = [...jobsFinal];
    if (value === 0)
    filterList
        .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        .reverse();
    else if (value === 1)
    filterList.sort((a, b) => a.source.localeCompare(b.source));
    else if (value === 2)
    filterList
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .reverse();
    else if (value === 3)
    filterList.sort((a, b) => a.job_type.localeCompare(b.job_type));
    setJobsFinal(filterList);
    setDropdown(value);
  };

  const searchfunc = (e) => {
    let updatedList = [...searchedjobs];
    updatedList = updatedList.filter((element) =>{
        return element.result[0]?.document_name?.toLowerCase()?.includes(e.target.value.toLowerCase())
    }
    );
    setJobsFinal(updatedList);
    setSearch(e.target.value);
  };

  const steps = [
    {
      target: "table",
      title: "Documents",
      content: "This is where your documents will show up",
      placementBeacon: "top",
    },
    {
      target: ".step2",
      title: "Status",
      content: "Here you can check the status of the file you uploaded",
    },
    {
      target: ".step3",
      title: "Inline Review",
      content: "Click here to go to the inline review page",
      placement: "top",
    },
    {
      target: ".step4",
      title: "Summary",
      content: "Click here to go to the summary page",
      placement: "top",
    },
  ];


  const show_onboarding = useFeatureFlagEnabled("[FE]-[Global]-show_onboarding")

  // test 525
  return (
    <Container>
      {show_onboarding && <Onboarding steps={steps} />}
      <div className="mt-11 mb-11 o">
        <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible  max-h-screen">
          <h1 className="text-2xl Inter font-black pb-7">
            <HiOutlineDocumentSearch className="inline-block mr-3" size={30}/>
            Your Documents
          </h1>
          <Notification />
          <div className="sm:flex-auto mb-7">
            {/* Search section */}
            <div className="flex flex-1 justify-between">
              <div className="w-1/2">
                <div className="relative text-[#6B7280] focus-within:text-gray-400">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="lookup"
                    className="block w-4/5 rounded-md border border-transparent bg-[#F9FBFF] py-2 pl-10 pr-3 leading-5 text-black placeholder-[#6B7280] focus:bg-white fade-in duration-100 focus:outline-none sm:text-sm"
                    placeholder="Search documents"
                    type="search"
                    value={search}
                    onChange={searchfunc}
                  />
                </div>
              </div>
              <div>
                <NavLink
                  to={`/home/upload`}
                  className="">
                  <button className="border-[#748AF2] text-l bg-transparent text-[#748AF2] shadow border-2 rounded-lg p-2 pr-4 pl-4 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer"
                          type="submit">
                    <AiOutlinePlusCircle className="mr-3" size={23} />
                    New review
                  </button>
                </NavLink>
              </div>
            </div>
            {/*  */}
          </div>
          <div className="sm:flex justify-between sm:items-center">
            <div className="">
              <label className="Inter font-bold text-lg">
                Documents Reviewed
              </label>
              <h3 className="Inter text-xs text-[#6B7280]">
                Your latest document reviews
              </h3>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Dropdown
                dropDownChild={dropDownChild}
                filters={jobsFilters}
                sideText={"Sort by:"} defaultFilter={0}
              />
            </div>
          </div>
          <div className="-mx-4 mt-8 rounded-xl sm:-mx-6 md:mx-0 overflow-auto overflow-y-hidden max-h-screen">
            {listjobs}
            
          </div>
        </div>
      </div>
    </Container>
  );
}
