import React from "react";
import Container from "../components/Single_Components/Container";
import Pricing from "../components/Single_Components/pricing";
import Breadcrumb from "../components/Single_Components/Breadcrumbs";

const PricingPage = () => {

    return (
        <>
            <div className='m-8 block justify-between items-center'>
                <Breadcrumb pathParts={[{url: "/home/settings", name: "Settings"}, {url: "/home/pricing", name: "Pricing"}]}/>
            </div>
            <Container>
                <div className='m-8 block justify-between items-center'>
                    <Pricing></Pricing>
                </div>
            </Container>
        </>
    )

}

export default PricingPage;