import Container from "../../components/Single_Components/Container";
import Notification from "../../components/Single_Components/Notification";
import Loading from "../../components/Single_Components/Loading";
import React, {useEffect, useState} from "react";
import {BiBuildings} from "react-icons/bi";
import {useNavigate, useParams} from "react-router-dom";
import {useGetCompanyByIdQuery} from "../../services/v1/getCompanyById";
import {subscribeCompany, subscribeCompanyDocument} from "../../services/v1/subscribeCompanyDocuments";
import {
    Company,
    CompanyCertificate,
    CompanyDocument,
    CompanyDocuments,
    PaginatedCompanyResult
} from "../../services/v1/interfaces";
import {
    useGetCompanyCertificationsByIdQuery
} from "../../services/v1/getCompanyCertificationsById";
import {BsBookmarkFill, BsBookmark} from "react-icons/bs"
import {getFlagEmoji, TOP_COUNTRIES_CODES} from "./countries_utils";
import {openDocumentShareLink} from "./utils";
import {unsubscribeCompany, unsubscribeCompanyDocument} from "../../services/v1/unsubscribeCompanyDocuments";
import {useGetCompanyAppsByIdQuery} from "../../services/v1/getCompanyAppsById";
import Pagination from "../../components/Single_Components/Pagination";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";

export default function CompanyDetails() {

    const { companyId: companyId } = useParams();
    const navigate = useNavigate();

    const [companyDocuments, setCompanyDocuments] = useState<CompanyDocument[]>([])

    const { data: company, isLoading: isLoadingCompanyDetails } =
        useGetCompanyByIdQuery(companyId || '',{
            refetchOnWindowFocus: false,
        });

    const { data: companyCertificates, isLoading: isLoadingCertificates } =
        useGetCompanyCertificationsByIdQuery(companyId || '', {
            refetchOnWindowFocus: false,
        });

    useEffect(() => {
        setCompanyDocuments(company?.documents ?? [])
    },[isLoadingCompanyDetails])

    const localSubscribeCompanyDocuments = async (companyId: string) => {
        const _companySubscriptionResult = await subscribeCompany(companyId);
    }

    const localSubscribeCompanyDocument = async (companyDocumentId: string) => {
        const _documentSubscriptionResult = await subscribeCompanyDocument(companyDocumentId);
    }

    const localUnSubscribeCompanyDocuments = async (companyId: string) => {
        const _companyUnsubscriptionResult = await unsubscribeCompany(companyId);
    }

    const localUnSubscribeCompanyDocument = async (companyDocumentId: string) => {
        const _documentUnsubscriptionResult = await unsubscribeCompanyDocument(companyDocumentId);
    }

    function NoContent({text}: {text: string}){
        return <div className="bg-pink-500 text-white shadow rounded p-10 pr-15 pl-5 hover:text-gray-200 hover:bg-blue hover:bg-opacity-50 fade-in duration-300">
            <p className="text-center">No {text} to show</p>
        </div>
    }

    async function handleDocumentUnsubscribe(documents: CompanyDocument[], document: CompanyDocument) {
        document.is_monitoring = false;
        setCompanyDocuments([...documents])
        await localUnSubscribeCompanyDocument(document.id)
    }

    async function handleDocumentSubscribe(documents: CompanyDocument[], document: CompanyDocument) {
        document.is_monitoring = true;
        setCompanyDocuments([...documents])
        await localSubscribeCompanyDocument(document.id)
    }

    async function handleCompanyUnsubscribe(documents: CompanyDocument[], company: Company) {
        company.is_monitoring = false
        for (let document of documents){
            document.is_monitoring = false
        }
        setCompanyDocuments([...documents])
        await localUnSubscribeCompanyDocuments(company.id)
    }

    async function handleCompanySubscribe(documents: CompanyDocument[], company: Company) {
        company.is_monitoring = true
        for (let document of documents){
            document.is_monitoring = true
        }
        setCompanyDocuments([...documents])
        await localSubscribeCompanyDocuments(company.id)
    }

    function DocumentMonitorAction({document}: {document: CompanyDocument}){
        return !document?.is_monitoring ? <div
            className="p-2 px-3 rounded-lg bg-[#748AF2] hover:bg-opacity-20 bg-opacity-20 fade-in duration-300 cursor-pointer"
            onClick={() => handleDocumentSubscribe(companyDocuments, document)}
        >
            <BsBookmark color="#748AF2" size={20} style={{strokeWidth: "1"}}/>
        </div> : <div
            className="p-2 px-3 rounded-lg bg-[#748AF2] hover:bg-opacity-20 bg-opacity-20 fade-in duration-300 cursor-pointer"
            onClick={() => handleDocumentUnsubscribe(companyDocuments, document)}
        >
            <BsBookmarkFill color="#748AF2"  size={20}/>
        </div>
    }

    function CompactCompanyMonitorAction({company}: {company: CompanyDocuments}){
        return <></>
        /*return !company?.is ? <div
            className="p-2 px-3 rounded-lg bg-[#748AF2] hover:bg-opacity-20 bg-opacity-20 fade-in duration-300 cursor-pointer"
            onClick={() => handleCompanySubscribe(company?.documents, company)}
        >
            <BsBookmark color="#748AF2" size={20} style={{strokeWidth: "1"}}/>
        </div> : <div
            className="p-2 px-3 rounded-lg bg-[#748AF2] hover:bg-opacity-20 bg-opacity-20 fade-in duration-300 cursor-pointer"
            onClick={() => handleCompanyUnsubscribe(company?.documents, company)}
        >
            <BsBookmarkFill color="#748AF2"  size={20}/>
        </div>
        */
    }

    function CompanyMonitoringAction({company}: {company: Company | undefined}){
        return company ?
            !company?.is_monitoring ? <button className="border-[#748AF2] bg-transparent text-[#748AF2] shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-50 fade-in duration-300 cursor-pointer"
                                                                 onClick={() => handleCompanySubscribe(companyDocuments, company)}>
             Follow <BsBookmark color="#748AF2" size={30} className="ml-5"/>
        </button> : <button
            className="border-[#748AF2] text-white bg-[#748AF2] shadow  border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-opacity-50 fade-in duration-300 cursor-pointer"
            onClick={() => handleCompanyUnsubscribe(companyDocuments, company)}
        >
                Following <BsBookmarkFill color="white" size={30} className="ml-5"/>
        </button> : <button className="bg-[#748AF2] text-white shadow rounded p-3 pr-5 pl-5 flex items-center hover:text-gray-200 hover:bg-blue hover:bg-opacity-50 fade-in duration-300 "></button>
    }

    function CompanyDocumentsTable({companyDocuments}: {companyDocuments: Array<CompanyDocument> | undefined}){
        return companyDocuments ?  <Container>
            <div className="w-full p-10">
            <h2 className="font-bold text-2xl mb-6">SaaS Documents</h2>
                <div className="border rounded-lg overflow-hidden">
            <table className="w-full divide-y divide-gray-300 rounded-lg table-auto">
                <thead className="Inter text-xs font-bold text-[#6B7280] bg-[#F9FAFB]">
                <tr>
                    <th scope="col" className="px-3 py-3.5 w-[50px]">
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start">
                        Document Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start">
                        Updated
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start">
                        Document
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y text-start divide-gray-200 bg-white">
                {companyDocuments && companyDocuments.map((document: CompanyDocument) => {
                    return (<tr className="even:bg-[#F9FAFB]" key={document.id}>
                        <td className="px-3 py-4 lg:table-cell">
                            <DocumentMonitorAction document={document}/>
                        </td>
                        <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis capitalize">
                            {document.document_type}
                        </td>
                        <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis capitalize">
                            {document.last_analysis_date.slice(0,10) || "-"}
                        </td>
                        <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis capitalize">
                            {openDocumentShareLink(navigate, document)}
                        </td>
                    </tr>)
                })}
                </tbody>
            </table></div></div></Container> : <NoContent text="Documents"/>
    }

    function CompanyAppsTable(){

        const [currentPage, setCurrentPage] = useState(1);
        const [companySearchText, setCompanySearchText] = useState<string>("");
        const [lastApps, setLastApps] = useState<PaginatedCompanyResult>();

        const { data: companyApps, isLoading: isLoadingApps } =
            useGetCompanyAppsByIdQuery(companyId || '', currentPage, companySearchText, {
                refetchOnWindowFocus: false,
            });

        useEffect(() => {
            if (!isLoadingApps) {
                setLastApps(companyApps)
            }
        }, [isLoadingApps]);

        const searchApp = (e: any) => {
            setCompanySearchText(e.target.value);
        };


        const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
        const forward = (pageNumber: number) => {
            setCurrentPage(pageNumber + 1)
        }
        const backward = (pageNumber: number) => {
            setCurrentPage(pageNumber - 1)
        }

        return <Container>
                <div className="w-full p-10">
                    <h2 className="font-bold mb-8 text-2xl">Marketplace Apps</h2>
                    <div className="flex justify-between mb-8 w-full">
                        <div className="relative text-[#6B7280] focus-within:text-gray-400 w-4/5">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </div>
                            <input
                                id="search"
                                name="lookup"
                                className="block w-1/3 rounded-md border border-transparent bg-[#E8EAEE] py-2 pl-10 pr-3 leading-5 text-black placeholder-[#6B7280] focus:bg-[#F8FAFE] fade-in duration-100 focus:outline-none sm:text-sm"
                                placeholder="Search Apps"
                                type="search"
                                value={companySearchText}
                                onChange={searchApp}
                            />
                        </div>
                        <Pagination
                            postsPerPage={10}
                            totalPosts={companyApps?.total_pages || 10}
                            paginate={paginate}
                            forward={forward}
                            backward={backward}
                            currentPage={currentPage}
                        />
                    </div>
                    <div className="border rounded-lg overflow-hidden">
                        <table className="w-full divide-y divide-gray-300 rounded-lg">
            <thead className="Inter w-full text-center text-xs font-bold text-[#6B7280] bg-[#F9FAFB] ">
            <tr>
                <td className="px-3 py-4 lg:table-cell">
                </td>
                <th scope="col" className="px-3 py-3.5 w-1/12">
                    App Name
                </th>
                <th scope="col" className="px-3 py-3.5 w-1/4">
                    Jurisdiction
                </th>
                <th scope="col" className="px-3 py-3.5 w-1/12">
                    T&C
                </th>
                <th scope="col" className="px-3 py-3.5 w-1/12">
                    Privacy Policy
                </th>
                <th scope="col" className="px-3 py-3.5 w-1/12">
                    DPA
                </th>

            </tr>
            </thead>
            <tbody className="divide-y text-center divide-gray-200 bg-white">
            {(companyApps || lastApps)?.items.map((app: CompanyDocuments) => {
                const appCountryName: string = app.company_metadata?.country?.name
                const appCountryCode = TOP_COUNTRIES_CODES[appCountryName]
                return (<tr className="even:bg-[#F9FAFB]" key={app.id}>
                    <CompactCompanyMonitorAction company={app}/>
                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis">
                        <div className="flex items-center">
                            <div className="flex flex-col">
                                {app.company_logo.includes("clearbit") ? <img src={app.company_logo} alt="Company logo" width="30px"
                                                                                  className=""/> :
                                    <BiBuildings color="gray" size={30}/>
                                }
                            </div>
                            <div className="ml-4">
                                                <span className="py-1 px-3 cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150 text-blue"
                                                      onClick={()=>navigate(`/home/companies/${app.id}/details`)}
                                                >{app.company_name}</span>
                            </div>
                        </div>
                    </td>
                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis capitalize">
                        {appCountryCode ? getFlagEmoji(appCountryCode): <>-</>} {appCountryName}
                    </td>
                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis capitalize">
                        {
                            app.documents["T&C"] ?
                        openDocumentShareLink(navigate, app.documents["T&C"])
                        : <>-</>
                        }
                    </td>
                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                        {
                            app.documents["Privacy Policy"] ?
                                openDocumentShareLink(navigate, app.documents["Privacy Policy"])
                                : <>-</>
                        }
                    </td>
                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                        {
                            app.documents["DPA"] ?
                                openDocumentShareLink(navigate, app.documents["DPA"])
                                : <>-</>
                        }
                    </td>
                </tr>)
            })}
            </tbody>
        </table></div></div></Container>
    }

    function CertificatesTable({certificates}: {certificates: CompanyCertificate[] | undefined}){
        return certificates ? <table className="w-full divide-y divide-gray-300">
            <thead className="Inter w-full text-center text-xs font-bold text-[#6B7280] bg-[#F9FAFB]">
            <tr>
                <th scope="col" className="px-3 py-3.5 w-1/12">
                    Certificates
                </th>
            </tr>
            </thead>
            <tbody className="divide-y text-center divide-gray-200 bg-white">
            {certificates && certificates?.map((certificate: CompanyCertificate) => {
                return (
                    <tr className="even:bg-[#F9FAFB]" key={certificate.certificate_id}>
                        <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden overflow-ellipsis capitalize">
                            {certificate.name}
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table> : <NoContent text="Certificates"/>
    }

    function CertificatesList({certificates}: {certificates: CompanyCertificate[] | undefined}){
        return certificates ?
            <span>{certificates && certificates?.map(
                (c: CompanyCertificate, idx: number) => <a
                    href={c.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer hover:underline hover:underline-offset-4 transition-underline duration-150 pr-1"
                >
                    {c.name}{idx === certificates.length-1 ? "" : ","}
                </a>
            )}</span> : <span>No Certificates</span>
    }

    function CompanyDetailsCard({company}: { company: Company | undefined}) {
        const companyCountryName: string = company?.metadata?.country?.name
        const companyCountryCode = TOP_COUNTRIES_CODES[companyCountryName]
        return <div>
            <div className="flex justify-between mx-8">
                <div className="flex items-center">
                {company?.logo_url.includes("clearbit")
                    ? <img src={company.logo_url} alt="Company logo" width="30px" className=""/>
                    : <BiBuildings color="gray" size={30}/>}
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ml-4">
                    {company?.name}
                </h2>
                </div>
                <CompanyMonitoringAction company={company}/>
            </div>
            <br/>
            <Container>
                <div className="w-full p-10">
                <h2 className="font-bold text-2xl mb-6">Summary</h2>
                <div className="flex justify-between w-full">
                    <p className="mt-4 text-gray-500 w-1/2 text-justify">{company?.metadata?.description}</p>


                    <dl className="mt-5 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8 w-1/2 ml-10">
                        <div className="border-t border-gray-200 pt-4">
                            <dt className="font-medium text-gray-900">Employees</dt>
                            <dd className="mt-2 text-sm text-gray-500">{company?.metadata?.totalEmployees || '-'}</dd>
                        </div>
                        <div className="border-t border-gray-200 pt-4">
                            <dt className="font-medium text-gray-900">Revenue</dt>
                            <dd className="mt-2 text-sm text-gray-500">{company?.metadata?.revenue || '-'}</dd>
                        </div>
                        <div className="border-t border-gray-200 pt-4">
                            <dt className="font-medium text-gray-900">Year Founded</dt>
                            <dd className="mt-2 text-sm text-gray-500">{company?.metadata?.yearFounded || '-'}</dd>
                        </div>
                        <div className="border-t border-gray-200 pt-4">
                            <dt className="font-medium text-gray-900">Jurisdiction</dt>
                            <dd className="mt-2 text-sm text-gray-500">
                                {companyCountryCode ? getFlagEmoji(companyCountryCode): <>-</>} {companyCountryName}
                            </dd>
                        </div>
                    </dl>
                    <dl className="mt-5 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8 ml-5">
                        <div className="border-t border-gray-200 pt-4">
                            <dt className="font-medium text-gray-900">Certificates</dt>
                            <dd className="mt-2 text-sm text-gray-500">{<CertificatesList certificates={companyCertificates}/>}</dd>
                        </div>
                    </dl>
                </div>
                </div>
            </Container>
        </div>
    }

    return (
            <div className="mt-11 mb-11">
                {
                    isLoadingCompanyDetails && isLoadingCertificates
                    ?
                    <Loading style={{}}/>
                    :
                    <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible  max-h-screen">
                    <Notification children={undefined} width={undefined} />
                    <CompanyDetailsCard company={company}/>

                    <CompanyDocumentsTable companyDocuments={companyDocuments}/>
                        {
                            <CompanyAppsTable/>
                        }
                    </div>
                }
            </div>
    );
}
