import axios from "axios";
import DriveUpload from "../Projects/Create/DriveUpload";
import Container from "../../components/Single_Components/Container";
import { useImmer } from "use-immer";
import Loading from "../../components/Single_Components/Loading";
import { notifications } from "../../components/layout/MainLayout";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import React, { useState } from "react";
import Notification from "../../components/Single_Components/Notification";
import {BiHide} from "react-icons/bi";

export default function Redaction() {

    const [notification_component, setNotification_component] = useState(null);

    const [totalDocs, setTotalDocs] = useImmer([]);
    const [loading, setLoading] = useImmer(false);
    function downloadDocument(filename, bytes, media_type) {
      let blob = new Blob([bytes], {type: media_type});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    };

    async function runJob(filename, file_binary) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', file_binary, filename)
        formData.append('pii_removal', "true")
        const requestOptions = {
          headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: localStorage.getItem("token")
            },
            responseType: "blob"
        } ;
        // Perform the upload using axios or fetch
        axios.post(process.env.REACT_APP_NLP_API_BASE_URL + "/pre_process_file" , formData, requestOptions).then((result) => {
            downloadDocument(`redacted_${filename}`, result.data, result.headers["content-type"]);
            setLoading(false)
            
            notifications.push({
              icon: <AiOutlineCheckCircle size={25} color="green" />,
              upper_text: "Document anonymized successfully!",
              lower_text: "Your document has been downloaded.",
            });
            setNotification_component(() => <Notification />);
          }).catch((error) => {
            console.log(error);
            setLoading(false)
            notifications.push({
              icon: <AiOutlineCloseCircle size={25} color="red" />,
              upper_text: "Unable to anonymize your document!",
              lower_text: error.message,
            });
            setNotification_component(() => <Notification />);
          })
      }

    return <><Container>
        <div className="mt-11 mb-11">
          {notification_component}
          <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible  max-h-screen">
            <h1 className="text-2xl Inter font-black pb-7">
                <BiHide className="inline-block mr-3" size={30}/>
                Redaction
            </h1>
            <DriveUpload totalDocs={[]} runJob={runJob} setTotalDocs={setTotalDocs} loading={loading}/>
            
          </div>
        </div>
        </Container>
    </>
}
