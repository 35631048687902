import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Pagination from "../../components/Single_Components/Pagination";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {AiOutlineFileSearch} from "react-icons/ai";
import Container from "../../components/Single_Components/Container";
import Notification from "../../components/Single_Components/Notification";
import Onboarding from "../../components/Single_Components/Onboarding";
import Divider from "../../components/Single_Components/Divider";
import SimpleFormInputBox from "../../components/Single_Components/SimpleFormInputbox";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './DaterangePicker.css';
import Loading from "../../components/Single_Components/Loading";
import customClassNames from "classnames";
import {MdOutlineScreenSearchDesktop} from "react-icons/md";

export default function SearchDocuments() {

    const initData = []

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [postsPerPage] = useState(10);
    const [documentsFinal, setDocumentsFinal] = useState(initData);

    const [language, setLanguage] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [documentType, setDocumentType] = useState("");
    // dates[0] -> start_date | dates[1] -> start_date
    // fyi: dates is undefined if date range is cleared
    const [dates, onChangeDates] = useState([]);
    const [isSearching, setIsSearching] = useState(false);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const forward = (pageNumber) => setCurrentPage(pageNumber + 1);
    const backward = (pageNumber) => setCurrentPage(pageNumber - 1);

    const [pageDocuments, setPageDocuments] = useState(documentsFinal?.slice(indexOfFirstPost, indexOfLastPost));

    const changeSearch = (e) => {
        setSearch(e.target.value);
    };

    function convert_quality_score_2_color(quality){
        if (quality === "good")
            return "green"
        else if (quality === "medium")
            return "yellow"
        else if (quality === "bad")
            return "red"
        else
            return "red"

    }

    async function getDocuments(){
        try{
            setIsSearching(true)
            let local_dates = dates
            if (dates === undefined) {
                local_dates = ["", ""]
            } else {
                local_dates = local_dates?.map((date) => date.toISOString().slice(0,10))
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                    "Connection": "keep-alive",
                },
                body: JSON.stringify({
                    semantic_search_text: search,
                    project_id: -1,
                    language: language,
                    start_date: local_dates[0],
                    end_date: local_dates[1],
                    document_type: documentType,
                    document_name: documentName,
                    top_k: 5
                }),
            };
            console.log("requestOptions:", requestOptions)
            const documents_promise = await fetch(
                process.env.REACT_APP_NLP_API_BASE_URL+"/query_documents",
                requestOptions,
                {}
            )
            let documents_dict = await documents_promise.json()
            let documents = documents_dict?.retrieved_documents
            setIsSearching(false)
            setDocumentsFinal(documents)
            setPageDocuments(documents.slice(indexOfFirstPost, indexOfLastPost));
        } catch (e){
            console.log(e.message)
            console.log("error")
        }
    }

    useEffect(() => {
        if(!isSearching)
            setPageDocuments(documentsFinal?.slice(indexOfFirstPost, indexOfLastPost))
        else
            setPageDocuments(documentsFinal)
    }, [documentsFinal, indexOfFirstPost, indexOfLastPost]);

    const show_onboarding = false
    const steps = []

    return (
        <Container>
            {show_onboarding && <Onboarding steps={steps} />}
            <div className="mt-11 mb-11">
                <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible max-h-screen">
                    <h1 className="text-2xl Inter font-black pb-7">
                        <MdOutlineScreenSearchDesktop className="inline-block mr-3" size={30}/>
                        Documents Search
                    </h1>
                    <Notification />
                    <div className="sm:flex-auto mb-7">
                        {/* Search section */}
                        <div className="">
                            <div className="w-4/4">
                                <label htmlFor="range"
                                       className="block text-sm font-medium leading-6 text-gray-900 mb-2">Query Text</label>

                                <div className="relative text-[#6B7280] focus-within:text-gray-400">
                                    <div className="pointer-events-none absolute py-2 inset-y-0 left-0 flex pl-3">
                                        <MagnifyingGlassIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <textarea
                                        id="search"
                                        name="search"
                                        className="block w-full rounded-md border border-transparent bg-[#F9FBFF] py-2 pl-10 pr-3 leading-5 text-black placeholder-[#6B7280] focus:bg-[#F8FAFF] fade-in duration-100 focus:outline-none sm:text-sm"
                                        placeholder="eg. Financial Cap over 1 million euros"
                                        type="search"
                                        value={search}
                                        onChange={changeSearch}
                                        rows="4"
                                    />
                                    <br/>
                                    <h2 className="Inter text-xs font-black">
                                        Filters
                                    </h2>
                                    <div className="grid grid-cols-3 gap-4">
                                        <SimpleFormInputBox idText={"documentName"} labelText={"Document Name"} setText={setDocumentName} placeholder={"eg. Outsystems Terms & Conditions"}/>
                                        <SimpleFormInputBox idText={"documentType"} labelText={"Document Type"} setText={setDocumentType} placeholder={"eg. NDA"}/>
                                        <SimpleFormInputBox idText={"language"} labelText={"Language"} setText={setLanguage} placeholder={"eg. English"}/>
                                        <div>
                                            <label htmlFor="range"
                                                   className="block text-sm font-medium leading-6 text-gray-900">Temporal Period</label>
                                            <div className="mt-2">
                                                <DateRangePicker
                                                    onChange={onChangeDates}
                                                    value={dates}
                                                    className="rounded-md border-0"
                                                    rangeDivider={""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="grid place-items-center">
                                <div className="text-indigo-600 text-md hover:text-indigo-900">
                                    <button
                                        className="text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer"
                                        onClick={getDocuments}
                                        type="submit"
                                        disabled={isSearching}
                                    >
                                        <AiOutlineFileSearch className="mr-3" size={20} />
                                        Search Documents
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                    </div>
                    <Divider text=""/>
                    <br/>
                    <div className="sm:flex justify-between sm:items-center">
                        <div className="">
                            <label className="Inter font-bold text-lg">
                                Your Documents
                            </label>
                            <h3 className="Inter text-xs text-[#6B7280]">
                                Retrieved Documents
                            </h3>
                        </div>
                    </div>
                    {isSearching ? <Loading style={{}}/> : <div className="-mx-4 mt-8 rounded-xl sm:-mx-6 md:mx-0 overflow-auto overflow-y-hidden max-h-screen">
                        <table className="min-w-1/2 w-full divide-y divide-gray-300 table-fixed table-auto">
                            <thead className="Inter text-left text-xs font-bold text-[#6B7280] bg-[#F9FAFB] ">
                            <tr>
                                <th scope="col" className="px-3 py-3.5">
                                    Document Name
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5">
                                    Document Type
                                </th>
                                <th scope="col" className="px-3 py-3.5">
                                    Language
                                </th>
                                <th scope="col" className="px-3 py-3.5">
                                    Date
                                </th>
                                <th scope="col">References</th>

                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {pageDocuments && pageDocuments?.map((document) => (
                                <tr className="even:bg-[#F9FAFB]" key={document.id}>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                                        {document.document_name}
                                    </td>
                                    <td className="Inter px-3 py-4 text-sm text-black-600 sm:table-cell whitespace-nowrap overflow-x-hidden">
                                        {document.document_type}
                                    </td>
                                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                                        {document.language}
                                    </td>
                                    <td className="Inter px-3 py-4 text-sm text-black-600 sm:table-cell whitespace-nowrap overflow-x-hidden">
                                        {document.date}
                                    </td>
                                    <td className="Inter px-3 py-4 text-sm text-black-600 sm:table-cell whitespace-nowrap overflow-x-hidden">
                                        {
                                            document?.passages?.map((passage, index) => {
                                                const quality_color = convert_quality_score_2_color(passage?.quality)
                                                const passage_id = passage?.id
                                                const activateClassNames = customClassNames(
                                                    "w-[120px]",
                                                    "min-w-[120px]",
                                                    "py-1",
                                                    "px-3",
                                                    "rounded-full",
                                                    "text-right",
                                                    "Inter",
                                                    "text-xs",
                                                    "hover:bg-opacity-50",
                                                    "fade-in",
                                                    "duration-300",
                                                    "focus:outline-none",
                                                    {
                                                        "bg-[#B3E7DB]/[.62]": quality_color === "green",
                                                        "bg-[#F9C107]/[.62]": quality_color === "yellow",
                                                        "bg-[#E7B3CC]/[.62]": quality_color === "red"
                                                    },
                                                    {
                                                        "hover:bg-[#B3E7DB]/[0.2]": quality_color === "green",
                                                        "hover:bg-[#F9C107]/[0.2]": quality_color === "yellow",
                                                        "hover:bg-[#E7B3CC]/[0.2]": quality_color === "red"
                                                    },
                                                    {
                                                        "text-[#217541]":  quality_color === "green",
                                                        "text-[#b39917]":  quality_color === "yellow",
                                                        "text-[#a83248]":  quality_color === "red"
                                                    }
                                                )
                                                return (<NavLink
                                                    key={passage_id}
                                                    to={`/contract/${document.document_id}/inline_review?tag=${passage_id}`}
                                                    target="_blank" rel="noopener noreferrer"
                                                    className={`hover:text-${quality_color} ml-1`}>
                                                  <span
                                                      className={activateClassNames}>
                                                  r{index}
                                                  </span>
                                                </NavLink>)
                                            })
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
                <div>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={documentsFinal?.length}
                        paginate={paginate}
                        forward={forward}
                        backward={backward}
                        currentPage={currentPage}
                    />
                </div>
            </div>
        </Container>
    );
}
