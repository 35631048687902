import Dropdown from "../../../components/Single_Components/Dropdown";
import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Container from "../../../components/Single_Components/Container";
import Notification from "../../../components/Single_Components/Notification";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import Onboarding from "../../../components/Single_Components/Onboarding";
import ListJobs from "../../../components/Single_Components/ListJobs";
import Loading from "../../../components/Single_Components/Loading";
import { FaChevronLeft } from "react-icons/fa";
import { IoDownload } from "react-icons/io5";
import { jobsFilters } from "../../../components/layout/MainLayout";
export default function Details() {

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [postsPerPage] = useState(10);

  const [dropdownValue, setDropdown] = useState("");
  const [jobsFinal, setJobsFinal] = useState([]);
  const {id} = useParams();
  const [details, setDetails] = useState({});
  const [report, setReport] = useState({});
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const [listjobs, setListJobs] = useState("");
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    async function getProjects(){
      try{
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', "Authorization": localStorage.getItem("token") },
      };
      const response = await window.fetch(process.env.REACT_APP_DOMAIN+"/api/v1/jobs/?project="+id, requestOptions)
      const jobs = await response.json();
      setLoading(false)
      setJobsFinal(jobs.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).reverse());
      setListJobs(<ListJobs jobs={[]}/>)
      return jobs
      } catch {
        console.log("error")
      }
    }
    
    async function getDetails(){
      try{
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', "Authorization": localStorage.getItem("token") },
      };
      const response = await window.fetch(process.env.REACT_APP_DOMAIN+"/api/v1/projects/"+id+"/", requestOptions)
      const details = await response.json();
      setDetails(details);
      return details
      } catch {
        console.log("error")
      }
    }
    async function getReport(){
      try{
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("token"),
            "Connection": "keep-alive",
          },
          body: JSON.stringify({
            project_id: id
          }),
        };
      const response = await fetch(process.env.REACT_APP_NLP_API_BASE_URL+"/generate_report", requestOptions)
      const report = await response.json();
      setReport(report);
      return report
      } catch (e){
        console.log(e)
      }
    }
    getProjects()
    getDetails()
    getReport()
  }, []);

  useEffect(() => {
    if(!loading) 
      setListJobs(<ListJobs jobs={jobsFinal} location={"projects"}/>)
    else
      setListJobs(<Loading />)
  }, [jobsFinal]);

  const dropDownChild = (value) => {
    if (value === 0)
      jobsFinal
        .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        .reverse();
    else if (value === 1)
      jobsFinal.sort((a, b) => a.source.localeCompare(b.source));
    else if (value === 2)
      jobsFinal
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .reverse();
    else if (value === 3)
      jobsFinal.sort((a, b) => a.job_type.localeCompare(b.job_type));
    setJobsFinal(jobsFinal);
    setListJobs(<ListJobs jobs={jobsFinal} />)
    setDropdown(value);
  };
  const searchfunc = (e) => {
    let updatedList = [...jobsFinal];
    updatedList = updatedList.filter((element) =>
      // if (!element.url) return ""
      element.result[0].document_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    
    setJobsFinal(updatedList);
    setSearch(e.target.value);
  };


  const steps = [
    {
      target: "table",
      title: "Projects",
      content: "This is where your projects will show up",
      placementBeacon: "top",
    },
    {
      target: ".step2",
      title: "Status",
      content: "Here you can check the status of the file you uploaded",
    },
    {
      target: ".step3",
      title: "Inline Review",
      content: "Click here to go to the inline review page",
      placement: "top",
    },
    {
      target: ".step4",
      title: "Summary",
      content: "Click here to go to the summary page",
      placement: "top",
    },
  ];


  const show_onboarding = useFeatureFlagEnabled("[FE]-[Global]-show_onboarding")
  const show_admins_job_details = useFeatureFlagEnabled("[FE]-[ListContracts]-show_admins_job_details")
  // test 525

  // const [answeredArray, setAnsweredArray] = useImmer([]);
  // const fetchAnswers = async (id) => {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": localStorage.getItem("token"),
  //       "Connection": "keep-alive",
  //     }
  //   }
  //   const res = await fetch(process.env.REACT_APP_NLP_API_BASE_URL+"/document/"+id+"/answered_prompts",requestOptions);
  //   return res.json();
  // };
  // for (let job of jobsFinal) {
  //   const {data} = useQuery("data", fetchAnswers(job.id));
  //   console.log(data)
  //   answeredArray.push(data);
  // }


  if(!details.name) return <Loading />
  return (
    <Container>
      {show_onboarding && <Onboarding steps={steps} />}
      <div className="mt-5 mb-11 o">

        <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible  max-h-screen">
        <NavLink to={`/home/projects/`}>
            <button
              className=" mb-10 bg-white py-1.5 flex items-center text-[#748AF2] font-[500] "
              type="submit">
              <FaChevronLeft size={20}/><span className="ml-5">Back to projects</span>
            </button>
          </NavLink>
          <h1 className="text-2xl Inter font-black pb-7">{details.name}</h1>
          <Notification />
          <div className="sm:flex-auto mb-7">
            {/* Search section */}
            <div className="flex flex-1 justify-between">
              <a href={report?.url} className="border-[#748AF2] text-l bg-transparent text-[#748AF2] shadow border-2 rounded-lg p-2 pr-4 pl-4 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer">
                  <IoDownload className="mr-3" size={20} />
                  Download Report
              </a>
              
                <NavLink to={{pathname:`/home/projects/` +id+`/upload`}} state= {{project_name: details.name}} className="text-indigo-600 text-md hover:text-indigo-900">
                  <button
                    className="text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer"
                    type="submit">
                    <AiOutlinePlusCircle className="mr-3" size={20} />
                    Upload Documents
                  </button>
                </NavLink>
              
            </div>
            {/*  */}
          </div>
          <div className="sm:flex justify-between sm:items-center">
            <div className="">
              <label className="Inter font-bold text-lg">
                Projects
              </label>
              <h3 className="Inter text-xs text-[#6B7280]">
                Your latest projects
              </h3>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Dropdown
                dropDownChild={dropDownChild}
                filters={jobsFilters}
                sideText={"Sort by:"} defaultFilter={0}
              />
            </div>
          </div>
          <div className="-mx-4 mt-8 rounded-xl sm:-mx-6 md:mx-0 overflow-auto overflow-y-hidden max-h-screen">
            {jobsFinal.length > 0 ? (<ListJobs jobs={jobsFinal} location={"projects"} project_id={id} project_name={details.name} />) : <>
            <span>No documents to show</span>
            </>}
          
          </div>
        </div>
      </div>
    </Container>
  );
}
