import React, { useState, useRef, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { BsQuestionCircle} from "react-icons/bs"
import { IoWarning} from "react-icons/io5"
import { Replace_idk_answer } from "../../../assets/GeneralFunctions";

import {NavLink} from "react-router-dom"
import CommentAnswerReferences from "../../../components/Single_Components/CommentAnswerReferences";

export default function Checklist({props, playbook_id, id}) {
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
    const tabsRef = useRef([]);

    let tabsData = []

    let risks = props?.summary?.yes_no_risks?.sort((a,b) => a.risk_score - b.risk_score) ?? []
    let alert_risks = risks?.filter((a) => (a.risk_score > 1)) ?? []
    let unknown_risks = risks?.filter((a) => (a.risk_score === 1)) ?? []
    let no_risks = risks?.filter((a) => (a.risk_score === 0)) ?? []

    function retrieveAnswerReferencesFromRisk(risk) {
        const doesHaveFollowUpAndRefsShouldBeVisible = risk?.follow_ups[0]?.are_refs_visible
        const doesRiskShouldBeVisible = risk?.are_refs_visible
        if (doesHaveFollowUpAndRefsShouldBeVisible){
            return risk?.follow_ups[0]?.answer_references
        }
        else if (doesRiskShouldBeVisible){
            return risk?.answer_references
        }
        return []
    }
    
    if(playbook_id !== 148)
        tabsData = [
            {
                value: alert_risks.length,
                label: "Issues Found",
                content: (<div className="rounded-xl overflow-hidden ">
                    <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                        <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                            <tr>
                                <th scope="col" className="px-6 py-5 w-2/12">
                                    Question
                                </th>
                                <th scope="col" className="px-6 w-2/12">
                                    Answer
                                </th>
                                <th scope="col" className="px-6 w-7/12">
                                    Follow-Ups
                                </th>
                                <th scope="col" className="px-6 w-7/12 text-center">
                                    References
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {alert_risks.map((risk) => (
                            <tr key={risk.origin_question ? risk.origin_question: risk.prompt_text} className="even:bg-[#F9FAFB] border-b ">
                                <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 ">
                                    <div className="block">
                                        <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer(risk.tag_name)}</div>
                                        <div className="w-full font-medium">{Replace_idk_answer(risk.origin_question ? risk.origin_question: risk.prompt_text)}</div>
                                    </div>
                                    
                                </th>
                                <td key={risk.tag_name} className="px-6 pr-0 py-4 whitespace-pre-wrap max-w-[200px]">
                                    <div className="block">
                                        <div className="w-full font-bold text-black pb-2 flex items-center  "><IoWarning className="mr-3 fill-[#F9C107]" size={20}/>{Replace_idk_answer(risk.answer)}</div>
                                        {risk.info_note ?
                                        <div className="w-full font-extralight items-center flex text-xs text-gray-500/75">
                                            
                                                <><AiOutlineInfoCircle className="mr-3 min-w-[20px] fill-[#748AF2]" size={20}/>
                                                <span>{Replace_idk_answer(risk.info_note)}</span></>
                                            
                                            </div>: <></>}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-pre-wrap ">
                                    {risk.follow_ups.map((follow_up)=>{
                                        return (<div key={risk.follow_up_question} className="block">
                                            <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer((follow_up.follow_up_question ? follow_up.follow_up_question : follow_up.prompt_text))}</div>
                                            <div className="w-full font-bold text-md text-[#748AF2]">{Replace_idk_answer((follow_up.follow_up_answer ? follow_up.follow_up_answer : follow_up.answer))}</div>
                                        </div>)
                                    })}
                                    
                                    
                                </td>
                                <td className=" min-w-[120px] text-center pr-5">
                                    <CommentAnswerReferences
                                        job_id={id}
                                        are_refs_visible={risk?.follow_ups[0]?.are_refs_visible || risk?.are_refs_visible}
                                        references={retrieveAnswerReferencesFromRisk(risk)}
                                    />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
            </div>),
                },

            {
            label: "Undetected Issues",
            value: unknown_risks.length,
            content: (<div className="rounded-xl overflow-hidden">
            <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                    <tr>
                    <th scope="col" className="px-6 py-5 w-2/12">
                                    Question
                                </th>
                                <th scope="col" className="px-6 w-2/12">
                                    Answer
                                </th>
                                <th scope="col" className="px-6 w-7/12">
                                    Follow-Ups
                                </th>
                    </tr>
                </thead>
                <tbody>
                {unknown_risks?.map((risk) => (
                    <tr key={risk.origin_question ? risk.origin_question: risk.prompt_text} className="even:bg-[#F9FAFB] border-b ">
                        <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 ">
                            <div className="block">
                                <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer(risk.tag_name)}</div>
                                <div className="w-full font-medium">{Replace_idk_answer(risk.origin_question ? risk.origin_question: risk.prompt_text)}</div>
                            </div>
                            
                        </th>
                        <td className="px-6 pr-0 py-4 whitespace-pre-wrap max-w-[200px]">
                            <div className="block ">
                                <div className="w-full flex font-bold text-[#748AF2] pb-2">{Replace_idk_answer(risk.answer)}</div>
                                {risk.info_note ?
                                        <div className="w-full font-extralight items-center flex text-xs text-gray-500/75">
                                            
                                                <><AiOutlineInfoCircle className="mr-3 min-w-[20px] fill-[#748AF2]" size={20}/>
                                                <span>{Replace_idk_answer(risk.info_note)}</span></>
                                            
                                            </div>: <></>}
                                
                            </div>
                        </td>
                        <td className="px-6 py-4 whitespace-pre-wrap ">
                            {risk.follow_ups.map((follow)=>{
                                return (<div key={risk.follow_up_question} className="block">
                                    <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer((follow.follow_up_question ? follow.follow_up_question : follow.prompt_text))}</div>
                                    <div className="w-full font-bold text-xs text-[#748AF2]">{Replace_idk_answer((follow.follow_up_answer ? follow.follow_up_answer : follow.answer))}</div>
                                </div>)
                            })}
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
    </div>)
            },

                {
                    label: "No Issues",
                    value: no_risks.length,
                    content: (<div className="rounded-xl overflow-hidden">
                    <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                        <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                            <tr>
                                <th scope="col" className="px-6 py-5 w-2/12">
                                    Question
                                </th>
                                <th scope="col" className="px-6 w-2/12">
                                    Answer
                                </th>
                                <th scope="col" className="px-6 w-7/12">
                                    Follow-Ups
                                </th>
                                <th scope="col" className="px-6 w-7/12">
                                    References
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {no_risks?.map((risk) => (
                            <tr key={risk.origin_question ? risk.origin_question: risk.prompt_text} className="even:bg-[#F9FAFB] border-b ">
                                <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 ">
                                    <div className="block">
                                        <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer(risk.tag_name)}</div>
                                        <div className="w-full font-medium">{Replace_idk_answer(risk.origin_question ? risk.origin_question: risk.prompt_text)}</div>
                                    </div>
                                    
                                </th>
                                <td className="px-6 py-4 whitespace-pre-wrap max-w-[200px]">
                                    <div className="block ">
                                        <div className="w-full font-bold text-black flex pb-2"><AiFillCheckCircle className="mr-3 fill-green-500" size={20}/>{Replace_idk_answer(risk.answer)}</div>
                                        {risk.info_note ?
                                            <div className="w-full font-extralight items-center flex text-xs text-gray-500/75">
                                                
                                                    <><AiOutlineInfoCircle className="mr-3 min-w-[20px] fill-[#748AF2]" size={20}/>
                                                    <span>{Replace_idk_answer(risk.info_note)}</span></>
                                                
                                                </div>: <></>}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-pre-wrap ">
                                    {risk.follow_ups.map((follow)=>{
                                        return (<div key={risk.follow_up_question} className="block">
                                            <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer((follow.follow_up_question ? follow.follow_up_question : follow.prompt_text))}</div>
                                            <div className="w-full font-bold text-md text-[#748AF2] Inter">{Replace_idk_answer((follow.follow_up_answer ? follow.follow_up_answer : follow.answer))}</div>
                                        </div>)
                                    })}
                                </td>
                                <td className="min-w-[120px] text-center pr-5">
                                     <CommentAnswerReferences
                                         job_id={id}
                                         are_refs_visible={risk?.follow_ups[0]?.are_refs_visible || risk?.are_refs_visible}
                                         references={retrieveAnswerReferencesFromRisk(risk)}
                                     />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
            </div>)
                    },
        

        ]
    else
    tabsData = [
        {
            value: risks.length,
            content: (<div className="rounded-xl overflow-hidden ">
                <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                    <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                        <tr>
                            <th scope="col" className="px-6 py-5 w-2/12">
                                Question
                            </th>
                            <th scope="col" className="px-6 w-2/12">
                                Answer
                            </th>
                            <th scope="col" className="px-6 w-7/12">
                                Follow-Ups
                            </th>
                            <th scope="col" className="px-6 w-7/12">
                                references
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {risks.map((risk) => {
                        return <tr key={risk.origin_question ? risk.origin_question: risk.prompt_text} className="even:bg-[#F9FAFB] border-b ">
                            <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 ">
                                <div className="block">
                                    <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer(risk.tag_name)}</div>
                                    <div className="w-full font-medium">{Replace_idk_answer(risk.origin_question ? risk.origin_question: risk.prompt_text)}</div>
                                </div>
                                
                            </th>
                            <td key={risk.tag_name} className="px-6 pr-0 py-4 whitespace-pre-wrap max-w-[200px]">
                                <div className="block ">
                                    <div className="w-full font-bold text-black pb-2 flex items-center  ">
                                        {Replace_idk_answer(risk.answer)}
                                                                                
                                    </div>
                                    {risk.info_note ?
                                    <div className="w-full font-extralight items-center flex text-xs text-gray-500/75">
                                            
                                            <><AiOutlineInfoCircle className="mr-3 min-w-[20px] fill-[#748AF2]" size={20}/>
                                            <span>{Replace_idk_answer(risk.info_note)}</span></>
                                        
                                        </div>: <></>}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-pre-wrap ">
                            
                                {risk.follow_ups.map((follow)=>{
                                    return (<div key={risk.follow_up_question} className="block">
                                        <div className="w-full font-extralight text-gray-500 pb-2">{Replace_idk_answer((follow.follow_up_question ? follow.follow_up_question : follow.prompt_text))}</div>
                                        <div className="w-full font-bold text-md text-[#748AF2]">{Replace_idk_answer((follow.follow_up_answer ? follow.follow_up_answer : follow.answer))}</div>
                                    </div>)
                                })}
                                
                                
                            </td>
                            <td className=" min-w-[120px] text-center">
                                <CommentAnswerReferences
                                    job_id={id}
                                    are_refs_visible={risk?.follow_ups[0]?.are_refs_visible || risk?.are_refs_visible}
                                    references={retrieveAnswerReferencesFromRisk(risk)}
                                />
                            </td>
                        </tr>
        })}
                    </tbody>
                </table>
        </div>),
            },
    ]
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }
        
        setTabPosition();
        window.addEventListener("resize", setTabPosition);
    
        return () => window.removeEventListener("resize", setTabPosition);
    }, [activeTabIndex]);
    
    return (
        <div className="bg-white rounded-xl border shadow-sm mb-5 p-7 pt-12">
            {playbook_id !== 148 ?
            <span className="Inter font-black text-2xl">Issues Assessment</span>
             : 
             <span className="Inter font-black text-2xl">Checklist</span>
            
            }
            <div className="mt-7 border-b-2 border-[#EFF0F6]">
                {playbook_id !== 148 ?
                tabsData.filter(tab => tab.value !== 0).map((tab, idx) => {
                    return (
                    <button
                        key={idx}
                        ref={(el) => (tabsRef.current[idx] = el)}
                        className={`pb-5 mr-7 border-b-2 transition-colors duration-300 ${
                        idx === activeTabIndex
                            ? "border-[#748AF2] text-[#748AF2]"
                            : "border-transparent hover:border-gray-200"
                        }`}
                        // Change the active tab on click.
                        onClick={() => setActiveTabIndex(idx)}>
                        <span className="mr-4">{tab?.label}</span><span className="p-1 px-2.5 text-sm text-[#748AF2] bg-[#748AF2]/[.2] rounded-full">{tab.value}</span>
                    </button>
                    );
                    
                })
                : <></>}

            </div>
                <span className="absolute bottom-0 block h-1 bg-teal-500 transition-all duration-300" />
            <div className="mt-7">
                <span className="">{tabsData.filter(tab => tab.value !== 0)[activeTabIndex]?.content}</span>
            </div>
        </div>

        
    );
    }