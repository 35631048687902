import { Schema, DOMParser } from '@tiptap/pm/model';
import { DocSegmentsByID } from '../../services/v1/interfaces';

// const schema = new Schema({
//     nodes: {
//       doc: {
//         content: 'block+',
//       },
//       paragraph: {
//         content: 'inline*',
//         group: 'block',
//         parseDOM: [{ tag: 'p' }],
//         toDOM() {
//           return ['p', 0];
//         },
//       },
//       heading: {
//         attrs: { level: { default: 1 } },
//         content: 'inline*',
//         group: 'block',
//         defining: true,
//         parseDOM: [{ tag: 'h1' }],
//         toDOM(node) {
//           return ['h1', 0];
//         },
//       },
//     },
//   });

const tagsMap: any = {
  'h1': {
    name:'heading',
    level: '1'
  },
  'h2': {
    name:'heading',
    level: '2'
  },
  'h3': {
    name:'heading',
    level: '3'
  },
  'h4': {
    name:'heading',
    level: '4'
  },
  'p': {
    name: 'paragraph'
  }
}

const normalizeRow = (row: DocSegmentsByID) => {
 
  return {
      type: tagsMap[row.element_optional_info]?.name || 'paragraph',
      // level: 1,
      attrs: {
          level: tagsMap[row.element_optional_info]?.level,
          id: row.id  
      },
      content: [{
          type: 'text',
          text: row.text
      }]
  }
};

export const normalizeToPMFormat = (contentToNormalize: DocSegmentsByID[]) => {
  const doc = {
    type: 'doc',
  };

  if (!contentToNormalize) {
    return null;
  }
  const content = contentToNormalize
    .filter((row) => row.text !== '')
    .map((row) => {
      const node = normalizeRow(row);

      return node;
    });

  return {
    ...doc,
    content,
  };
};
