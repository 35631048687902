import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {PaginatedCompanyResult} from './interfaces';

export const getCompanyAppsById = async (
  ID: string,
  page: number,
  searchText: string
): Promise<PaginatedCompanyResult> => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem("token")
    }} ;
  let url = `${process.env.REACT_APP_NLP_API_BASE_URL}/companies/web/${ID}/apps?page=${page}`
  url = searchText !== "" ? `${url}&search_text=${searchText}` : url
  return await axios
    .get<PaginatedCompanyResult>(url, requestOptions)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error getting company apps: ${ID}`, error);
      return Promise.reject(error);
    });
};

export function useGetCompanyAppsByIdQuery(
  ID: string,
  page: number,
  searchQuery: string,
  options?: QueryOptionsOmit<
    UseQueryOptions<any>
  >
) {
  return useQuery(
    ['companies', 'apps', ID, page, searchQuery],
    () => {
      return getCompanyAppsById(ID, page, searchQuery);
    },
    options as any
  );
}
