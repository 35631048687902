import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from '../interfaces';
import {Company} from './interfaces';

export const getCompanyById = async (
  ID: string
): Promise<Company> => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem("token"),
    }} ;
  return await axios
    .get<Company>(`${process.env.REACT_APP_NLP_API_BASE_URL}/companies/${ID}/details`, requestOptions)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error getting company: ${ID}`, error);
      return Promise.reject(error);
    });
};

export function useGetCompanyByIdQuery(
  ID: string,
  options?: QueryOptionsOmit<
    UseQueryOptions<any>
  >
) {
  return useQuery(
    [ID],
    () => {
      return getCompanyById(ID);
    },
    options as any
  );
}
