import React, { useState } from 'react';
import { CSSTransition } from "react-transition-group";
import {NavLink} from "react-router-dom"

function Submenu({submenu}) {
  const [isOpen, setOpen] = useState(false);

  return (
        <CSSTransition
                key={submenu.state}
                timeout={200}
                classNames="submenu-transition"
              >
                <li className="rounded-sm">
                  <NavLink to={submenu.path} className="navbar-text flex items-center px-3 py-1.5 rounded-xl hover:font-black hover:bg-grey ease-in duration-100">
                    <span className="navbar-font ml-8">{submenu.state}</span>
                  </NavLink>
                </li>
              </CSSTransition>
  );
}

export default Submenu;