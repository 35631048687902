import React,  { useRef  } from 'react'
import DocSegment from "../../[Contract]/Review/DocSegment";
import { useEffect, useState } from 'react'
import ScrollChild from './ScrollChild'
import Comment from './Comment';
import TextBox from "../../../components/Single_Components/TextBox";
import { BiReset } from 'react-icons/bi';
import { ADDED_BACKGROUND_COLOR, ADDED_BACKGROUND_COLOR_SELECTED, EDITED_BACKGROUND_COLOR, EDITED_BACKGROUND_COLOR_SELECTED, ELIMINATED_BACKGROUND_COLOR, ELIMINATED_BACKGROUND_COLOR_SELECTED } from '../../../assets/GeneralFunctions';
export default function Differences({document1, document2, comments}) {
    const parentRef = useRef(null);
    useEffect(() => {

        if(document.getElementById('parent')){
            let s1 = document.getElementById('child1-scroll');
            let s2 = document.getElementById('child2-scroll');
            let s3 = document.getElementById('comments-scroll');
            function click_child_1(e) {
                const child = (e.target.id.includes("child") ? e.target.id.slice(0, -1) : e.target.id);
                
                // if (e.target.attributes.getNamedItem("aria-comment")) {
                    document.getElementById('child1').style.top = 0+"px"
                    const offsetTop1 = e.target.offsetTop;
                    const offsetTop2 = (e.target.id.includes("child") ? document.getElementById(child + "2").offsetTop : document.getElementById(child).offsetTop)
                    const scrollTopDiff = offsetTop1 - offsetTop2;
                    console.log(e.target.id, offsetTop1, offsetTop2, scrollTopDiff)
                    document.getElementById('child1').style.top = -scrollTopDiff +"px"
                    if(scrollTopDiff!==0) document.getElementById('child1-scroll').classList.add("masked-overflow")

                // }

            }
            
            function click_child_2(e) {
                const child = (e.target.id.includes("child") ? e.target.id.slice(0, -1) : e.target.id);
                
                // if (e.target.attributes.getNamedItem("aria-comment")) {
                        document.getElementById('child2').style.top = 0+"px"
                        const offsetTop1 = e.target.offsetTop;
                        const offsetTop2 = (e.target.id.includes("child") ? document.getElementById(child + "1").offsetTop : document.getElementById(child).offsetTop)
                        
                        const scrollTopDiff =  offsetTop1-offsetTop2 ;
                        document.getElementById('child1').style.top = scrollTopDiff +"px"
                        if(scrollTopDiff!==0) document.getElementById('child1-scroll').classList.add("masked-overflow")

                // }

            }

            function click_child_3(e) {
                
                const child = "father-"+e.target.offsetParent.id.split("-comment")[0]+"-child"

                        document.getElementById('child2').style.top = 0+"px"
                        const offsetTop1 = document.getElementById(child + "2").offsetTop;
                        const offsetTop2 = document.getElementById(child + "1").offsetTop;
                        const scrollTopDiff =  offsetTop1-offsetTop2 ;
                        document.getElementById('child1').style.top = scrollTopDiff +"px"
                        if(scrollTopDiff!==0) document.getElementById('child1-scroll').classList.add("masked-overflow")

                    
                

            }
    
            s1.addEventListener("click", click_child_1);
            s2.addEventListener("click", click_child_2);
            s3.addEventListener("click", click_child_3);
            
            return () => {
                s1.removeEventListener("click", click_child_1);
                s2.removeEventListener("click", click_child_2);
                s3.removeEventListener("click", click_child_3);
            }
        }}, [document1])



    function resetScroll() {
        document.getElementById('child1').style.top = "0px"
        document.getElementById('child2').style.top = "0px"
        document.getElementById('child1-scroll').classList.remove("masked-overflow")
    }



    return (
    <>
    
    {document1 && document2 && comments?
        <div >
        <div className="flex">
        <div id="doc-text" className="sm:px-6 lg:px-8 bg-white border rounded-xl p-6 shadow-sm w-2/3" >
            <div className='flex justify-between items-center  mb-6 '>
                <span className='Inter font-bold text-xl'>Documents Changes</span>
                <div>
                    <span className={`mr-3 py-0.5 px-4 rounded-md ${ADDED_BACKGROUND_COLOR_SELECTED}`}></span>
                    <span className='mr-3 font-bold text-sm Inter'>Added Text</span>
                    <span className={`mr-3 py-0.5 px-4 rounded-md ${EDITED_BACKGROUND_COLOR_SELECTED}`}></span>
                    <span className='mr-3 font-bold text-sm Inter'>Changed Text</span>
                    <span className={`mr-3 py-0.5 px-4 rounded-md ${ELIMINATED_BACKGROUND_COLOR_SELECTED}`}></span>
                    <span className='font-bold text-sm Inter'>Removed Text</span>
                </div>
            </div>
            <div className='text-center flex mt-10  pr-5'>
                <div className='text-center Inter text-lg font-bold mx-auto mb-6' >Original</div>
                <button className='Inter rounded-full bg-white p-2' onClick={resetScroll}><BiReset size={25} className='fill-blue'/></button>
                <div className='text-center Inter text-lg font-bold mx-auto mb-6'>Changed</div>
            </div>
            {/* <TextBox text={document1?.text}> */}
            <div id="parent" className='flex relative' ref={parentRef}>
                <div id="child1-scroll" className='block mx-1.5 w-1/2 '>
                    <div  className="transition-all duration-150">
                        <div id="child1" className="relative transition-all duration-150 ">
                            <ScrollChild document1={document1} part={"child1"} commentIds={comments}/>
                        </div>
                    </div>
                </div>

                <div className='block mx-1.5 w-1/2 '>
                <div id="child2-scroll" className="transition-all duration-150 ">
                    <div id="child2" className="relative  transition-all duration-150">
                        <ScrollChild document1={document2} part={"child2"} commentIds={comments}/>
                    </div>
                </div>
                </div>
                

            
            </div>
            {/* </TextBox> */}
            </div>
             
            <div id="comments-scroll" className="mr-3 transition-all duration-150 overflow-hidden w-1/3 pl-12 masked-overflow ">
                <div id="comments" className="relative transition-all duration-150 mt-[145px]  " >
                    <ScrollChild document1={comments} comments={true} commentIds={[]}/>
                </div>
            </div>
            
        </div>
        </div >
        :<></>}
    </> )
}
