import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { notifications } from "../layout/MainLayout";
import { AiOutlineClockCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { AiOutlineShareAlt } from "react-icons/ai";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { GrClose } from "react-icons/gr";

export default function Modal(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };
  const closeModalDocuments = () => {
    setOpen(false);
    props.closeModal();
  };

  const closeModalReview = () => {
    setOpen(false);
    props.closeModal();
    notifications.push({
      icon: <AiOutlineCheckCircle size={25} color="green" />,
      upper_text: "Success",
      lower_text: "Document was submitted for review with success!",
    });
  };

  const [copied, setCopied] = useState();
  function myFunction() {
    // Get the text field
    let containerid = document.getElementById("myInput");
    navigator.clipboard.writeText(containerid.innerHTML);
    setCopied(
      <div className="quote absolute z-20 mx-auto inset-x-0 -top-10 transition-all duration-100 max-w-fit">
        <div className="bg-blue text-white Inter px-5 py-2 mx-auto rounded-xl w-fit text-xs">
          Copied!
        </div>
        <div class="h-0 w-0 border-x-8 border-x-transparent mx-auto border-t-[10px] border-t-blue"></div>
      </div>
    );
    setTimeout(() => {
      setCopied();
    }, 5000);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative overflow-hidden rounded-3xl bg-white lg:p-16 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <button onClick={closeModal} className="absolute right-7 top-7">
                  <GrClose size={25} />
                </button>
                <div>
                  <div className="flex justify-center items-center mb-10">
                    <FaPaperPlane
                      size={40}
                      className="fill-blue hover:fill-[#225187] mr-10"
                    />
                    <span className="Poppins text-[20px] xl:text-[40px] font-bold text-[#555555]">
                      Sharing Document Review!
                    </span>
                  </div>
                  <button
                    onClick={myFunction}
                    className="mb-10  relative block ">
                    <div
                      type="text"
                      id="myInput"
                      className="bg-white border Inter border-gray-300 text-gray-900 text-sm rounded-2xl focus:outline-none block  p-3 px-12 py-4 cursor-pointer w-fit"
                      readOnly>
                      {window.location.origin + "/share/" + props.uniqueID}
                    </div>
                    <div className="quote1">{copied}</div>
                    <div className="cursor-pointer flex justify-between mx-3 -translate-y-[37px]">
                      <AiOutlineShareAlt size={20} color={"#3F3F46"} />
                      <HiOutlineClipboardCopy size={20} color={"#3F3F46"} />
                    </div>
                  </button>
                  <div className="border-l-blue border-l-8 pl-8 max-w-[840px]">
                    <p className="Inter font-bold text-lg text-[#555555] mb-1">
                      How does share work?
                    </p>
                    <p className="Inter font-light text-md text-[#555555]">
                      Send the link to a colleague or friend. Once they tap on
                      the link or insert it in a web browser they will be
                      redirected to Legalreview.ai to see the analysis performed
                      on your document.
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
