import {  React, useState, useEffect  } from "react";
import Breadcrumb from "../../../components/Single_Components/Breadcrumbs";
import DocumentHeader from "../../../components/Single_Components/DocumentHeader";
import { useLocation, useOutletContext } from "react-router-dom";
import FetchComparison from "../../../assets/FetchComparison";
import { useParams } from "react-router-dom";
import Differences from "./Diferences";
// import test from "./test.json"
function ComparisonReview() {
    const [document1, setDocument, text, fetchComments] = useOutletContext();
    const [documentDate, setDocumentDate] = useState();
    const [document2, setDocument2] = useState();
    const [document3, setDocument3] = useState();
    const [date, setDate] = useState("");
    const [name, setName] = useState("");
    const [comments, setComments] = useState("");
    const [commentsIds, setCommentsIds] = useState([]);
    const location = useLocation();

    const [shareID, setShareId] = useState("");


    useEffect(() => {
        function setCommentYPosition(comment, lastComment) {
          if (!lastComment) {
            comment.style.top = document.getElementById("father-" + comment.id.replace("-comment", "-child2"))?.offsetTop + "px";
          } else if (lastComment && lastComment?.style?.top.replace("px", "") + lastComment?.offsetHeight < parseInt(document.getElementById("father-" + comment.id.replace("-comment", "-child2"))?.offsetTop)) {
            comment.style.top =
              document.getElementById(
                "father-" + comment.id.replace("-comment", "-child2")
              ).offsetTop + "px";
          } else {
            const k =
              lastComment?.offsetHeight +
              +lastComment?.style?.top.replace("px", "");
            comment.style.top = k + "px";
          }
        }
    
        const commentsList = document.getElementById("comments")?.childNodes;
        if(commentsList){
          let commentTop = [];
          for (let comment of commentsList) {
            let lastComment = commentTop.pop();
            setCommentYPosition(comment, lastComment);
            commentTop.push(comment);
          }
        }
        if(location.search.split("=")[1]){
          setTimeout(() => {
            let element = document.getElementById(
              "father-" + location.search.split("=")[1]
            );
      
            if (element) {
              element.click();
              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }, 250);
        }
    
      }, [comments])


      
      useEffect(()=>{
        
        let comparison_data = ""
        if(document1?.result && document1?.result[0]?.result?.side_by_side_comparison){
            
            comparison_data = document1?.result[0]?.result
            setDocumentDate(comparison_data)
            setDocument2(comparison_data.side_by_side_comparison?.original_doc_side)
            setDocument3(comparison_data.side_by_side_comparison?.new_doc_side)
            setName(document1?.result[0]?.document_name)
            setDate(document1?.updated_at.replaceAll("-","/").split("T")[0])
            setShareId(document1?.unique_id);
            let commentsList = []
            if(comparison_data?.diffs_enriched){
              Object.keys(comparison_data?.diffs_enriched).forEach((key, value) => {
                  if(comparison_data?.diffs_enriched[key].length > 0){
                    comparison_data?.diffs_enriched[key].forEach((comment, index) => {
                      if(commentsList.filter(e => e.order_idx === comment.order_idx).length === 0){
                        commentsList.push(comment)
                        commentsIds.push(comment.ref_id)
                          }
                      })
                  }})
                  commentsList = commentsList.sort((a,b) => a.order_idx - b.order_idx)
                  setComments(commentsList)
                }
          
        }
        },[document1])

        
        // console.log("...............")
        // document2?.filter(e => e.change_type !== "unchanged").map(e=>{
        //   console.log(e.change_type)
        //   e.diffs.map(a=>{
            
        //     console.log(a.new_text)
        //   })
        // })
    return ( <div  className="lg:min-w-navbar mt-0 my-6 mx-5" >
        <Breadcrumb page={"Summary"} docname={name}/>
        {/* <DocumentHeader props={[document2, "comparison/"+document1?.id+"/summary", "Summary",date, name,,shareID]}/> */}
        <DocumentHeader props={[documentDate, "comparison/"+document1?.id+"/summary", "Summary",date, name,"",shareID]}/>

        <Differences document1={document2} document2={document3} comments={comments} commentIds={documentDate} />

    </div>)
} 


export default ComparisonReview;