import { Outlet, useParams } from "react-router-dom";
import Sidebar from "../navbar/Sidebar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginButton from "../auth/login";
import UploadContract from "../../pages/UploadContract";
import ToCPrivacyPolicy from "../../pages/ToCPrivacyPolicy";
import FetchUserInfo from "../../assets/FetchBalance";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {useFeatureFlagEnabled, usePostHog} from 'posthog-js/react'
import { RxHamburgerMenu } from "react-icons/rx";
import { GrClose } from "react-icons/gr";
import {posthog} from "posthog-js";

const MainLayout = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); //load loading component
  const [jobs, setJobs] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [acceptedDisclaimer, setAcceptDisclaimer] = useState();
  const [disclaimerComponent, setDisclaimerComponent] = useState();
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false)

  const ph = usePostHog()

  useEffect( () => {
    console.log("user:", user)
    if (user) {
      posthog.identify(user.email, {
        email: user.email,
      })
    }
  }, [ph, user])

  const legalreviewViewEnabled = useFeatureFlagEnabled(
      '[FE]-original-legalreview-app-view'
  );

  async function fetchAllDocuments(requestOptions) {
    try {
      const response = await window.fetch(
        process.env.REACT_APP_DOMAIN + "/api/v1/jobs/",
        requestOptions
      );
      // const result = await response.json();
      return response;
    } catch (e) {
      console.log(e);
    }
    return [];
  }

  useEffect(() => {
    async function callSecureApi() {
      // if (!localStorage.getItem("token")){
      try {
        const token = await getAccessTokenSilently({
          ignoreCache: true,
        });
        const token1 = "Bearer " + token;
        localStorage.setItem("token", token1);
      } catch (error) {
        console.log(error.message);
      }
      return localStorage.getItem("token");
    }

    const fetchDocFromUniqueID = async (requestOptions) => {
      const response = await window.fetch(
        process.env.REACT_APP_DOMAIN +
          "/api/v1/jobs/share/" +
          localStorage.getItem("uniqueID") +
          "/",
        requestOptions
      );
      if (response.status !== 200) return response.status;
      const result = await response.json();
      return result
    };

    callSecureApi()
      .then((result) => {
        if (
          localStorage.getItem("terms_accepted") === "false" ||
          localStorage.getItem("terms_accepted") === "undefined" ||
          !localStorage.getItem("terms_accepted")
        ) {
          FetchUserInfo().then((userinfo) => {
            setAcceptDisclaimer(userinfo.terms_accepted);

            localStorage.setItem("terms_accepted", userinfo.terms_accepted);
            if (!userinfo.terms_accepted) {
              setIsFirstTimeUser(true)
              setDisclaimerComponent(<ToCPrivacyPolicy />);
            }
          });
        } else {
          setAcceptDisclaimer(true);
        }

        return result;
      })
      .then((result) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: result,
          },
        };

        fetchAllDocuments(requestOptions)
          .then(async (r) => {
            return await r.json();
          })
          .then((result) => {
            try {
              setLoading(false)
              result
                .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
                .reverse();
              setJobs(result.filter((job) => {return job.project === null}));
            } catch {}
          });
        if (localStorage.getItem("uniqueID")){
          fetchDocFromUniqueID(requestOptions).then((result) => {
            navigate(
              "/contract/" + result?.id + "/summary"
            );
          });


        }

      });
  }, []);

  useEffect(() => {
    if (redirect && jobs.length === 0 && window.location.href.includes("?code="))
      navigate("/home/upload");
  }, [redirect, jobs]);

  const [timeOfJob, setTimeOfJob] = useState();
  const location = useLocation();

  useEffect(() => {
    let refresh = 0;
    let intervalId = null;
    createdJob = false;
    let timeDiference =
      new Date() - new Date(localStorage.getItem("newJobCreatedDate"));

    let diffMins = Math.round(((timeDiference % 86400000) % 3600000) / 60000); //this converts the dates to minutes
    intervalId = setInterval(() => {
      if (
        localStorage.getItem("newJobCreatedDate") &&
        diffMins < 5 &&
        refresh < 15
      ) {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        };

        fetchAllDocuments(requestOptions)
          .then(async (r) => {
            return await r.json();
          })
          .then((result) => {
            setLoading(false)
            result
              .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
              .reverse();
            setJobs(result.filter((job) => job.project === null));
          });
        refresh++;
      } else {
        clearInterval(intervalId);
      }
    }, 20000);
  }, [localStorage.getItem("newJobCreatedDate")]);

  useEffect(() => {
    if (legalreviewViewEnabled !== undefined) {
      if (
          isAuthenticated &&
          (location.pathname === "/" ||
              location.pathname === "/home" ||
              location.pathname === "/home/")
      ) {
        if (!legalreviewViewEnabled) {
          navigate("/home/contracts");
        } else {
          navigate("/home/companies");
        }
      }
      if (!localStorage.getItem("terms_accepted"))
        localStorage.setItem("terms_accepted", false);
    }
}, [legalreviewViewEnabled]);

  const [open, setOpen] = useState(false);
  const close = event => {
    setOpen(false)
  }


  return (
    <div className="flex bg-[#E0E0E0]/[.2] h-screen">
      {isAuthenticated &&
        (acceptedDisclaimer ? (
          <>
            <div className="fixed block lg:hidden z-50">
              <div className={`${open?"fixed z-50 right-0":""}`}>
              <button
                className={`p-5 mt-5 ml-2 bg-white shadow-sm border rounded-xl`}
                onClick={() => setOpen(!open)}>
                  {open?<GrClose size={25} />:<RxHamburgerMenu size={25} />}
                
              </button>
              </div>
            
              {open ? <><Sidebar close={close}/>
              <div className="bg-white w-screen  h-screen absolute z-10"></div>
              </> :   
              <></>
              }
            </div>
            <div className="hidden fixed lg:block">
              <Sidebar /> 
            </div>

            
            <div className={`w-full ml-[65px] lg:ml-[271px] min-h-screen overflow-auto max-h-screen`}>
              {!isFirstTimeUser ? (
                <Outlet context={[jobs, setJobs, loading]} />
              ) : (
                <UploadContract setTimeOfJob={setTimeOfJob} />
              )}
            </div>
          </>
        ) : (
          disclaimerComponent
        ))}

      {!isAuthenticated && <LoginButton />}
    </div>
  );
};
export default MainLayout;
export let notifications = [];
export let createdJob = false;

export const projectTypes = {
  "Select option": null,
  "M&A":"m_and_a",
  "SaaS contracts":"saas_db",
  "In house analysis":"in_house_legal",
}

let playbook = {}

// Add Custom Conditional playbooks
posthog.onFeatureFlags(function() {

  if (!posthog.isFeatureEnabled('[FE]-original-legalreview-app-view')) {
    playbook["Auto-detect"] = -1
  }

  playbook["M&A"] = 148
  playbook["NDA Playbook"] = 152
  // feature flags should be available at this point
  if (posthog.isFeatureEnabled('[FE]-[Upload]-make_workmotion_playbook_visible') ) {
    playbook["Workmotion"] = 153
  }
  if (posthog.isFeatureEnabled('[FE]-[Upload]-make_capnamic_playbook_visible') ) {
    playbook["Term Sheet"] = 154
  }
  if (posthog.isFeatureEnabled('[FE]-[Upload]-make_simpliant_playbook_visible') ) {
    playbook["DPA"] = 155
  }
  if (posthog.isFeatureEnabled('[FE]-[Upload]-make_shareholders_agreement_playbook_visible') ) {
    playbook["Shareholders Agreement"] = 156
  }
  if (posthog.isFeatureEnabled('[FE]-[Upload]-make_convertible_loan_playbook_visible') ) {
    playbook["Convertible Loan"] = 157
  }
})

// Step 1: Convert the object into an array of key-value pairs
const playbooksArray = Object.entries(playbook);

// Step 2: Sort the array based on the values (smallest to biggest)
playbooksArray.sort((a, b) => a[1] - b[1]);

// Step 3: Convert the sorted array back into an object
playbook = Object.fromEntries(playbooksArray);

export {playbook};

export const jobsFilters = {
  "Last modified" : 0,
  "Document Name": 1,
  "Created Date": 2,
  "Document Type": 3,
}

export const projectFilters = {
  "Created date" : 0,
  "Project name": 1,
  "Playbook": 2,
  "Project type": 3,
}