import { Fragment, useState, useRef, useEffect } from "react";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function Dropdown({ dropDownChild, filters, placeholder, sideText, styles, defaultFilter}) {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [width, setWidth] = useState("");
  const handleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if(Object.keys(filters)[0] === "Select option") setFilter("Select option")
    else  setFilter(Object.keys(filters)[0])
    if (placeholder) setFilter(placeholder)
    const longestString = Object.keys(filters).reduce((acc, curr) => {
      return curr.length > acc.length ? curr : acc;
    }, "");
    setWidth(Math.floor(longestString.length * 8.1));
  }, []);

  function textFilter(filter, int, key) {
    if (Object.keys(filters)[0] === "Select option" && int === 0) setFilter("Select option")
    else setFilter(key);
    setOpen(false);
    return dropDownChild(filter);
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // Object.keys(filters)[Object.values(filters).indexOf(filter)]
  return (
    <>
      <Menu>
        <div className="relative inline-block text-left">
          <Menu.Button
            className={`flex w-full justify-between rounded-md  ${styles ? styles: "border border-gray-300"}  bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
            onClick={handleOpen}>
            <span className="pr-1 ">{sideText}</span>
            <span
              className={`text-blue w-[${width}px] max-w-[500px] break-words text-start`}>
              &nbsp;{filter}
            </span>
            {}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>

          <div ref={wrapperRef} className="">
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              {open ? (
                <div className="absolute w-max right-0 top-10 z-10 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <ul className="menu">
                    {Object.keys(filters).map((filter, int) => (
                      <a key={int+"_question"}
                        className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-5 py-3 text-sm break-words max-w-[500px]"
                        onClick={() => textFilter(Object.values(filters)[Object.keys(filters).indexOf(filter)], int, filter)}>
                        {filter}
                      </a>
                    ))}
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </Transition>
          </div>
        </div>
      </Menu>
    </>
  );
}
