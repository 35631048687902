import { React } from "react";
import ClickOutside from "../../../components/Single_Components/ClickOutside";
import {
  addStyle,
  removeStyle,
ADDED_BORDER, EDITED_BORDER, ELIMINATED_BORDER

} from "../../../assets/GeneralFunctions";


// import test from "./test.json"
function Comment({ comment_data,allFatherData, fatherClick }) {
  const handleMouseEnter = () => {
    let comment = document.getElementById(comment_data.ref_id)
    if (comment && comment.getAttribute("aria-comment") === "false") {
      let fatherTitle1 = document.getElementById("father-"+comment_data.ref_id+"-child1");
      let fatherTitle2 = document.getElementById("father-"+comment_data.ref_id+"-child2");
      console.log(fatherTitle1, fatherTitle2)
      addStyle(fatherTitle1, comment_data.change_type, comment_data.real_change_type);
      addStyle(fatherTitle2, comment_data.change_type, comment_data.real_change_type);
      comment.classList.add("comments_border");
      if(comment_data.change_type === "edited") comment.classList.add(EDITED_BORDER)
      else if(comment_data.change_type === "added" || comment_data.real_change_type === "added") comment.classList.add(ADDED_BORDER);
      else if(comment_data.change_type === "eliminated" || comment_data.real_change_type === "eliminated") comment.classList.add(ELIMINATED_BORDER);
    }
  };

  const handleMouseLeave = () => {
    let comment = document.getElementById(comment_data.ref_id)
    if (comment && comment.getAttribute("aria-comment") === "false") {
      let fatherTitle1 = document.getElementById("father-"+comment_data.ref_id+"-child1");
      let fatherTitle2 = document.getElementById("father-"+comment_data.ref_id+"-child2");
      removeStyle(fatherTitle1, comment_data.change_type, comment_data.real_change_type);
      removeStyle(fatherTitle2, comment_data.change_type, comment_data.real_change_type);
      comment.classList.remove("comments_border");
      if(comment_data.change_type === "edited") comment.classList.remove(EDITED_BORDER)
      else if(comment_data.change_type === "added" || comment_data.real_change_type === "added") comment.classList.remove(ADDED_BORDER);
      else if(comment_data.change_type === "eliminated" || comment_data.real_change_type === "eliminated") comment.classList.remove(ELIMINATED_BORDER);
    }
  };

  const handleClick = (e) => {
    
    let comment = document.getElementById(comment_data.ref_id)
    let child1 = document.getElementById("father-"+comment_data.ref_id+"-child1")
    let child2 = document.getElementById("father-"+comment_data.ref_id+"-child2")
    child1.setAttribute("aria-comment", "true");
    child2.setAttribute("aria-comment", "true");
    let childs = document.getElementsByClassName("child-"+comment_data.ref_id);
    for (let child of childs) {
      child.setAttribute("aria-comment", "true");
    }
    document.getElementById(comment_data.ref_id).classList.add("comments_border");

    if(comment_data.change_type === "edited") comment.classList.add(EDITED_BORDER)
    else if(comment_data.change_type === "added" || comment_data.real_change_type === "added") comment.classList.add(ADDED_BORDER);
    else if(comment_data.change_type === "eliminated" || comment_data.real_change_type === "eliminated") comment.classList.add(ELIMINATED_BORDER);

    //--click----
    fatherClick(e);
  };

  return (
      <ClickOutside onClickInside={handleClick}>
        <div
          key={comment_data.ref_id}
          id={comment_data.ref_id}
          className={`bg-white mb-3 shadow-inner p-5 rounded-xl transition-all duration-150 Inter child-${comment_data.ref_id}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          aria-comment="false">
          <div>
            <div className={`text-sm text-gray-500 mb-3 pr-2 flex capitalize ${comment_data?.change_type ?"justify-between" :"justify-end" }`}>
              
            {comment_data?.change_type }
              {/* {useFeatureFlagEnabled("[FE]-[InlineReview]-allow_feedback") ? (
                <div className="z-20 flex">
                  <button onClick={handleThumbsUp}>
                    {thumbs === 1 ? (
                      <BsHandThumbsUpFill
                        size={25}
                        className="mr-5 fill-blue"
                      />
                    ) : (
                      <BsHandThumbsUp
                        size={25}
                        className="mr-5 fill-gray-400"
                      />
                    )}
                  </button>
                  <button onClick={handleThumbsDown}>
                    {thumbs === -1 ? (
                      <BsHandThumbsDownFill size={25} className="fill-red" />
                    ) : (
                      <BsHandThumbsDown size={25} className="fill-gray-400" />
                    )}
                  </button>
                </div>
              ) : (
                <></>
              )} */}
            </div>
            <p className="ml-auto text-md pr-2"
                dangerouslySetInnerHTML={{
              __html:
              comment_data.prompt_answer.replaceAll(
                  "\n",
                  "<br>"
                )
              ,
            }}/>
          </div>
        </div>
      </ClickOutside>
  );
}

export default Comment;
