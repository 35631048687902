import React from 'react';

interface IQuestionListSkeletonProps {}

const QuestionListSkeleton: React.FC<IQuestionListSkeletonProps> = ({}) => {
  return (
    <div role="status" className="animate-pulse flex flex-col gap-2 mt-5 px-1 ">
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      <div className="h-12 bg-gray-100 rounded dark:bg-gray-300  w-full  "></div>
      
    </div>
  );
};

export default QuestionListSkeleton;
