import React, { useState } from "react";
import Posthog, { posthog } from "posthog-js";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import Modal from "./ModalUpload";
import Dropdown from "./Dropdown";
function Questions({ questions, setQuestions } ) {
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const [question, setQuestion] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!questions.includes(question)) {
      setQuestions([...questions, question]);
      setQuestion("");
    } else {
      alert("question already entered");
    }
    openModal();
  };
  const handleRemove = (id) => {
    setQuestions(questions.filter((todo) => todo !== id));
  };

  return (
    <div className="">
      
      <form onSubmit={handleSubmit}>
        <div className="flex mb-5">
          <label className="block text-md text-[16px] font-[500] Inter">
            What would you like to know about your document?
          </label>
        </div>
        <div className="flex my-6 items-center step3 w-fit">
          <input
            type="text"
            placeholder="&#xf044;  Type your question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            id="questions"
            name="questions"
            style={{ fontFamily: "Inter, FontAwesome", fontSize: "16px" }}
            className="w-[637px] border p-4 text-lg rounded focus:outline-none Inter"
            // defaultValue={''}
          />
          <button
            className="ml-8 border-[#748AF2] text-l bg-transparent text-[#748AF2] shadow border-2 rounded-2xl p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer"
            type="submit">
            <AiOutlinePlusCircle className="mr-3" size={20} />
            <span className="min-w-fit">Add question</span>
          </button>

        </div>
      </form>
      <ul className={`${questions.length ? "mb-10" : ""}`}>
        {questions.map((q, i) => (
          <li
            className="w-[637px] flex justify-between items-center border p-4 mb-2 text-lg rounded focus:outline-none Inter"
            key={i}>
            <span>{q}</span>
            <button onClick={() => handleRemove(q)}>
              <AiFillDelete
                className="mr-3 text-red/[.7] text-center"
                size={20}
              />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Questions;
