import React from 'react';

const InfoBox: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  };

  const iconStyle = {
    backgroundColor: 'rgba(48, 86, 211, 0.1)',
    width: '24px', 
    height: '24px',
    color: '#748AF2',
    borderRadius: '20%',
    padding: '9px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
  };

  const blueTextStyle = {
    color: '#748AF2',
  };

  const boldTextStyle = {
    fontWeight: 'bold',
  };

  const alertStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
  };

  const alertIconStyle = {
    ...iconStyle,
    backgroundColor: '#748AF2',
    color: 'white',
    marginRight: '8px',
    borderRadius: '50%',
  };



  return (
    <div style={containerStyle} className='text-sm'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ ...iconStyle, ...boldTextStyle }}>i</div>
        <div style={{ ...blueTextStyle, ...boldTextStyle }}>What to expect</div>
      </div>
      <p style={{ ...boldTextStyle, marginTop: '20px' }}>We currently support the following document types:</p>
      <ul className="list-disc list-inside mt-6">
        <li>M&A agreements</li>
        <li>Non-disclosure agreements</li>
        <li>Term sheets</li>
        <li>Data Protection Agreements</li>
        <li>Shareholders Agreements</li>
        <li>Convertible Loans</li>
      </ul>
      <p className='mt-6'>For other document types, we may not be able to provide the same level of quality assurance.</p>
      <div style={alertStyle}>
        <div style={alertIconStyle}>!</div>
        <p style={{ ...blueTextStyle, ...boldTextStyle, marginTop: '15px' }}>If you'd like to analyse different documents, please contact us via chat or at <a href="mailto:hello@legalreview.al" className="underline">hello@legalreview.al</a>. We can create custom playbooks for your specific needs.</p>
      </div>
    </div>
  );
}

export default InfoBox;
