const FetchSingleDocument = async ([document, setDocument], id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  };
  const fetchSingleDoc = async () => {
    const response = await window.fetch(
      process.env.REACT_APP_DOMAIN + "/api/v1/jobs/" + id + "/",
      requestOptions
    );
    if (response.status !== 200) return response.status;
    const result = await response.json();
    if (result.length !== 0) {
      setDocument(result);
    }
    return response.status;
  };

  return await fetchSingleDoc();
};
export default FetchSingleDocument;
