import Box from "@mui/material/Box";
import { red, yellow } from "@mui/material/colors";
import { useState, useEffect } from "react";
import ReactStoreIndicator from "../../[Contract]/Summary/ReactStoreIndicator";
import { usePostHog, useFeatureFlagEnabled,useActiveFeatureFlags } from 'posthog-js/react'
import { Replace_idk_answer } from "../../../assets/GeneralFunctions";
export default function SummaryComponent({ props, playbook_id }) {
  let redbar = 0,
    edits = 0;
  let yellowbar = 0,
    removals = 0;
  let greenbar = 0,
    additions = 0;
  let total = 0;

  try {
    // additions = props?.doc_analysis_summary_data?.total_additions_checks
    // edits = props?.doc_analysis_summary_data?.total_edits_checks
    // removals = props?.doc_analysis_summary_data?.total_removals_checks

    // total = props?.doc_analysis_summary_data?.total_checks
    additions = props?.summary?.stats?.adds?.paragraph
    edits = props?.summary?.stats?.edits?.paragraph
    removals = props?.summary?.stats?.removals?.paragraph
    total = additions + edits + removals;

    redbar = (removals * 100) / total;
    yellowbar = (edits * 100) / total;
    greenbar = (additions * 100) / total;
  } catch (e) {
    console.log(e);
  }
  const [readmorebutton, setReadmorebutton] = useState(false);

  const [readmore, setReadmore] = useState(true);
  const [moreorless, setMoreorless] = useState("more");
  function ReadMore(e) {
    let elementToCheck = document.getElementById("summary");

    //
    if (readmore) {
      elementToCheck.classList.remove("line-clamp-7");
      setMoreorless("less");
      setReadmore(!readmore);
    } else {
      elementToCheck.classList.add("line-clamp-7");
      setMoreorless("more");
      setReadmore(!readmore);
    }
  }
  let elementToCheck = document.getElementById("summary");
  useEffect(() => {
    function showReadMoreButton(element) {
      if (
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth
      ) {
        setReadmore(true);
        setReadmorebutton(true);
      } else {
        setReadmore(false);
        setReadmorebutton(false);
      }
    }
    if (elementToCheck) showReadMoreButton(elementToCheck);
  }, [props?.summary?.diff_summary_text]);

  let posthog = usePostHog()

  const show_document_score_access = useFeatureFlagEnabled("[FE]-[Summary]-show_document_score_access")


  const [summaryText, setSummaryText] = useState("1")
  useEffect(() => {
    if (props?.summary?.diff_summary_text) {
      if (props?.summary?.diff_summary_text.includes("I do not know the answer."))
       setSummaryText(Replace_idk_answer(props?.summary?.diff_summary_text))      
    }
  }, [props?.summary?.diff_summary_text])

  return (
    <div className="p-5 bg-white rounded-xl border shadow-sm mb-5 ">
      <div className="Inter text-2xl font-black mt-7 ml-5 flex items-center w-5/12 justify-between">
        <span>Review summary</span>
        {props?.general_information?.score && show_document_score_access ? (
                    <div className="bg-white rounded-3xl shadow-sm border p-5 pb-0 pt-2 absolute right-[120px] top-[332px] z-20">
                      <span className="font-bold Inter text-[20px] pb-3">
                        Document Score
                      </span>
                      <ReactStoreIndicator
                        value={props?.general_information?.score}
                        maxValue={100}
                        width={100}
                        fadedOpacity={20}
                        style={{ marginTop: "10px" }}
                        stepsColors={[
                          "#D81658",
                          "#D81758",
                          "#D91D55",
                          "#DD324C",
                          "#E65F39",
                          "#F4AC19",
                          "#CFDD2E",
                          "#77C782",
                        ]}
                        textStyle={{ top: "25px", fontWeight: "400" }}
                      />
                    </div>) : <></>}
                  
      </div>
      <div
        className={`lg:flex mb-5 ${
          playbook_id === -1 ? "items-end" : "items-center"
        }`}
      >
        <div className="block mt-7 ml-5 max-w-[50%] border-gray-500 pr-7">
          
        <span
            id="summary"
            className="line-clamp-7 text-black"
            dangerouslySetInnerHTML={{
              __html:
                props?.summary?.diff_summary_text.replaceAll(
                  "\n",
                  "<br>"
                )
              ,
            }}
          />
          
          {readmorebutton ? (
            <button
              className="text-right w-full text-blue hover:underline"
              onClick={ReadMore}
            >
              Read {moreorless}
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="block w-1/2">
          <div className="flex w-full">

          {playbook_id === -1 ? 
              <div className="mb-1 w-1/3 text-center">
              <span className="Inter font-bold">Additions</span>
              <div className="w-[145px] mx-auto bg-gray-200 rounded-full h-3 mt-5">
                <div
                  className="bg-green-500 h-3 rounded-full "
                  style={{ width: greenbar.toString() + "%" }}
                ></div>
                <div className="flex justify-center Inter font-bold mt-1">
                  <span>
                    {
                      additions
                    }{" "}
                    / {total}
                  </span>
                </div>
              </div>
            </div>
            : <></>}

            <div className="mb-1 w-1/3 text-center border-gray-500">
              <span className="Inter font-bold">Edits</span>
              <div className="w-[145px] mx-auto bg-gray-200 rounded-full h-3 mt-5 ">
                <div
                  className="bg-[#F9C107] h-3 rounded-full "
                  style={{ width: yellowbar.toString() + "%" }}
                ></div>
                <div className="flex justify-center Inter font-bold mt-1">
                  <span>
                    {
                      edits
                    }{" "}
                    / {total}
                  </span>
                </div>
              </div>
            </div>


                        {playbook_id === -1 ? 
              <div className="mb-1 w-1/3 text-center">
              <span className="Inter font-bold">Removals</span>
              <div className="w-[145px] mx-auto bg-gray-200 rounded-full h-3 mt-5">
                <div
                  className="bg-[#F1618D] h-3 rounded-full "
                  style={{ width: redbar.toString() + "%" }}
                ></div>
                <div className="flex justify-center Inter font-bold mt-1">
                  <span>
                    {
                     removals
                    }{" "}
                    / {total}
                  </span>
                </div>
              </div>
            </div>
            : <></>}

          </div>
        </div>
      </div>
    </div>
  );
}
