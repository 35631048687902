import React, { useContext } from "react";
import { useEffect } from "react";
import DocSegment  from "./DocSegment";
import ClickOutside from "../../../components/Single_Components/ClickOutside";

function ScrollChild({document1, comments, part, commentIds}) {
  console.log(document1)
  if (comments) console.log(document1)
  return (
      document1?.map((element, value) => {
        return (
            <>
            {element.change_type === "unchanged"?
            <element.element_optional_info
                id={`father-${element.new_doc_ref_id}-${part}`}
                key={`father-${element.new_doc_ref_id}-1`}
                className={`Inter transition-all duration-150 pr-5 overflow-hidden break-words`}>
                {element.side_text}
            </element.element_optional_info>
            :<DocSegment props={element} old_id={element.old_doc_ref_id} new_id={element.new_doc_ref_id} text={comments ? "1": "0"} part={part} commentIds={commentIds}/>}
            </>
          
        );
      })

)
}  
  

export default ScrollChild;
