import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

interface IBreadCrumbsProps {
  items: Breadcrumb[];
}

export type Breadcrumb = {
  label: string;
  path: string;
};

const Breadcrumbs: React.FC<IBreadCrumbsProps> = ({ items }) => {
  return (
    <div className="text-[#8D92A7] flex items-center gap-2">
      {items.map((item, index) => {
        if (items.length - 1 === index) {
          return (
            <NavLink to={item.path}>
              <span className={`text-[#748AF2]`}>{item.label}</span>;
            </NavLink>
          );
        }
        return (
          <>
            <NavLink to={item.path}>
              <span className={``}>{item.label}</span>
            </NavLink>
            <BsChevronRight size={15} />
          </>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
