import React from 'react';

interface EditorSkeletonProps {}

const EditorSkeleton: React.FC<EditorSkeletonProps> = ({}) => {
  return (
    <div className="animate-pulse w-full bg-white p-16 rounded max-h-screen mt-2 overflow-y-auto flex flex-col gap-2">
      <div className="h-8 bg-gray-100 rounded dark:bg-gray-300 w-48 mb-4"></div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>

      <div className="h-8 bg-gray-100 rounded dark:bg-gray-300 w-48 my-4"></div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>

      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>

      <div className="h-8 bg-gray-100 rounded dark:bg-gray-300 w-48 my-4"></div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-32"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-full"></div>
        <div className="h-5 bg-gray-100 rounded dark:bg-gray-300 w-24"></div>
      </div>
    </div>
  );
};

export default EditorSkeleton;
