import React, { useState, useRef, useEffect } from "react";



export default function Checklist({props}) {
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
    const tabsRef = useRef([]);
    let tabsData = [
        {
            value: props?.summary?.stats?.adds?.paragraph,
            label: "Additions",
            content: (<div className="rounded-xl overflow-hidden ">
            <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                    <tr>
                        <th scope="col" className="px-6 py-5 w-1/3">
                        Old Text
                        </th>
                        <th scope="col" className="px-6 w-1/3">
                        New Text
                        </th>
                        {/* <th scope="col" className="px-6 w-1/3">
                        AI Analysis
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                {props?.diffs_comparison?.additions?.filter((a) => a.scope === "paragraph")?.map((job) => (
                    <tr key={job.origin_question} className="even:bg-[#F9FAFB] border-b ">
                        <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 max-w-[200px]">
                            <div className="block w-full">
                                <div className="w-full font-bold text-blue pb-2 break-words">{job.old_text}</div>
                            </div>
                            
                        </th>
                        <td key={job.tag_name} className="px-6 pr-0 py-4 whitespace-pre-wrap max-w-[200px]">
                            <div className="block w-full">
                                <div className="w-full font-bold text-blue pb-2 flex items-center whitespace-pre-wrap text-ellipsis">
                                    <span className="block w-full break-words">{job.new_text}</span>
                                </div>
                            </div>
                        </td>
                        {/* <td className="px-6 py-4 whitespace-pre-wrap ">
                                                        
                            
                        </td> */}
                    </tr>
                    ))}
                </tbody>
            </table>
    </div>),
            },

            {
                value: props?.summary?.stats?.edits?.paragraph,
                label: "Edits",
                content: (<div className="rounded-xl overflow-hidden ">
                <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                    <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                        <tr>
                            <th scope="col" className="px-6 py-5 w-1/3">
                            Old Text
                            </th>
                            <th scope="col" className="px-6 w-1/3">
                            New Text
                            </th>
                            {/* <th scope="col" className="px-6 w-1/3">
                            AI Analysis
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {props?.diffs_comparison?.edits?.filter((a) => a.scope === "paragraph")?.map((job) => (
                        <tr key={job.origin_question} className="even:bg-[#F9FAFB] border-b ">
                            <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-gray-900 max-w-[200px]">
                                <div className="block w-full">
                                    <div className="w-full font-bold text-blue pb-2 break-words">{job.old_text}</div>
                                </div>
                                
                            </th>
                            <td key={job.tag_name} className="px-6 pr-0 py-4 whitespace-pre-wrap max-w-[200px]">
                                <div className="block w-full">
                                    <div className="w-full font-bold text-blue pb-2 flex items-center whitespace-pre-wrap text-ellipsis">
                                        <span className="block w-full break-words">{job.new_text}</span>
                                    </div>
                                </div>
                            </td>
                            {/* <td className="px-6 py-4 whitespace-pre-wrap ">
                                                            
                                
                            </td> */}
                        </tr>
                        ))}
                    </tbody>
                </table>
        </div>),
                },   
                {
                    value: props?.summary?.stats?.removals?.paragraph,
                    label: "Removals",
                    content: (<div className="rounded-xl overflow-hidden ">
                    <table className="Inter table-fixed min-w-full text-sm text-left text-gray-500">
                        <thead className="text-[#6B7280] bg-[#e4e8f6] text-[16px]">
                            <tr>
                                <th scope="col" className="px-6 py-5 w-1/3">
                                Old Text
                                </th>
                                <th scope="col" className="px-6 w-1/3">
                                New Text
                                </th>
                                {/* <th scope="col" className="px-6 w-1/3">
                                AI Analysis
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {props?.diffs_comparison?.removals?.filter((a) => a.scope === "paragraph")?.map((job) => (
                            <tr key={job.origin_question} className="even:bg-[#F9FAFB] border-b ">
                                <th scope="row" className="whitespace-pre-wrap px-6 py-5 font-medium text-green max-w-[200px]">
                                    <div className="block w-full">
                                        <div className="w-full font-bold text-blue pb-2 break-words">{job.old_text}</div>
                                    </div>
                                    
                                </th>
                                <td key={job.tag_name} className="px-6 pr-0 py-4 whitespace-pre-wrap max-w-[200px]">
                                    <div className="block w-full">
                                        <div className="w-full font-bold text-blue pb-2 flex items-center whitespace-pre-wrap text-ellipsis">
                                            <span className="block w-full break-words">{job.new_text}</span>
                                        </div>
                                    </div>
                                </td>
                                {/* <td className="px-6 py-4 whitespace-pre-wrap ">
                                    
                                    
                                </td> */}
                            </tr>
                            ))}
                        </tbody>
                    </table>
            </div>),
                    },  

    ]
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }
        
        setTabPosition();
        window.addEventListener("resize", setTabPosition);
    
        return () => window.removeEventListener("resize", setTabPosition);
    }, [activeTabIndex]);
    
    return (
        <div className="bg-white rounded-xl border shadow-sm mb-5 p-7 pt-12">
            <span className="Inter font-black text-2xl">Issues Assessment</span>
            <div className="mt-7 border-b-2 border-[#EFF0F6]">
                {tabsData.filter(tab => tab.value !== 0).map((tab, idx) => {
                    // if (tab.value <= 0) {
                    //     tabsData.reverse().pop()
                    // }
                    // if(tab.value>0 )
                    return (
                    <button
                        key={idx}
                        ref={(el) => (tabsRef.current[idx] = el)}
                        className={`pb-5 mr-7 border-b-2 transition-colors duration-300 ${
                        idx === activeTabIndex
                            ? "border-blue text-blue "
                            : "border-transparent hover:border-gray-200"
                        }`}
                        // Change the active tab on click.
                        onClick={() => setActiveTabIndex(idx)}>
                        <span className="mr-4">{tab.label}</span><span className="p-1 px-2.5 text-sm text-blue bg-blue/[.2] rounded-full">{tab.value}</span>
                    </button>
                    );
                    
                })}
            </div>
                <span className="absolute bottom-0 block h-1 bg-teal-500 transition-all duration-300" />
            <div className="mt-7">
                <span className="">{tabsData.filter(tab => tab.value !== 0)[activeTabIndex]?.content}</span>
            </div>
        </div>

        
    );
    }