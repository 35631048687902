import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Pagination from "../../components/Single_Components/Pagination";
import Dropdown from "../../components/Single_Components/Dropdown";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {AiOutlinePlusCircle, AiTwotoneFolderOpen} from "react-icons/ai";
import Container from "../../components/Single_Components/Container";
import Notification from "../../components/Single_Components/Notification";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import Onboarding from "../../components/Single_Components/Onboarding";
import { projectFilters, projectTypes } from "../../components/layout/MainLayout";
export default function Projects() {

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [postsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [dropdownValue, setDropdown] = useState("");
  const [jobsFinal, setJobsFinal] = useState([]);
  const [searchedjobs, setSearchedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const forward = (pageNumber) => setCurrentPage(pageNumber + 1);
  const backward = (pageNumber) => setCurrentPage(pageNumber - 1);


  useEffect(() => {
    async function getProjects(){
      try{
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', "Authorization": localStorage.getItem("token") },
      };
      const response = await window.fetch(process.env.REACT_APP_DOMAIN+"/api/v1/projects/", requestOptions)
      const jobs = await response.json();
      return jobs
      } catch {
        console.log("error")
      }
    }
    setLoading(true)
    getProjects().then((result) => {
      setJobsFinal(result.sort((a, b) => b.id - a.id));
      setSearchedJobs(result.sort((a, b) => b.id - a.id))
      setCurrentPosts(result.slice(indexOfFirstPost, indexOfLastPost));
      setLoading(false)
    })


  }, []);


  useEffect(() => {setSearchedJobs(jobsFinal)},[])
  useEffect(() => {
    if(!loading) 
      setCurrentPosts(searchedjobs.slice(indexOfFirstPost, indexOfLastPost))
    else
    setCurrentPosts(searchedjobs)
  }, [searchedjobs, indexOfFirstPost, indexOfLastPost]);



  const dropDownChild = (value) => {
    if (value === 0)
      searchedjobs.sort((a, b) => b.id-a.id)
    else if (value === 1)
      searchedjobs.sort((a, b) => a.name.localeCompare(b.name));
    else if (value === 2)
      searchedjobs.sort((a, b) => a.playbook_id-b.playbook_id);
    else if (value === 3)
      searchedjobs.sort((a, b) => a.project_type.localeCompare(b.project_type));
      setSearchedJobs(() => searchedjobs);
    setCurrentPosts(searchedjobs.slice(indexOfFirstPost, indexOfLastPost))
    setDropdown(value);
  };
  const searchfunc = (e) => {
    console.log(e.target.value);
    let updatedList = [...jobsFinal];
    updatedList = updatedList.filter((element) =>{
      // console.log(element, element.name, e.target.value, element.name?.toLowerCase()?.includes(e.target.value.toLowerCase()))
      return element.name?.toLowerCase()?.includes(e.target.value.toLowerCase())

    });
    console.log(updatedList)
    setSearchedJobs(updatedList);
    setSearch(e.target.value);
  };


  const steps = [
    {
      target: "table",
      title: "Projects",
      content: "This is where your projects will show up",
      placementBeacon: "top",
    },
    {
      target: ".step2",
      title: "Status",
      content: "Here you can check the status of the file you uploaded",
    },
    {
      target: ".step3",
      title: "Inline Review",
      content: "Click here to go to the inline review page",
      placement: "top",
    },
    {
      target: ".step4",
      title: "Summary",
      content: "Click here to go to the summary page",
      placement: "top",
    },
  ];


  const show_onboarding = useFeatureFlagEnabled("[FE]-[Global]-show_onboarding")
  const show_admins_job_details = useFeatureFlagEnabled("[FE]-[ListContracts]-show_admins_job_details")
  // test 525

  return (
    <Container>
      {show_onboarding && <Onboarding steps={steps} />}
      <div className="mt-11 mb-11">
        <div className="px-4 sm:px-6 lg:px-8 overflow-auto lg:overflow-visible min-h-[700px]">
          <h1 className="text-2xl Inter font-black pb-7">
            <AiTwotoneFolderOpen className="inline-block mr-3" size={30}/>
            Your Projects
          </h1>
          <Notification />
          <div className="sm:flex-auto mb-7">
            {/* Search section */}
            <div className="flex flex-1 justify-between">
              <div className="w-1/4">
                <div className="relative text-[#6B7280] focus-within:text-gray-400">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="lookup"
                    className="block w-full rounded-md border border-transparent bg-[#F9FBFF] py-2 pl-10 pr-3 leading-5 text-black placeholder-[#6B7280] focus:bg-white fade-in duration-100 focus:outline-none sm:text-sm"
                    placeholder="Search Projects"
                    type="search"
                    value={search}
                    onChange={searchfunc}
                  />
                </div>
              </div>
              <div>
                <NavLink
                    to={`/home/projects/create`}>
                  <button className="border-[#748AF2] text-l bg-transparent text-[#748AF2] shadow border-2 rounded-lg p-2 pr-4 pl-4 flex items-center hover:bg-[#748AF2] hover:text-white fade-in duration-300 cursor-pointer"
                          type="submit">
                    <AiOutlinePlusCircle className="mr-3" size={23} />
                    New Project
                  </button>
                </NavLink>
              </div>
            </div>
            {/*  */}
          </div>
          <div className="sm:flex justify-between sm:items-center">
            <div className="">
              <label className="Inter font-bold text-lg">
                Projects
              </label>
              <h3 className="Inter text-xs text-[#6B7280]">
                Your latest projects
              </h3>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Dropdown
                dropDownChild={dropDownChild}
                filters={projectFilters}
                sideText={"Sort by:"} defaultFilter={0}
              />
            </div>
          </div>
          <div className="-mx-4 mt-8 rounded-xl sm:-mx-6 md:mx-0 min-h-[630px]">
            <table className="min-w-1/2 w-full divide-y divide-gray-300 table-fixed">
              <thead className="Inter text-left text-xs font-bold text-[#6B7280] bg-[#F9FAFB] ">
                <tr>
                  { show_admins_job_details ? (
                    <th
                      scope="col"
                      className="text-[#6B7280] w-1 py-3.5 pl-3 pr-3 text-left text-sm font-thin text-gray-900 sm:pl-5">
                      User
                    </th>
                  ) : (
                    <></>
                  )}
                  {show_admins_job_details ? (
                    <th
                      scope="col"
                      className="text-[#6B7280] w-1 py-3.5 pl-3 pr-3 text-left text-sm font-thin text-gray-900 sm:pl-5">
                      ID
                    </th>
                  ) : (
                    <></>
                  )}
                  <th scope="col" className="px-3 py-3.5">
                    Project Name
                  </th>
                  <th
                    scope="col"
                    className=" px-3 py-3.5 w-[120px] max-w-[120px]">
                    Playbook
                  </th>
                  <th scope="col" className="px-3 py-3.5">
                    Type
                  </th>
                  {/* <th scope="col" className="px-3 py-3.5 w-[120px] max-w-[120px]">
                            Review Type
                        </th> */}
                  <th scope="col"></th>
                  
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {currentPosts && currentPosts?.map((job) => (
                  <tr className="even:bg-[#F9FAFB]" key={job.id}>
                    {show_admins_job_details ? (
                      <td className="Inter w-1 py-3.5 pl-3 pr-3 text-sm font-medium text-black-900 sm:w-auto sm:max-w-none sm:pl-5">
                        {job.source}
                      </td>
                    ) : (
                      <></>
                    )}
                    {show_admins_job_details ? (
                      <td className="Inter w-1 py-3.5 pl-3 pr-3 text-sm font-medium text-black-900 sm:w-auto sm:max-w-none sm:pl-5">
                        {job.id}
                      </td>
                    ) : (
                      <></>
                    )}
                    <td className="Inter hidden px-3 py-4 text-sm text-black-500 lg:table-cell whitespace-nowrap overflow-x-hidden max-w-[230px] overflow-ellipsis">
                    {job.name}
                    </td>
                    <td className="Inter px-3 py-4 text-sm text-black-600 sm:table-cell whitespace-nowrap overflow-x-hidden">
                    {job.playbook_id === -1 ? "-": "M&A"}
                    </td>

                    <td className="Inter px-3 py-4 text-sm text-black-600 sm:table-cell whitespace-nowrap overflow-x-hidden">
                    {Object.keys(projectTypes)[Object.values(projectTypes).indexOf(job.project_type)]}
                    </td>
                    {/* <td className="Inter py-4 pl-3 pr-4 text-left capitalize text-sm sm:pr-6">{job.job_type}</td> */}
                    <td className="step3 Inter w-[120px] min-w-[120px] text-center ">

                    <NavLink
                          key={job.id}
                          to={`/home/projects/${job.id}/details`}
                          className="hover:text-indigo-900">
                          <span className="w-[120px] min-w-[120px] py-1 px-3 rounded-full bg-baby-blue text-right text-blue Inter text-xs hover:bg-blue hover:bg-opacity-50 fade-in duration-300 focus:outline-none">
                          View project
                          </span>
                        </NavLink>
                    
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <Pagination
                postsPerPage={postsPerPage}
                totalPosts={searchedjobs?.length}
                paginate={paginate}
                forward={forward}
                backward={backward}
                currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
