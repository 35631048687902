const IS_CHROME = navigator.userAgent.includes('Chrome')

let lastScrolledElement: any = null
let timeoutID = 0

export function scrollElementIntoView(element: HTMLElement) {
  if (!element) return

  if (IS_CHROME && lastScrolledElement) {
    lastScrolledElement.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    })
  }

  lastScrolledElement = element

  window.clearTimeout(timeoutID)

  timeoutID = window.setTimeout(() => {
    lastScrolledElement = null
  }, 250)

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  })
}