import Box from "@mui/material/Box";
import { red, yellow } from "@mui/material/colors";
import { useState, useEffect } from "react";
import ReactStoreIndicator from "./ReactStoreIndicator";
import { usePostHog, useFeatureFlagEnabled,useActiveFeatureFlags } from 'posthog-js/react'
import { Replace_idk_answer } from "../../../assets/GeneralFunctions";
export default function SummaryComponent({ props, playbook_id }) {
  let redbar = 0,
    warning = 0;
  let yellowbar = 0,
    unknown = 0;
  let greenbar = 0,
    success = 0;
  let total = 0;

  const SUMMARY_MAX_LINES = 8

  try {
    // success = props?.doc_analysis_summary_data?.total_success_checks
    // warning = props?.doc_analysis_summary_data?.total_warning_checks
    // unknown = props?.doc_analysis_summary_data?.total_unknown_checks

    // total = props?.doc_analysis_summary_data?.total_checks

    success = props?.summary?.yes_no_risks?.filter(
      (a) => a.risk_score === 0
    ).length;
    warning = props?.summary?.yes_no_risks?.filter(
      (a) => a.risk_score > 1
    ).length;
    unknown = props?.summary?.yes_no_risks?.filter(
      (a) => a.risk_score === 1
    ).length;
    total = props?.summary?.yes_no_risks?.length;

    redbar = (warning * 100) / total;
    yellowbar = (unknown * 100) / total;
    greenbar = (success * 100) / total;
  } catch (e) {
    console.log(e);
  }
  const [readmorebutton, setReadmorebutton] = useState(false);

  const [readmore, setReadmore] = useState(true);
  const [moreorless, setMoreorless] = useState("more");
  function ReadMore(e) {
    let elementToCheck = document.getElementById("summary");

    //
    if (readmore) {
      elementToCheck.classList.remove(`line-clamp-${SUMMARY_MAX_LINES}`);
      setMoreorless("less");
      setReadmore(!readmore);
    } else {
      elementToCheck.classList.add(`line-clamp-${SUMMARY_MAX_LINES}`);
      setMoreorless("more");
      setReadmore(!readmore);
    }
  }
  let elementToCheck = document.getElementById("summary");
  useEffect(() => {
    function showReadMoreButton() {
      const bullet_points = props?.doc_analysis_summary_data?.document_analysis_summary_text?.split("\n")
      const is_there_a_lot_of_bullet_points = bullet_points?.length > SUMMARY_MAX_LINES
      setReadmore(is_there_a_lot_of_bullet_points);
      setReadmorebutton(is_there_a_lot_of_bullet_points);
    }
    if (elementToCheck)
      showReadMoreButton();
  }, [props?.doc_analysis_summary_data?.document_analysis_summary_text]);

  let posthog = usePostHog()

  const show_document_score_access = useFeatureFlagEnabled("[FE]-[Summary]-show_document_score_access")


  const [summaryText, setSummaryText] = useState("1")
  useEffect(() => {
    if (props?.doc_analysis_summary_data?.document_analysis_summary_text) {
      if (props?.doc_analysis_summary_data?.document_analysis_summary_text.includes("I do not know the answer."))
       setSummaryText(Replace_idk_answer(props?.doc_analysis_summary_data?.document_analysis_summary_text))      
    }
  }, [props?.doc_analysis_summary_data?.document_analysis_summary_text])
  return (
    <div className="p-5 bg-white rounded-xl border shadow-sm mb-5 ">
      <div className="Inter text-2xl font-black mt-7 ml-5 flex items-center w-5/12 justify-between">
        <span>Review summary</span>
        {props?.general_information?.score && show_document_score_access ? (
                    <div className="bg-white rounded-3xl shadow-sm border p-5 pb-0 pt-2 absolute right-[120px] top-[332px] z-20">
                      <span className="font-bold Inter text-[20px] pb-3">
                        Document Score
                      </span>
                      <ReactStoreIndicator
                        value={props?.general_information?.score}
                        maxValue={100}
                        width={100}
                        fadedOpacity={20}
                        style={{ marginTop: "10px" }}
                        stepsColors={[
                          "#D81658",
                          "#D81758",
                          "#D91D55",
                          "#DD324C",
                          "#E65F39",
                          "#F4AC19",
                          "#CFDD2E",
                          "#77C782",
                        ]}
                        textStyle={{ top: "25px", fontWeight: "400" }}
                      />
                    </div>) : <></>}
                  
      </div>
      <div
        className={`lg:flex mb-5 ${
          playbook_id !== 148 ? "items-end" : "items-center"
        }`}
      >
        <div className="block mt-7 ml-5 max-w-[50%] border-gray-500 pr-7">
          
        <span
            id="summary"
            className={`line-clamp-8 text-black`}
            dangerouslySetInnerHTML={{
              __html:
                props?.doc_analysis_summary_data?.document_analysis_summary_text.replaceAll(
                  "\n",
                  "<br>"
                )
              ,
            }}
          />
          
          {readmorebutton ? (
            <button
              className="text-right w-full text-[#748AF2] hover:underline"
              onClick={ReadMore}
            >
              Read {moreorless}
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="block w-1/2">
          <div className="flex w-full">
            {playbook_id !== 148 ?
              <div className="mb-1 w-1/3 text-center">
              <span className="Inter font-bold">Issues Found</span>
              <div className="w-[145px] mx-auto bg-gray-200 rounded-full h-3 mt-5">
                <div
                  className="bg-[#F1618D] h-3 rounded-full "
                  style={{ width: redbar.toString() + "%" }}
                ></div>
                <div className="flex justify-center Inter font-bold mt-1">
                  <span>
                    {
                      props?.summary?.yes_no_risks?.filter(
                        (a) => a.risk_score > 1
                      ).length
                    }{" "}
                    / {total}
                  </span>
                </div>
              </div>
            </div>
            : <></>}


            <div className="mb-1 w-1/3 text-center border-gray-500">
              <span className="Inter font-bold">Undetected Issues</span>
              <div className="w-[145px] mx-auto bg-gray-200 rounded-full h-3 mt-5 ">
                <div
                  className="bg-[#F9C107] h-3 rounded-full "
                  style={{ width: yellowbar.toString() + "%" }}
                ></div>
                <div className="flex justify-center Inter font-bold mt-1">
                  <span>
                    {
                      props?.summary?.yes_no_risks?.filter(
                        (a) => a.risk_score === 1
                      ).length
                    }{" "}
                    / {total}
                  </span>
                </div>
              </div>
            </div>
            {playbook_id !== 148 ?
              <div className="mb-1 w-1/3 text-center">
              <span className="Inter font-bold">No Issues</span>
              <div className="w-[145px] mx-auto bg-gray-200 rounded-full h-3 mt-5">
                <div
                  className="bg-green-500 h-3 rounded-full "
                  style={{ width: greenbar.toString() + "%" }}
                ></div>
                <div className="flex justify-center Inter font-bold mt-1">
                  <span>
                    {
                      props?.summary?.yes_no_risks?.filter(
                        (a) => a.risk_score === 0
                      ).length
                    }{" "}
                    / {total}
                  </span>
                </div>
              </div>
            </div>
            : <></>}

          </div>
        </div>
      </div>
    </div>
  );
}
