import axios from 'axios';
import { useMutation } from '@tanstack/react-query';


export const deleteAccount = async (
  ID: string
) => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem("token"),
    }} ;
  return await axios
    .delete(`${process.env.REACT_APP_DOMAIN}/api/users/${ID}/`, requestOptions)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(`Error deleting account: ${ID}`, error);
      return Promise.reject(error);
    });
};

export function useDeleteAccountMutation() {
    return useMutation(deleteAccount);
  }
