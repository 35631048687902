import React from 'react';
import Questions from '../Questions/Questions';

interface ISidebarProps {}

const Sidebar: React.FC<ISidebarProps> = ({}) => {
  return (
    <div className="h-full overflow-y-auto bg-white w-[380px] p-4 flex flex-col gap-2 rounded">
      <SearchBox />
      <Questions />
    </div>  
  );
};

export default Sidebar;

const SearchBox = () => {
  return (
    <div>
      <input
        placeholder="Ask a question"
        className="bg-[#F9FAFE] text-xs py-3 px-2 w-full rounded"
      />
    </div>
  );
};
