import React, {useEffect, useState} from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import ComparisonSummary from "../pages/Comparison/Summary";
import ComparisonReview from "../pages/Comparison/Review";
import {useFeatureFlagEnabled, usePostHog} from 'posthog-js/react'

import Comparison from '../pages/Comparison';
import Summary from '../pages/[Contract]/Summary';
import Review from '../pages/[Contract]/Review';
import LoadDocument from '../pages/[Contract]';
import { useAuth0 } from '@auth0/auth0-react';
import NotFound from '../pages/NotFound';
import UploadContract from '../pages/UploadContract';
import ListContracts from '../pages/ListContracts';
import Settings from '../pages/Settings';

import MainLayout from '../components/layout/MainLayout';
import { AuthenticationGuard } from './protectedRoute';
import ToCPrivacyPolicy from '../pages/ToCPrivacyPolicy';
import Loading from '../components/Single_Components/Loading';
import ShareLandingPage from '../pages/ShareLandingPage';
import Projects from '../pages/Projects';

import CreateProject from "../pages/Projects/Create";
import Upload from "../pages/Projects/Create/Upload";
import Details from "../pages/Projects/Details";
import Redaction from "../pages/Anonymization";
import SearchDocuments from "../pages/SearchDocuments";
import {posthog} from "posthog-js";
import ReviewPage from '../pages/v2/[contract]';
import SaasCompanies from "../pages/SaasCompanies";
import TrademarksPage from "../pages/trademarks";
import CompanyDetails from "../pages/SaasCompanies/company_details";
import UserCompaniesMonitoringPage from "../pages/SaasCompanies/monitoring";
import PricingPage from "../pages/pricingPage";

export default function MakeRoutes() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const ph = usePostHog()

  useEffect( () => {
    if (user) {
      posthog.identify(user.email, {
        email: user.email,
      })
    }
  }, [ph, user])

  const legalreviewViewEnabled = useFeatureFlagEnabled('[FE]-original-legalreview-app-view')

  const settingsEnabled = useFeatureFlagEnabled('[FE]-[Homepage]-make_settings_visible')
  const projectsEnabled = useFeatureFlagEnabled('[FE]-[Global]-Projects')
  const anonEnabled = useFeatureFlagEnabled('[FE]-[Global]-Redaction')
  const searchDocumentsEnabled = useFeatureFlagEnabled('[FE]-[Global]-search_documents')
  const saasCompaniesEnabled = useFeatureFlagEnabled('[FE]-[Global]-saas_companies')

  const trademarksEnabled = useFeatureFlagEnabled('[FE]-[Global]-trademarks')
  const myMonitoringEnabled = useFeatureFlagEnabled('[FE]-[Global]-monitoring_companies')

  let appRoutes = [
    {
      path: '/home/upload',
      element: UploadContract,
    },
    {
      path: '/home',
      element: MainLayout,
    },
    {
      path: '/home/contracts',
      element: ListContracts,
    },
  ];
  let routes = appRoutes

  let legalReviewBaseRoutes = []
  let saasRoutes = []
  const isUserDefinedAndAuthenticated = isAuthenticated && user
  if (isUserDefinedAndAuthenticated && projectsEnabled &&
      appRoutes.filter((e) => e.element === Projects).length === 0) {
    let tempRouts = [{
        path: "/home/projects",
        element: Projects,
      },
      {
        path: "/home/projects/create",
        element: CreateProject,
      },
      {
        path: "/home/projects/:id/upload",
        element: Upload,
      },
      {
        path: "/home/projects/:id/details",
        element: Details,
      },
    ]
    legalReviewBaseRoutes.push(...tempRouts)
    saasRoutes.push(...tempRouts)

  }
  if (isUserDefinedAndAuthenticated && anonEnabled && legalReviewBaseRoutes.filter((e) => e.element === Redaction).length === 0) {
    let redactionPage = {
      path: "/home/Redaction",
      element: Redaction,
    }
    legalReviewBaseRoutes.push(redactionPage)
    saasRoutes.push(redactionPage)
  }

  if (isUserDefinedAndAuthenticated && searchDocumentsEnabled && legalReviewBaseRoutes.filter((e) => e.element === SearchDocuments).length === 0) {
    let searchDocumentPage = {
      path: "/home/searchDocuments",
      element: SearchDocuments,
    }
    legalReviewBaseRoutes.push(searchDocumentPage)
    saasRoutes.push(searchDocumentPage)
  }

  if (isUserDefinedAndAuthenticated && saasCompaniesEnabled && saasRoutes.filter((e) => e.element === SaasCompanies).length === 0) {

    let companiesPage = {
      path: "/home/companies",
      element: SaasCompanies,
    }
    let companiesDetailsPage = {
      path: "/home/companies/:companyId/details",
      element: CompanyDetails,
    }
    legalReviewBaseRoutes.push(companiesPage)
    saasRoutes.push(companiesPage)
    legalReviewBaseRoutes.push(companiesDetailsPage)
    saasRoutes.push(companiesDetailsPage)
  }

  if (isUserDefinedAndAuthenticated && myMonitoringEnabled && saasRoutes.filter((e) => e.element === UserCompaniesMonitoringPage).length === 0) {
    let monitoringPage = {
      path: "/home/monitoring",
      element: UserCompaniesMonitoringPage,
    }
    legalReviewBaseRoutes.push(monitoringPage)
    saasRoutes.push(monitoringPage)
  }

  if (isUserDefinedAndAuthenticated && trademarksEnabled && legalReviewBaseRoutes.filter((e) => e.element === TrademarksPage).length === 0) {
    let trademarksPage = {
      path: "/home/trademarks",
      element: TrademarksPage,
    }
    legalReviewBaseRoutes.push(trademarksPage)
    saasRoutes.push(trademarksPage)
  }

  if (isUserDefinedAndAuthenticated && settingsEnabled && appRoutes.filter((e) => e.element === Settings).length === 0
  ) {
    let settingPage = {
      path: '/home/settings',
      element: Settings,
    }
    legalReviewBaseRoutes.push(settingPage)
    saasRoutes.push(settingPage)
  }

  if (isUserDefinedAndAuthenticated && !legalreviewViewEnabled) {
    routes.push(...legalReviewBaseRoutes)
  } else if (isUserDefinedAndAuthenticated && legalreviewViewEnabled) {
    routes.push(...saasRoutes)
  }


  const generateRoutes = () => {
    return routes.map((route, index) => (
      <Route
        path={route.path}
        element={<route.element />}
        key={index}
        action={route.action}
      />
    ));
  };

  if (isLoading) {
    return (
      <div className="page-layout">
        <Loading />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={'/'}
        element={<AuthenticationGuard component={MainLayout} />}
      >
        {generateRoutes()}
      </Route>
      <Route
        path={'/contract/:id'}
        element={<AuthenticationGuard component={LoadDocument} />}
      >
        <Route
          path={'/contract/:id/summary'}
          element={<AuthenticationGuard component={Summary} />}
        />
        <Route
          path={'/contract/:id/inline_review'}
          element={<AuthenticationGuard component={Review} />}
        />
      </Route>
      <Route
        path={'/v2/contract/:id/inline_review'}
        element={<AuthenticationGuard component={ReviewPage} />}
      />
      <Route
        path={'/comparison/:id'}
        element={<AuthenticationGuard component={Comparison} />}
      >
        <Route
          path={'/comparison/:id/summary'}
          element={<AuthenticationGuard component={ComparisonSummary} />}
        />
        <Route
          path={'/comparison/:id/inline_review'}
          element={<AuthenticationGuard component={ComparisonReview} />}
        />
      </Route>
      <Route
          path={'/home/pricing'}
          element={<AuthenticationGuard component={PricingPage} />}
      />

      <Route path="/*" element={<NotFound />} />
      <Route
        path="/disclaimer"
        element={<AuthenticationGuard component={ToCPrivacyPolicy} />}
      />
      <Route path="/share/:uniqueid" element={<ShareLandingPage />} />
    </Routes>
  );
}
