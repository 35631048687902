import { React, useEffect } from "react";
import {useOutletContext, useSearchParams} from "react-router-dom";
import CompleteDocument from "./CompleteDocument";
import Notification from "../../../components/Single_Components/Notification";

// import test from "./test.json"
function Review(props) {
  const {documentFinal, text, comments, fetchComments, reupload,id} = useOutletContext();
  console.log('documentFinal', documentFinal);
  console.log('text', text);

  useEffect(() => {
    function setCommentYPosition(comment, lastComment) {
      if (!lastComment) {
        comment.style.top =
          document.getElementById(
            "father-" + comment.id.replace("-comment", "")
          ).offsetTop + "px";
      } else if (
        lastComment &&
        +lastComment?.style?.top.replace("px", "") +
          +lastComment?.offsetHeight <
          parseInt(
            document.getElementById(
              "father-" + comment.id.replace("-comment", "")
            ).offsetTop
          )
      ) {
        comment.style.top =
          document.getElementById(
            "father-" + comment.id.replace("-comment", "")
          ).offsetTop + "px";
      } else {
        const k =
          lastComment?.offsetHeight +
          +lastComment?.style?.top.replace("px", "");
        comment.style.top = k + "px";
      }
    }

    const commentsList = document.getElementById("comments")?.childNodes;
    if(commentsList){
      let commentTop = [];
      for (let comment of commentsList) {
        let lastComment = commentTop.pop();
        setCommentYPosition(comment, lastComment);
        commentTop.push(comment);
      }
    }

  }, [comments])

  return (
    <>
    <Notification />
      <div
        name="rectangle"
        className="bg-[#f9f9f9] w-1/3 h-[250px] absolute right-0 top-0 z-10"></div>
        { text.length > 0 ?       
        <CompleteDocument props={documentFinal} id={id} text={text} comments={comments} fetchComments={fetchComments}/>: 
        <>
          {reupload}
        </>}
    </>
  );
}

export default Review;
